import { render, staticRenderFns } from "./icon-light-comment-dot.vue?vue&type=template&id=4ac08fa8&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports