<template lang="pug">
.container-fluid
  .row
    .order-header.mb-3
      img(src="../assets/image/w_logo.png")
    .order-body.px-3
      .order-info.py-4
        span {{ '#' + number }} {{ $t('message.inbox_builded_context_01') }}
        span {{ $t('message.inbox_builded_context_02') }}
        span {{ $t('message.inbox_builded_context_03') }}
      button.btn-default.d-block.my-3(@click="openManager()") {{ $t('message.inbox_builded_go_manager') }}
</template>

<script>
// import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
// import Common from '../library/Common'
export default {
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio,
    icon
  },
  data () {
    return {
      number: ''
    }
  },
  created () {
    if (this.$route.params.number) {
      this.number = this.$route.params.number
    }
  },
  methods: {
    openManager () {
      let goUri = this.$root.web_url + 'order/goto/' + this.number
      this.$root.console('Uri: ', goUri)
      window.location.href = goUri
    }
  }
}
</script>

<style scoped lang="sass">
.container-fluid
  h1
    color: #FFF
    font-weight: bold
  .order-body
    height: calc( 100% - 60px )
    overflow: hidden
    overflow-y: auto
  main
    height: 100%
    background: #F5F5F5
  .card
    border-radius: 15px
  .order-header
    padding: 5px 15px
    background: #2434A1
    width: 100%
    color: #fff
    font-weight: bold
    img
      height: 50px
  .order-body
    font-weight: bold
</style>