<template lang="pug">
.cover-bg(v-show="boolShow", @click="toggleAside")
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'cover-background',
  computed: {
    ...mapState('ui', {
      boolShow: 'boolAppUseCoverBg'
    })
  },
  methods: {
    toggleAside () {
      this.$root.toggleAside = !this.$root.toggleAside
    }
  }
}
</script>>
<style lang="sass">
@import "@/assets/sass/require/common.sass"
.cover-bg
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1000
  width: 100%
  height: 100%
  margin: 0
  background-color: $backgroundColor-black
</style>