<template lang="pug">
.connect-page.container-fluid
  //- 步驟說明頭部
  .step-info.step-info-xs.d-lg-none
    span(v-if="$root.user.platform === 1") {{ $t('message.connect_step_02_title_connect_page') }}
    span(v-if="$root.user.platform === 2") {{ $t('message.connect_step_02_title_connect_channel') }}
  .step-info.step-info-lg.d-none.d-md-block
    span(v-if="$root.user.platform === 1") {{ $t('message.connect_step_02_title_connect_page') }}
    span(v-if="$root.user.platform === 2") {{ $t('message.connect_step_02_title_connect_channel') }}
  .connect-content
    .row
      .col-md-2
      .col-md-8.col-xs-12
        //- 選擇粉專標題區塊
        .connect-title
          h3.connect-title-xs.d-lg-none {{ $t('message.connect_page_title') }}
          h1.connect-title-lg.text-center.d-none.d-md-block {{ $t('message.connect_page_title') }}
        //- 粉絲專頁清單
        .connect-facebook-pages
          .facebook-page(v-for="(page, index) in pages")
            .facebook-page-image
              img(:src="page.image")
            .facebook-page-name.facebook-page-name-xs.d-lg-none {{ page.name }}
            .facebook-page-name.facebook-page-name-lg.d-none.d-md-block {{ page.name }}
            .facebook-page-check(v-if="!page.connected")
              uiCheckbox(
                @click="toggleSelectPage(index)",
                :boolActive="usePage == page.page_id",
                :objStyle="objCheckboxStyle"
              )
            .facebook-page-check(v-else)
              span {{ $t('message.connect_page_binded') }}
            .float-clear
        //- 請求重新授權文字區塊
        .connect-no-pages.text-center
          a(@click="reAuthFacebook()") {{ $t('message.connect_need_reset') }}
        //- 確定選擇粉專進行綁定
        .connect-page-button.text-center
          button.btn-default.btn-lg(type="button", @click="bindFaceBookPage()") {{ $t('message.connect_confirm_button') }}
      .col-md-2
</template>

<script>
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  data () {
    return {
      boolCheckboxActive: false,
      pages: [],
      usePage: '',
      objCheckboxStyle: { fontSize: '1.5em' }
    }
  },
  created () {
    this.getFaceBookPages()
  },
  methods: {
    // 設定點選隱私權政策
    toggleSelectPage (_index) {
      if (this.usePage === this.pages[_index].page_id) {
        this.usePage = ''
      } else {
        this.usePage = this.pages[_index].page_id
      }
    },
    // 請求重新授權
    reAuthFacebook () {
      window.location.href = this.$root.web_url + 'users/access/authority'
    },
    // 取得粉絲專頁
    getFaceBookPages () {
      this.$http.get('manager/v1/page/list').then((_response) => {
        this.$root.console('--> 取得粉專: ')
        this.$root.console(_response)
        if (_response.body.code === '0000') {
          this.pages = _response.body.data
        }
      })
    },
    // 請求綁定商戶
    bindFaceBookPage () {
      if (!this.usePage) {
        this.$root.showAlert(this.$t('message.alert_confirm_bind_page'), 'error')
        return false
      }

      this.$http.post('manager/v1/page/' + this.usePage + '/bind').then(function (_response) {
        if (_response.body.code === '0000') {
          let shopID = _response.body.data.hash_id
          // 綁定完成，設定目前對象
          this.$root.shopID = shopID
          // 設定完成，更新商店資料
          this.$root.getShop()
          // 綁定完成，前往設定頁
          this.$router.push({ name: 'Currency', params: { shop: shopID } })
        }
      }, function (_response) {
        this.$root.showAlert(_response.body.data, 'error')
      })
    }
  }
}
</script>

<style scoped lang="sass">
.connect-page
  .step-info
    margin-left: -15px
    margin-right: -15px
    padding: 5px 15px
    font-weight: bold
    background: #F2F2F2
    // ==== 處理進度漸層色 ====
    border-bottom: 5px solid transparent
    border-image: linear-gradient(to right, #1977F2 0%, #D1D1D1 50%)
    border-image-slice: 1
  .step-info-xs
    font-size: 1.3em
  .step-info-lg
    font-size: 3em
  .connect-content
    .connect-title
      padding-top: 5%
      .connect-title-xs
        font-weight: normal
      .connect-title-lg
        font-size: 2em
    .connect-facebook-pages
      margin-top: 2%
      margin-bottom: 45px
      .facebook-page
        border-top: 2px solid #EBEDF0
        margin: 0px auto
        margin-bottom: 15px
        padding: 5px 5px
        position: relative
        cursor: pointer
        .facebook-page-image
          float: left
          width: 50px
          height: 50px
        .facebook-page-name
          float: left
          padding-top: 12px
          padding-left: 15px
        .facebook-page-name-xs
          font-size: 1em
        .facebook-page-name-lg
          font-size: 1.5em
        .facebook-page-check
          float: right
          padding-top: 12px
        .float-clear
          clear: both
    .connect-no-pages
      margin-bottom: 15px
      a
        text-decoration: underline
        color: #000000
    .connect-page-button
      margin-bottom: 5%
</style>
