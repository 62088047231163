<template lang="pug">
.inbox-page
  main
    article
      section.section.flex-row.align-items-stretch
        .section-body
          .container-fluid
            .row
              .order-header
                img(src="../assets/image/w_logo.png")
              .col-12.order-body
                .order-form
                  .p-2
                  h6 {{ $t('message.inbox_order_page_title') }}
                  .p-1
                  //- 收件資料
                  .card
                    .card-body
                      h6 {{ $t('message.inbox_order_member_info_title') }}
                      .p-1
                      .row
                        .col-3.pt-2
                          | {{ $t('message.inbox_order_member_name') }}
                        .col-9
                          .input-group
                            input.input(
                              :placeholder="$t('message.inbox_order_member_name_input')",
                              v-model="order.recipient_name"
                            )
                      .p-1
                      .row
                        .col-3.pt-2
                          | {{ $t('message.inbox_order_member_phone') }}
                        .col-9
                          .input-group
                            input.input(
                              type="tel",
                              :placeholder="$t('message.inbox_order_member_phone_input')",
                              v-model="order.recipient_phone",
                              @keydown="$root.changePhone"
                            )
                      .p-1
                      .row
                        .col-3.pt-2
                          | {{ $t('message.inbox_order_member_zipcode') }}
                        .col-9
                          .input-group
                            input.input(
                              :placeholder="$t('message.inbox_order_member_zipcode_input')",
                              v-model="order.recipient_zipcode"
                            )
                      .p-1
                      .row
                        .col-3.pt-2
                          | {{ $t('message.inbox_order_member_address') }}
                        .col-9
                          .input-group
                            input.input(
                              :placeholder="$t('message.inbox_order_member_address_input')",
                              v-model="order.recipient_address"
                            )
                  //- 訂單內容
                  .p-2
                  .card
                    .card-body.order-detail
                      h6 {{ $t('message.inbox_order_info_title') }}
                      .p-1
                      .row.pb-3
                        .col-2 {{ $t('message.inbox_order_detail_operating') }}
                        .col-4 {{ $t('message.inbox_order_detail_name') }}
                        .col-3 {{ $t('message.inbox_order_detail_count') }}
                        .col-3 {{ $t('message.inbox_order_detail_price') }}
                      .row.pb-3(v-for="(detail, index) in order.details")
                        .col-2.text-center
                          .remove(@click="removeItem(index)")
                            icon(:iconName="'lightTimes'")
                        .col-4
                          .input-group
                            input.input(
                              list="pitemList",
                              :placeholder="$t('message.inbox_order_detail_name')",
                              v-model="detail.name"
                            )
                            datalist#pitemList
                              option(
                                v-for="(product, index) in products",
                                :value="product.name"
                              ) {{ product.name }}
                        .col-3 
                          select.select.form-control(v-model="detail.quy")
                            option(v-for="n in 15", :value="n") {{ n }}
                        .col-3 
                          .input-group
                            input.input(
                              type="number",
                              :placeholder="$t('message.inbox_order_detail_price')",
                              v-model="detail.price",
                              @input="$root.changeVal"
                            )
                      button.btn-default-ghost.btn-sm(@click="addItem()")
                        icon(:iconName="'lightPlus'")
                        span {{ $t('message.inbox_order_detail_add') }}
                      hr
                      .p-1
                      .order-postcost
                        .title {{ $t('message.inbox_order_post_cost') }}
                        .conect
                          .input-group
                            input.input(
                              type="number",
                              :placeholder="$t('message.inbox_order_post_cost')",
                              v-model="order.ship_cost",
                              @input="$root.changeVal"
                            )
                        .clearall
                      .p-1
                      .order-total
                        .title 
                          b {{ $t('message.inbox_order_total_amt') }}
                        .conect $ {{ total }}
                        .clearall
                      .p-1
                      hr
                      .p-1
                      .order-payments
                        .title
                          b {{ $t('message.orders_order_table_title_06') }}
                        .options
                          .form-row(@click="setPayment(1)")
                            uiRadio(:boolActive="order.pay_method === 1")
                            .label {{ $t('message.orders_order_payment_cash') }}
                          .form-row(
                            @click="setPayment(2)",
                            v-if="payments.number"
                          )
                            uiRadio(:boolActive="order.pay_method === 2")
                            .label {{ $t('message.orders_order_payment_atm') }}
                          .iiiii.pt-1
                          .form-row(
                            @click="setPayment(3)",
                            v-if="payments.linepay"
                          )
                            uiRadio(:boolActive="order.pay_method === 3")
                            .label {{ $t('message.orders_order_payment_linemoney') }}
                          .form-row(
                            @click="setPayment(4)",
                            v-if="payments.linepay_id"
                          )
                            uiRadio(:boolActive="order.pay_method === 4")
                            .label {{ $t('message.orders_order_payment_linepay') }}
                          .form-row(
                            @click="setPayment(5)",
                            v-if="payments.ecpay_hashkey"
                          )
                            uiRadio(:boolActive="order.pay_method === 5")
                            .label {{ $t('message.orders_order_payment_ecpay') }}
                        .clearall
                      hr
                      .p-1
                      .order-payments
                        .title
                          b {{ $t('message.orders_order_table_title_10') }}
                        .options
                          .form-row(@click="setPost(1)")
                            uiRadio(:boolActive="order.post_method === 1")
                            .label {{ $t('message.orders_order_post_tohome') }}
                          .form-row(@click="setPost(2)")
                            uiRadio(:boolActive="order.post_method === 2")
                            .label {{ $t('message.orders_order_post_tostore') }}
                          .form-row(@click="setPost(3)")
                            uiRadio(:boolActive="order.post_method === 3")
                            .label {{ $t('message.orders_order_post_to_FAMIC2C') }}
                          .form-row(@click="setPost(4)")
                            uiRadio(:boolActive="order.post_method === 4")
                            .label {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                          .form-row(@click="setPost(5)")
                            uiRadio(:boolActive="order.post_method === 5")
                            .label {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                        .clearall
                  //- 買家備註
                  .p-2
                  .card
                    .card-body
                      h6 {{ $t('message.inbox_order_recipient_note') }}
                      .p-1
                      textarea(v-model="order.recipient_note", maxlength="250")
                  //- 賣家備註
                  .p-2
                  .card
                    .card-body
                      .row
                        .col-4
                          h6 {{ $t('message.inbox_order_shop_note') }}
                        .col-8.text-right
                          h6.alert-text {{ $t('message.inbox_order_shop_note_alert') }}
                      .p-1
                      textarea(v-model="order.shop_note", maxlength="250")
                  .p-2
                  .is-working(v-if="saveWorkIng")
                    .text-center Working...
                  .is-working(v-else)
                    button.btn-default.d-block(
                      @click="sendOrder()",
                      :disabled="!isCanSaveOrder",
                      type="button"
                    ) {{ $t('message.inbox_order_save_button') }}
                    .inbox-buttom-padding
                  .p-3
                .can-not-use-then(v-if="useOrderErr")
                  .p-5
                  h1.text-center
                    span(v-if="useOrderErr === 1011") {{ $t('message.inbox_order_error_1011') }}
                    span(v-if="useOrderErr === 1012") {{ $t('message.inbox_order_error_1012') }}
                    span(v-if="useOrderErr === 1013") {{ $t('message.inbox_order_error_1013') }}
                    span(v-if="useOrderErr === 1016") {{ $t('message.inbox_order_error_1016') }}
                    span(
                      v-if="useOrderErr === 1014",
                      @click="openManagerSite()"
                    ) {{ $t('message.alert_no_setting_shop') }}
                    span(
                      v-if="useOrderErr === 1015",
                      @click="openManagerSite()"
                    ) {{ $t('message.inbox_order_error_1015') }}
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio,
    icon
  },
  data () {
    return {
      // 是否可以使用本功能
      useOrderErr: false,
      // 訂單內容
      order: {
        // 所屬會員
        member: {
          name: '',
          phone: '',
          zip: '',
          address: ''
        },
        // 訂單細節
        details: [
          {
            name: '',
            quy: 1,
            price: ''
          }
        ],
        // 付款方式
        pay_method: 1,
        // 運費
        ship_cost: 0,
        // 出貨方式
        ship_method: '',
        recipient_name: '',
        recipient_phone: '',
        recipient_zipcode: '',
        recipient_address: '',
        // 買家備註
        recipient_note: '',
        // 賣家備註
        shop_note: '',
        // 預設狀態為待處理
        status: 0
      },
      // 自動計算總價
      total: 0,
      // 0 代表還可繼續在 Inbox 編輯
      status: 0,
      // 粉專商品清單
      products: [],
      // 處理訂單存儲中
      saveWorkIng: false,
      // 目前使用的 Page ID
      fbpageId: '',
      // 目前可使用的付款資訊
      payments: {}
    }
  },
  computed: {
    // 是否可以存儲訂單
    isCanSaveOrder () {
      const NecessaryOrderItems = [
        'recipient_name',
        'recipient_phone',
        'recipient_address'
      ]
      const NecessaryOrderDetailsItems = [
        'quy',
        'name',
        'price'
      ]
      const NecessaryOrderItemsAreValid = NecessaryOrderItems.map(item => {
        return Boolean(this.order[item])
      }).every(validItem => Boolean(validItem))

      let NecessaryOrderDetailsItemsAreValid = false
      if (this.order.details.length > 0) {
        this.order.details.forEach(detail => {
          NecessaryOrderDetailsItems.forEach(item => {
            const isItemValNaN = Number.isNaN(detail[item])
            if (isItemValNaN) {
              NecessaryOrderDetailsItemsAreValid = Boolean(detail[item])
            } else {
              NecessaryOrderDetailsItemsAreValid = Boolean(Number(detail[item]))
            }
          })
        })
      }
      return NecessaryOrderItemsAreValid && NecessaryOrderDetailsItemsAreValid
    }
  },
  async created () {
    // 執行功能預先載入
    await this.autoProcess()
    // 取得當前訂單資料
    await this.getOrder()
  },
  methods: {
    // 載入自動化功能
    async autoProcess () {
      // 
      let vm = this
      // 等待畫面載入完成
      await $(document).ready(function () {
        // 處理自動化計算價格
        $('.order-detail').on('change', 'input,select,textarea', function () {
          vm.processOrder()
        })
        // 處理自動存儲功能
        $(document).on('change', 'input,select,textarea', function () {
          vm.checkForm(true)
        })
      })
    },
    // 取得目前訂單與資訊
    getOrder () {
      let memberHashID = this.$root.memberHashID
      let commentID = this.$root.commentID
      let apiUri = ''
      this.$root.console('-------------------->>>')
      this.$root.console('memberHashID: ' + memberHashID)

      if (!memberHashID && !commentID) {
        return false
      }

      if (memberHashID) {
        apiUri = 'extension/v1/order/member/' + memberHashID + '/current'
      }

      if (commentID) {
        apiUri = 'extension/v1/order/comment/' + commentID + '/current'
      }

      // 取得訂單資料
      this.$http.get(apiUri)
        .then(function (_response) {
          if (_response.body.code === '0000') {
            this.order = _response.body.data
            // 初始化可付款方式
            this.payments = this.order.payment
            // 取得粉專商品
            this.getProduct(this.order.page_id)
            // 處理預設支付方式
            this.order.pay_method = (!this.order.pay_method) ? 1 : this.order.pay_method
            // 轉移用戶資料
            this.order.recipient_name = (this.order.recipient_name) ? this.order.recipient_name : this.member.name
            this.order.recipient_phone = (this.order.recipient_phone) ? this.order.recipient_phone : this.member.phone
            this.order.recipient_zipcode = (this.order.recipient_zipcode) ? this.order.recipient_zipcode : this.member.zip
            this.order.recipient_address = (this.order.recipient_address) ? this.order.recipient_address : this.member.address
            // 初始化訂單資料
            this.order.status = 0
          }
        }, function (_response) {
          this.useOrderErr = _response.body.code
        })
        .then(function () {
          this.checkForm()
        })
    },
    // 重新計算訂單
    processOrder () {
      // 讓運費預設歸0
      this.order.ship_cost = (this.order.ship_cost) ? this.order.ship_cost : 0
      // 重新計算費用
      let total = 0
      this.order.details.forEach(function (_detail) {
        if (_detail.price && _detail.quy) {
          total += parseFloat(parseFloat(_detail.price) * parseFloat(_detail.quy))
        }
      })
      // 增加運費
      total += parseFloat(this.order.ship_cost)
      // 將數字格式化
      this.total = this.$root.common.formatNumber(total)
    },
    // 取得商品清單
    getProduct (_pageID) {
      this.$root.console('--> 嘗試獲取指定 Page ID 的商品清單: ', _pageID);

      // 灌入目前 Page ID
      this.fbpageId = _pageID
      this.$http.get('extension/v1/product/' + _pageID + '/list').then(function (_response) {
        if (_response.body.code === '0000') {
          this.products = _response.body.data
        }
      })
    },
    // 增加訂單品項
    addItem () {
      this.order.details.push({
        name: '',
        quy: 1,
        price: ''
      })
      setTimeout(() => { this.checkForm(true) }, 800);
    },
    // 移除訂單項目
    removeItem (_index) {
      this.order.details.splice(_index, 1)
      setTimeout(() => { this.checkForm(true) }, 800);
    },
    // 變更付款方式
    setPayment (_methon) {
      this.order.pay_method = _methon
      this.checkForm(true)
    },
    // 變更出貨方式
    setPost (_methon) {
      this.order.post_method = _methon
      this.checkForm(true)
    },
    // 更新訂單資訊
    setOrder () {
      let memberHashID = this.$root.memberHashID
      let commentID = this.$root.commentID
      let apiUri = ''

      if (memberHashID) {
        apiUri = 'extension/v1/order/member/' + memberHashID
      }

      if (commentID) {
        apiUri = 'extension/v1/order/comment/' + commentID
      }

      // 超土炮作法，不知為何哪裏會修改訂單狀態
      if (!this.saveWorkIng) {
        this.order.status = 0
      }

      this.$http.put(apiUri, this.order).then(function (_response) {
        if (_response.body.code === '0000') {
          // this.$root.console('--> Order Save Done.')
          if (_response.body.data.status === 1) {
            this.$router.push({ name: 'OrderBuilded', params: { number: _response.body.data.number } })
          }
          this.saveWorkIng = false
        }
      }, function (_error) {
        this.useOrderErr = _error.body.code
        this.saveWorkIng = false
      })
    },
    // 確認表單資料內容
    checkForm (_isAutoSave = false) {
      // 處理訂單細項資料
      this.processOrder()
      // 處理自動儲存
      if (_isAutoSave) this.setOrder()
    },
    // 儲存訂單並發送通知
    sendOrder () {
      let vm = this

      // 檢查最後的商品細項
      let checkKey = true
      this.order.details.forEach(function (_item) {
        vm.$root.console('--> 檢測商品: ', _item)
        if (!_item.name) {
          checkKey = false
          alert(vm.$t('message.alert_no_product_name'))
        }
      })

      if (!checkKey) {
        return false
      }

      this.saveWorkIng = true
      this.order.status = 1
      this.setOrder()
    },
    // 開啟後台網址
    openManagerSite () {
      window.open(this.$root.web_url + '/main')
    }
  }
}
</script>

<style scoped lang="sass">
.inbox-page .section-body
  overflow-y: hidden
.inbox-page
  position: relative
  width: 100%
  border: 1px solid #aaa
  h1
    color: #FFF
    font-weight: bold
  .order-body
    height: calc( 100% - 60px )
    overflow: hidden
    overflow-y: auto
  main
    height: 100%
    background: #F5F5F5
  .card
    border-radius: 15px
  .order-header
    padding: 5px 15px
    background: #2434A1
    width: 100%
    color: #fff
    font-weight: bold
    img
      height: 50px
  .remove
    cursor: pointer
    color: #f00
  .order-form
    width: 100%
    .order-detail
      hr
        margin: 3px 0px
      input.form-control
        width: 100%
      .order-postcost
        .conect
          .input-group
            input
              width: 80px
      .order-postcost,.order-total,.order-payment,.order-shipment
        width: 100%
        .title
          float: left
        .conect
          float: right
          padding-right: 15px
        .shipment
          float: left
        .option
          .form-row
            cursor: pointer
            float: left
  .alert-text
    color: #F00
  .can-not-use-then
    position: absolute
    background: rgba(0, 0, 0, 0.75)
    width: 100%
    height: 100%
    left: 0px
    top: 0px
  .inbox-buttom-padding
    padding-top: 25px
  .order-payments
    .options
      margin-top: 5px
      margin-left: 5px
</style>