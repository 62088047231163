<template lang="pug">
.qrcode-view
  .qrcode-bg
  .qrcode-content
    img.close-img(
      src="@/assets/image/close@3x.png",
      @click="closeQRcodeView()"
    )
    qrcode.code-size(:value="$root.code_uri")
    .qrcode-text {{ $t('message.shops_manager_page_expired_info').replace('%s', '『' + $root.code_shopname + '』') }}
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {

  },
  methods: {
    closeQRcodeView () {
      this.$root.qrcode_view_status = false
    }
  }
}
</script>

<style scoped lang="sass">
.qrcode-view
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 99
  justify-content: center
  align-items: center
  align-content: center
  text-align: center
  padding: 3rem
  .qrcode-bg
    width: 100%
    height: 100%
    background: rgba(0,0,0,0.6)
    position: absolute
    left: 0
    top: 0
  .qrcode-content
    position: relative
    background: #fff
    border-radius: 15px
    margin: 7rem auto
    padding: 5rem 3rem 3rem 3rem
    max-width: 25rem
    .close-img
      position: absolute
      right: 2rem
      top: 2rem
      cursor: pointer
      &:active
        opacity: .6
        &:hover
          opacity: .8
    .code-size
      max-width: 27rem
      width: 100%
    .qrcode-text
      text-align: left
      width: 100%
      font-size: 16px
      font-weight: 400
</style>

