<template lang="pug">
.signin-page.container-fluid
  //- 步驟說明頭部
  .step-info.step-info-xs.d-lg-none
    span(v-if="$root.user.platform === 1") 您的 FaceBook 商店方案目前已經到期
    span(v-if="$root.user.platform === 2") 您的 Line 商店方案目前已經到期
  .step-info.step-info-lg.d-none.d-md-block
    span(v-if="$root.user.platform === 1") 您的 FaceBook 商店方案目前已經到期
    span(v-if="$root.user.platform === 2") 您的 Line 商店方案目前已經到期
  .signin-content
    .row
      .col-2
      .col-8.text-center
        //- Logo 區塊
        .header-logo.d-lg-none
          //- 間隔空間
          .p-5
          //- Logo
          img.text-center(src="../assets/image/logo.png")
          //- 間隔空間
          .p-4
        //- Banner 區域
        .signin-banner.d-none.d-md-block
          img(src="../assets/image/main-banner.png")
        //- 手機版其他說明文字
        .d-lg-none.signin-text-xs
          .signin-text-01 
            span(v-if="$root.user.platform === 1") 您的商店方案已經到期
            span(v-if="$root.user.platform === 2") 您的商店方案已經到期
          .signin-text-02 
            span(v-if="$root.user.platform === 1") 請點擊以下按鈕前往續費唷！
            span(v-if="$root.user.platform === 2") 請點擊以下按鈕前往續費唷！
        //- 電腦版其他說明文字
        .d-none.d-md-block.signin-text-lg
          .signin-text-01
            span(v-if="$root.user.platform === 1") 您的商店方案已經到期
            span(v-if="$root.user.platform === 2") 您的商店方案已經到期
          .signin-text-02
            span(v-if="$root.user.platform === 1") 請點擊以下按鈕前往續費唷！
            span(v-if="$root.user.platform === 2") 請點擊以下按鈕前往續費唷！
        //- 間隔空間
        .d-lg-none
          .p-5
        //- 處理電腦版顯示的按鈕
        .sign-in-connect-button.d-none.d-md-block
          button.btn-line-login(
            type="button",
            @click="openLiveShopLine(2, $root.shop.hash_id, $root.shop.name)",
            v-if="$root.user.platform === 2"
          )
            .overlay
            .icon
              .icon-inner
                img(src="../assets/image/line-b.png")
            span 前往續約

          button.btn-facebook-login(
            type="button",
            @click="openLiveShopLine(2, $root.shop.hash_id, $root.shop.name)",
            v-if="$root.user.platform === 1"
          )
            .overlay
            .icon
              .icon-inner
                img(
                  src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png"
                )
            span 前往續約
        //- 處理手機版顯示的按鈕
        .sign-in-connect-button.d-lg-none
          button.btn-line-login(
            type="button",
            @click="openLiveShopLine(1, $root.shop.hash_id, $root.shop.name)",
            v-if="$root.user.platform === 2"
          )
            .overlay
            .icon
              .icon-inner
                img(src="../assets/image/line-b.png")
            span 前往續約

          button.btn-facebook-login(
            type="button",
            @click="openLiveShopLine(1, $root.shop.hash_id, $root.shop.name)",
            v-if="$root.user.platform === 1"
          )
            .overlay
            .icon
              .icon-inner
                img(
                  src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png"
                )
            span 前往續約
      .col-2
</template>

<script>
export default {
  data () {
    return {
      platform: ''
    }
  },
  created () {

  },
  methods: {
    // 開啟 LiveShop Line
    openLiveShopLine (_openType = false, _shopHashId = '', _shopName = '') {
      let uri = "https://line.me/R/oaMessage/@liveshop/?" + encodeURI("我的商店[" + _shopHashId + "-" + _shopName + "]已經到期")
      this.$root.console('--> 產製目標網址: ' + uri)

      switch (_openType) {
        // 開啟類型1, 打開指定網址
        case 1:
          window.open(uri)
          break;
        // 開啟類型2, 打開顯示 QRCode
        case 2:
          this.openLiveShopQrcode(_shopName, uri)
          break;
      }
    },
    // 開啟 QRCode 視窗
    openLiveShopQrcode (_shopName, _uri) {
      this.$root.qrcode_view_status = true
      this.$root.code_uri = _uri
      this.$root.code_shopname = _shopName
    }
  }
}
</script>

<style scoped lang="sass">
.signin-page
.step-info
    margin-left: -15px
    margin-right: -15px
    padding: 5px 15px
    font-weight: bold
    background: #F2F2F2
    // ==== 處理進度漸層色 ====
    border-bottom: 5px solid transparent
    border-image: linear-gradient(to right, #1977F2 0%, #D1D1D1 0%)
    border-image-slice: 1
.step-info-xs
    font-size: 1.3em
.step-info-lg
    font-size: 3em
.signin-content
    .signin-banner
        margin: 0px auto
        margin-top: 10%
    img
        margin: 0px auto
        max-width: 100%
        max-height: 350px
        margin-bottom: 25px
    .signin-text-xs
        font-size: 1em
    .signin-text-lg
        font-size: 1.5em
    .sign-in-connect-button
        text-align: center
        margin-bottom: 35px
        margin-top: 25px
</style>
