<template lang="pug">
#ProductPage.container
  //- 登入以選購商品
  .section-body(v-if="type === 'add'")
    //- 商品資訊曝光
    .pt-2
    .card
      .card-header
        .row
          .col-12
            h2 {{ product.name }}
      .card-body.carousel-region
        .row(v-if="product.image")
          .col-12
            carousel(perPage="1", autoplay="true", autoplayTimeout="5000")
              slide(v-for="(image, index) in product.images")
                img.product-image(:src="image", alt="alt")

        .row(v-if="product.des")
          .col-12
            .pt-4
            h3 商品描述
            p(v-html="product.des.replace(/\\r?\\n/g, '<br>')")
    .pt-5
    .login-to-cart(v-if="!userId")
      .main-sign-in-button
        button.btn-line-login(type="button", @click="askGoLineLogin()")
          .overlay
          .icon
            .icon-inner
              img(src="../assets/image/line-b.png")
          span.font-size-xs.d-lg-none {{ $t('message.cart_use_line_login') }}
          span.font-size-lg.d-none.d-md-block {{ $t('message.cart_use_line_login') }}
      .pt-5
    //- 商品選購表單
    form(v-else-if="userId")
      //- 加入商品到購物車
      .card
        .card-body
          .mb-3
            label.form-label(for="ProductPrice") 售價
            #ProductPrice {{ product.price }}
          .mb-3
            label.form-label(for="ProductCount") 需要的數量
            select#ProductCount.form-control(
              aria-describedby="CountHelp",
              v-model="addCnt"
            )
              option(v-for="n in 15", :value="n") {{ n }}
            #CountHelp.form-text 請選擇您需要購買的數量
      //- 收件資料
      .pt-2
      .card
        .card-body
          h6 {{ $t('message.inbox_order_member_info_title') }}
          .p-1
          .row
            .col-3.pt-2
              | {{ $t('message.inbox_order_member_name') }}
            .col-9
              .input-group
                input.input(
                  v-model="order.recipient_name",
                  :placeholder="$t('message.inbox_order_member_name_input')"
                )
          .p-1
          .row
            .col-3.pt-2
              | {{ $t('message.inbox_order_member_phone') }}
            .col-9
              .input-group
                input.input(
                  type="tel",
                  v-model="order.recipient_phone",
                  :placeholder="$t('message.inbox_order_member_phone_input')",
                  @keydown="$root.changePhone"
                )
          .p-1
          .row(v-if="order.post_method === 1")
            .col-3.pt-2
              | {{ $t('message.inbox_order_member_zipcode') }}
            .col-9
              .input-group
                input.input(
                  v-model="order.recipient_zipcode",
                  :placeholder="$t('message.inbox_order_member_zipcode_input')"
                )
          .p-1
          .row(v-if="order.post_method === 1")
            .col-3.pt-2
              | {{ $t('message.inbox_order_member_address') }}
            .col-9
              .input-group
                input.input(
                  v-model="order.recipient_address",
                  :placeholder="$t('message.inbox_order_member_address_input')"
                )

      //- 出貨方式
      .pt-2
      .card
        .card-body
          h6
            span 設定付款與出貨
          .row
            .p-1
            .col-12
              label.form-label(for="PaymentSwitch") 付款方式
              select#PaymentSwitch.form-control(v-model="order.pay_method")
                option(value="0") 請選擇付款的方式
                option(
                  v-for="(payment, index) in payments",
                  :value="payment.id"
                ) {{ payment.name }}
          .row
            .p-1
            .col-12
              label.form-label(for="ShipSwitch") 出貨方式
              select#ShipSwitch.form-control(v-model="order.post_method")
                option(value="0") 請選擇出貨的方式
                option(v-for="(ship, index) in ships", :value="ship.id") {{ ship.name }}
      .pt-2
      button.btn.btn-primary.btn-default-outline.d-block(
        type="button",
        @click="setAddToCart"
      ) 更新購物車
      .pt-5
  .pt-2
  .section-body(v-if="type === 'cart'")
    //- 訂單內容
    .p-2
    .card
      .card-body.order-detail
        h6 {{ $t('message.inbox_cart_info_title') }}
        .p-1
        .row.pb-3
          .col-4 {{ $t('message.inbox_order_detail_name') }}
          .col-3 {{ $t('message.inbox_order_detail_count') }}
          .col-3 {{ $t('message.inbox_order_detail_price') }}
          .col-2 {{ $t('message.inbox_order_detail_operating') }}
        .row.pb-3(v-for="(detail, index) in order.details")
          .col-4
            .input-group.table-text
              span {{ detail.name }}
          .col-3 
            .input-group.table-text
              span {{ detail.quy }}
            //- select.select.form-control(v-model="detail.quy")
              option(v-for="n in 15", :value="n") {{ n }}
          .col-3 
            .input-group.table-text
              span {{ detail.price }}
          .col-2.text-center
            .remove(@click="removeItem(index)")
              icon.icon(:iconName="'lightTimes'")
    //- 出貨方式
    .pt-2
    .card(
      v-if="orderNumber && order.post_method >= 3 && order.post_method <= 5"
    )
      .card-header 
        h5(v-if="order.store_id") 已設定的取貨超商
        h5(v-else) 選擇取貨超商
      .card-body(v-if="order.store_id") 
        .p-1
        .inpu-group.row
          .col-3.pt-2 超商種類
          .col-8.pt-2 
            span(v-if="order.post_method == 3") 全家超商
            span(v-if="order.post_method == 4") 7-11超商
            span(v-if="order.post_method == 5") 萊爾富超商
        .inpu-group.row
          .col-3.pt-2 郵遞區號
          .col-8.pt-2 
            span {{ order.recipient_zipcode }}
        .inpu-group.row
          .col-3.pt-2 超商地址
          .col-8.pt-2 
            span {{ order.recipient_address }}

      .card-body(v-else)
        .p-1
        .col-12
          button.btn.btn-primary.btn-default.d-block(
            @click="setOrderShipMap(orderNumber, 3)",
            v-if="order.post_method == 3"
          ) 前往設定 全家 門市
          button.btn.btn-primary.btn-default.d-block(
            @click="setOrderShipMap(orderNumber, 4)",
            v-if="order.post_method == 4"
          ) 前往設定 7-11 門市
          button.btn.btn-primary.btn-default.d-block(
            @click="setOrderShipMap(orderNumber, 5)",
            v-if="order.post_method == 5"
          ) 前往設定 萊爾富 門市
        .p-3

    .pt-2
    .row
      .col-6
        button.btn.btn-primary.btn-default-outline.d-block(
          type="button",
          @click="continueBuy"
        ) 繼續選購

      .col-6
        button.btn.btn-primary.btn-default.d-block(
          type="button",
          @click="sendOrderToSeller"
        ) 找賣家結帳去
    .pt-5
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons'
import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
    icon,
    Carousel
  },
  props: {
    isBind: false,
    userId: false,
    userName: false,
    userIdType: false,
    order: {},
    detail: {},
    product: {},
    memberId: false,
    lineBaseId: false,
    audienceId: false,
    platformId: false,
    paymentSwitch: false,
    shipSwitch: false,
    payments: [],
    ships: []
  },
  data () {
    return {
      type: "add",
      addCnt: 1,
      orderNumber: ''
    }
  },
  created () {
    $("title").html(this.product.name)
    // 更新為已經選購的數量
    if (this.detail.name) {
      this.addCnt = this.detail.quy
    }
  },
  methods: {
    // 請求進行 Line 登入
    askGoLineLogin () {
      let currentLocation = window.btoa(window.location)
      let goUri = this.$root.web_url + 'link/auth/line/' + this.lineBaseId + '?state=' + currentLocation
      console.log('goUri: ' + goUri)
      window.location.href = goUri
    },
    // 請求加入購物車
    setAddToCart () {
      // 整理商品資料
      var vm = this
      let details = []
      let searchKey = false
      this.order.details.forEach(function (_item, _index) {
        if (!_item.name) {
          return
        }

        // 處理購物訂單細節
        if (_item.name === vm.product.name) {
          searchKey = true
          _item.quy = vm.addCnt
          _item.price = vm.product.price
          details.push(_item)
        }

        // 若非本次商品，複製該品項至內容
        else {
          details.push(_item)
        }
      })

      if (!searchKey) {
        details.push({
          name: this.product.name,
          quy: this.addCnt,
          price: this.product.price
        })
      }

      // 送交訂單異動資訊
      this.$http.post('liff/v1/cart/' + this.memberId + '/add', {
        details: details,
        code: "addToCart",
        count: this.addCnt,
        userId: this.userId,
        utype: this.userIdType,
        productId: this.product.hash_id,
        pay_method: this.order.pay_method,
        post_method: this.order.post_method,
        recipient_name: this.order.recipient_name,
        recipient_phone: this.order.recipient_phone,
        recipient_zipcode: this.order.recipient_zipcode,
        recipient_address: this.order.recipient_address
      }).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert("加入購物車時發生問題")
          return
        }

        // 加入購物車成功
        this.type = 'cart'

        // 更新訂單細節
        this.orderNumber = _response.body.data
        this.order.details = details
      })
    },
    // 移除訂單項目
    async removeItem (_index) {
      if (!confirm("確定要將商品移出購物車嗎?")) {
        return
      }

      // 移除訂單內容
      await this.order.details.splice(_index, 1)

      // 請求重新更新資料
      await this.setAddToCart()
    },
    // 繼續選購
    async continueBuy () {
      await this.setAddToCart()
      await this.$emit('continueBuy')
    },
    // 送單給賣家
    async sendOrderToSeller () {
      // 進行基本檢查
      if (this.order.post_method >= 3 && this.order.post_method <= 5) {
        if (!this.order.store_id) {
          alert("請務必完成指定取貨的超商設定哦！")
          return
        }
      }
      // 處理更新作業
      await this.setAddToCart()
      let targetUri = await this.genKeywordLink("呼叫小幫手，我要結帳囉～")
      await this.$emit('goChannel', targetUri)
    },
    // 產生 Line 官方帳號鏈結
    genKeywordLink (_keyword) {
      let keyword = encodeURI(_keyword)
      return 'https://line.me/R/oaMessage/' + this.platformId + '/?' + keyword
    },
    // 前往設定指定取貨超商
    setOrderShipMap (_orderNumber, _postMethod) {
      let targetUri = this.$root.base_url + '/ecpay/' + _orderNumber + '/map/' + _postMethod
      console.log("=> 前往設定商店: ", targetUri)

      this.$emit('goSetPostShop', targetUri)
    }
  }
}
</script>

<style lang="sass" scoped>
button
  min-width: auto
.input-group .table-text
  padding-top: 7px
.remove
  .icon
    margin-top: 7px
img.product-image
  width: 100%
/deep/  .VueCarousel-dot
  min-width: 10px !important
</style>