const state = {
  boolAppUseCoverBg: false,
  boolAsideActive: false,
  // 需要顯示Header的頁面
  arrNeedHeaderPages: [
    // 恭喜開張頁
    'Congratulations',
    // 商店設定頁
    'Setting',
    // 金物流設定頁
    'Ship',
    // 訂單列表頁
    'Orders',
    // 訂單操作頁
    'OrdersOperating',
    // 訂單細項頁
    'Order',
    // 商品列表頁
    'Products',
    // 商品資料頁
    'EditProduct',
  ],
  // 需要顯示側邊欄的頁面
  arrNeedSidePages: [
    // 商店設定頁
    'Setting',
    // 金物流設定頁
    'Ship',
    // 訂單列表頁
    'Orders',
    // 訂單操作頁
    'OrdersOperating',
    // 訂單細項頁
    'Order',
    // 商品列表頁
    'Products',
    // 商品資料頁
    'EditProduct',
  ],
}

const mutations = {
  setAppCoverBg (state, boolShow) {
    state.boolAppUseCoverBg = boolShow
  },
  setAsideActive (state, boolShow) {
    state.boolAsideActive = boolShow
  }
}

const actions = {
  toggleAside ({ commit }, boolAsideActive) {
    // console.log(boolAsideActive)
    // console.log(window.innerWidth)
    if (window.innerWidth < 768) {
      commit('setAppCoverBg', boolAsideActive)
    }
    commit('setAsideActive', boolAsideActive)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}