<template lang="pug">
.page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-column.align-items-stretch.flex-nowrap
        .section-body
          .container-fluid
            .p-2
            nav(aria-label="breadcrumb")
              ol.breadcrumb
                li.breadcrumb-item 
                  a.link-underlined(
                    @click="$router.push({ name: 'Products' })"
                  ) 商品管理
                li.breadcrumb-item.active(aria-current="page") 
                  span(v-if="productId === 'add'") 增加新的商品
                  span(v-else) {{ product.name }}
            .row
              //- 商品資料
              .col-md-6.col-xs-12
                .p-2
                .card
                  .card-body
                    .row
                      .col-7
                        h6 {{ $t('message.product_info_title') }}
                    .p-1
                    .inpu-group.row
                      .col-4.pt-2 {{ $t('message.product_detail_title') }}
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_status') }}
                      select.input.col-8(v-model="product.status")
                        option(value="0") 關閉選購
                        option(value="1") 開放選購
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_name') }}
                      input.input.col-8(v-model="product.name")
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_slogn') }}
                      input.input.col-8(v-model="product.slogn")
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_des') }}
                      textarea.input.col-8(v-model="product.des")
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_sku') }}
                      input.input.col-8(v-model="product.sku")
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_price') }}
                      input.input.col-8(type="number", v-model="product.price")
                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_keyword') }}
                      input.input.col-8(
                        type="text",
                        placeholder="對應的關鍵字，請使用『 , 』做為分隔符號",
                        v-model="product.keyword"
                      )
                //- 商品鏈結
                .product-link(
                  v-if="$root.shop.is_liff && $root.shop.account.line_login_channel_id"
                )
                  .p-2
                  .card
                    .card-body
                      .row
                        .col-7
                          h6 {{ $t('message.product_link_title') }}
                      .p-1
                      .inpu-group.row
                        .col-10.pt-2 
                          textarea.linkuri(
                            :value="product.link",
                            rows="2",
                            readonly
                          )
                        .col-2.pt-2
                          button.btn-icon
                            icon.icon(iconName="lightCopy", @click="")

              //- 商品圖片
              .col-md-6.col-xs-12
                .p-2
                .card
                  .card-body
                    .row
                      .col-7
                        h6 {{ $t('message.product_image_title') }}

                    .p-1
                    .inpu-group.row
                      .col-3.pt-2 {{ $t('message.product_image') }}
                      input.input.col-8(
                        type="file",
                        multiple,
                        @change="filesChange($event.target.files)"
                      )
                    .pt-1
                    .product-image-rang.text-center(
                      v-if="product.images.length > 0"
                    )
                      .product-image-item(
                        v-for="(image, index) in product.images"
                      )
                        button.btn-icon.TrashAlt(@click="removeImg(index)")
                          icon(:iconName="'lightTrashAlt'")
                        img.product-image(:src="image", alt="alt")

        .section-footer
          .container-fluid.p-3
            .row
              .col-lg-6.text-center.text-lg-right
                button.btn-md.btn-default(
                  v-if="productId === 'add'",
                  @click="addProduct"
                ) 建立商品資料
                button.btn-md.btn-default(v-else, @click="putProduct") 更新商品資料
</template>

<script>
import HeaderBase from './HeaderBase'
import Menu from './Menu'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    HeaderBase,
    Menu,
    icon,
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      productId: false,
      product: {
        name: '',
        price: '',
        keyword: '',
        images: [],
        link: false
      }
    }
  },
  async created () {
    if (this.$route.params.productId) {
      this.productId = this.$route.params.productId
    }

    await this.getShopProduct()
  },
  watch: {
  },
  methods: {
    // 請求獲取商品資料
    async getShopProduct () {
      if (this.productId === 'add') {
        return
      }

      // 向後端獲取商品資料
      await this.$http.get('manager/v1/shop/' + this.$root.shopID + '/product/' + this.productId).then(async function (_response) {
        if (_response.body.code !== '0000') {
          await this.$root.showAlert(_response.body.data, 'error')
          return
        }

        let product = _response.body.data

        //  初始化多圖
        if (!product.images) {
          product.images = []
          // 如果有多個圖片
          if (Array.isArray(product.images) && product.images.length > 0) {
            product.images = product.images
          }
          // 如果有一個圖片
          else if (product.image) {
            product.images = [product.image]
          }
        }

        // console.log("product:", product)
        this.product = product
      }, async function (_error) {
        await this.$root.showAlert(_error.body.data, 'error')
      })
    },
    // 檢測商品資料是否完整填寫
    checkFormData () {
      if (!this.product.name) {
        alert("請務必填寫商品名稱哦！")
        return false
      }
      if (!this.product.slogn) {
        alert("請務必填寫商品小標題哦！")
        return false
      }
      if (!this.product.des) {
        alert("請務必填寫商品描述哦！")
        return false
      }
      if (!this.product.sku) {
        alert("請務必填寫商品貨號哦！")
        return false
      }
      if (!this.product.price) {
        alert("請務必填寫商品價格哦！")
        return false
      }
      if (!this.product.keyword) {
        alert("請務必填寫商品關鍵字哦！")
        return false
      }
      if (!this.product.images) {
        alert("請務必上傳商品圖片哦！")
        return false
      }
      return true
    },
    // 建立商品資料
    async addProduct () {

      // 檢測商品資料填寫
      if (!this.checkFormData()) {
        return
      }

      // 特別處理商品圖片
      this.product.image = this.product.images[0]

      // 請求後端建立商品資料
      await this.$http.post('manager/v1/shop/' + this.$root.shopID + '/product', this.product).then(async function (_response) {
        if (_response.body.code !== '0000') {
          await this.$root.showAlert(_response.body.data, 'error')
          return
        }

        // 處理回應資料
        let response = _response.body.data
        this.productId = await response.hash_id
        this.product = response

        this.$root.showAlert("商品建立成功", "success")

        // 跳轉畫面
        this.$router.push({ name: 'EditProduct', params: { shop: this.$root.shopID, productId: response.hash_id } })
      }, async function (_error) {
        await this.$root.showAlert(_error.body.data, 'error')
      })
    },
    // 更新商品資料
    async putProduct () {

      // 檢測商品資料填寫
      if (!this.checkFormData()) {
        return
      }

      // 特別處理商品圖片
      this.product.image = this.product.images[0]

      // 請求後端更新商品資料
      await this.$http.put('manager/v1/shop/' + this.$root.shopID + '/product/' + this.productId, this.product).then(async function (_response) {
        if (_response.body.code !== '0000') {
          await this.$root.showAlert(_response.body.data, 'error')
          return
        }

        // 處理回應資料
        let response = _response.body.data
        this.productId = await response.hash_id
        this.product = response

        this.$root.showAlert("商品更新成功", "success")
      }, async function (_error) {
        await this.$root.showAlert(_error.body.data, 'error')
      })
    },
    // 上傳圖片
    async filesChange (_files) {
      var vm = this
      var formData = new FormData();
      console.log('上傳的檔案: ', _files)

      // 處理多個檔案
      for (let _file of _files) {
        formData.append('file[]', _file)
      }

      // 處理圖片資訊
      if (!this.product.images) {
        this.product.images = []
      }

      // 處理檔案上傳
      this.$http.post('manager/v1/shop/' + this.$root.shopID + '/image', formData).then(function (_response) {
        _response.body.data.forEach(function (_image) {
          vm.product.images.push(_image)
        })
      })

      // -- 粗暴重新渲染
      this.$forceUpdate()
    },
    // 移除圖片
    removeImg (_removeIndex) {
      console.log('_removeIndex: ', _removeIndex)
      this.product.images.splice(_removeIndex, 1)
    }
  }
}
</script>

<style scoped lang="sass">
.section-body
  width: 100%
  overflow-y: scroll
  .search-room-bar
    width: 100%
    .search-input
      padding-left: 3rem
      padding-right: 3rem
  .order-operating
    width: 100%
    .order-operating
      .select-group
        width: 100%
      .operating-option
        max-width: 100%
        width: 100%
    ul.nav
      li.nav-item
        a.nav-link.active
          color: #00F
  .operating-lg
    .operating-01
      float: right
      .select-group
        width: 15rem
    .operating-02
      margin-left: 15px
      float: right
  .orders-list
    margin-bottom: 15px
  .nav-item
    cursor: pointer
  .orders-xs
    .order-number
      // color: #356CFA
    td
      border: 0px
      padding: 2px
    .order-payment
      border-radius: 20px
      background: #ccc
      padding: 3px 10px
      font-size: .8em
      float: right
  .orders-lg
    .table
      min-width: 800px
  .no-data
    img
      margin: 0px auto
      max-width: 100%
      max-height: 350px
      margin-bottom: 25px
  .select-all-order
    .select.operating-option
      margin-left: 15px
      min-width: 150px
  .order-list
    .pageings
      nav
        ul
          margin: 0px auto
  #staticBackdrop
    .modal-body
      .text
        text-align: left
      input.link
        border: 1px solid #aaa
        padding: 10px 15px
        margin: 15px 0px
        width: 100%
  .text-riget
    text-align: right
  button.btn-icon
    margin-right: 10px
    margin-top: 10px
    .icon
      width: 20px
      height: 20px
  textarea.linkuri
    height: auto
    background: #fff
  .product-image-rang
    position: relative
    .product-image-item
      position: relative
      float: left
      width: 150px
      min-width: 150px
      height: auto
      margin-top: 15px
      margin-right: 15px
      .TrashAlt
        cursor: pointer
        position: absolute
        right: -7%
        top: -8%
      img.product-image
        margin: 0px auto
        max-width: 150px
</style>