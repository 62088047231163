<template lang="pug">
  div.icon(v-once)
    //- 先是細體
    template(v-if="iconName === 'lightTimes'")
      icon-light-times
    template(v-else-if="iconName === 'lightAngleLeft'")
      icon-light-angle-left
    template(v-else-if="iconName === 'lightAngleRight'")
      icon-light-angle-right
    template(v-else-if="iconName === 'lightAngleUp'")
      icon-light-angle-up
    template(v-else-if="iconName === 'lightAngleDown'")
      icon-light-angle-down
    template(v-else-if="iconName === 'lightAngleDoubleLeft'")
      icon-light-angle-double-left
    template(v-else-if="iconName === 'lightAngleDoubleRight'")
      icon-light-angle-double-right
    template(v-else-if="iconName === 'lightSearch'")
      icon-light-search
    template(v-else-if="iconName === 'lightClock'")
      icon-light-clock
    template(v-else-if="iconName === 'lightEllipsisH'")
      icon-light-ellipsis-h
    template(v-else-if="iconName === 'lightCog'")
      icon-light-cog
    template(v-else-if="iconName === 'lightTrashAlt'")
      icon-light-trash-alt
    template(v-else-if="iconName === 'lightQuestionCircle'")
      icon-light-question-circle
    template(v-else-if="iconName === 'iconLiveShopPrinter'")
      iconLiveShopPrinter
    template(v-else-if="iconName === 'iconLiveShopPlugins'")
      iconLiveShopPlugins
    template(v-else-if="iconName === 'iconLiveShopView'")
      iconLiveShopView
    template(v-else-if="iconName === 'lightCommentAltEdit'")
      icon-light-comment-alt-edit
    template(v-else-if="iconName === 'lightCommentAltLines'")
      icon-light-comment-alt-lines
    template(v-else-if="iconName === 'lightCommentAlt'")
      icon-light-comment-alt
    template(v-else-if="iconName === 'lightCommentDot'")
      icon-light-comment-dot
    template(v-else-if="iconName === 'lightTag'")
      icon-light-tag
    template(v-else-if="iconName === 'lightStar'")
      icon-light-star
    template(v-else-if="iconName === 'lightEdit'")
      icon-light-edit
    template(v-else-if="iconName === 'lightCheck'")
      icon-light-check
    template(v-else-if="iconName === 'lightImage'")
      icon-light-image
    template(v-else-if="iconName === 'lightShoppingBag'")
      icon-light-shopping-bag
    template(v-else-if="iconName === 'lightVolumeUp'")
      icon-light-volume-up
    template(v-else-if="iconName === 'lightFileAlt'")
      icon-light-file-alt
    template(v-else-if="iconName === 'lightFilm'")
      icon-light-film
    template(v-else-if="iconName === 'lightSmile'")
      icon-light-smile
    template(v-else-if="iconName === 'lightExclamationCircle'")
      icon-light-exclamation-circle
    template(v-else-if="iconName === 'lightUserTie'")
      icon-light-user-tie
    template(v-else-if="iconName === 'lightUserAlt'")
      icon-light-user-alt
    template(v-else-if="iconName === 'lightUserTimes'")
      icon-light-user-times
    template(v-else-if="iconName === 'lightUserAltSlash'")
      icon-light-user-alt-slash
    template(v-else-if="iconName === 'lightClipboardList'")
      icon-light-clipboard-list
    template(v-else-if="iconName === 'lightRobot'")
      icon-light-robot
    template(v-else-if="iconName === 'lightBars'")
      icon-light-bars
    template(v-else-if="iconName === 'lightInboxIn'")
      icon-light-inbox-in
    template(v-else-if="iconName === 'lightInboxOut'")
      icon-light-inbox-out
    template(v-else-if="iconName === 'lightInbox'")
      icon-light-inbox
    template(v-else-if="iconName === 'lightPlusCircle'")
      icon-light-plus-circle
    template(v-else-if="iconName === 'lightEnvelope'")
      icon-light-envelope
    template(v-else-if="iconName === 'lightEnvelopeOpen'")
      icon-light-envelope-open
    template(v-else-if="iconName === 'lightPlus'")
      icon-light-plus
    template(v-else-if="iconName === 'lightCopy'")
      icon-light-copy
    template(v-else-if="iconName === 'lightInfo'")
      icon-light-info
    //- 中體
    template(v-else-if="iconName === 'regularSpinner'")
      icon-regular-spinner
    template(v-else-if="iconName === 'regularSpinnerThird'")
      icon-regular-spinner-third
    template(v-else-if="iconName === 'regularArrowRight'")
      iconRegularArrowRight
    //- 然後是粗體
    template(v-else-if="iconName === 'solidCog'")
      icon-solid-cog
    template(v-else-if="iconName === 'solidCaretUp'")
      icon-solid-caret-up
    template(v-else-if="iconName === 'solidCaretDown'")
      icon-solid-caret-down
    template(v-else-if="iconName === 'solidUserAlt'")
      icon-solid-user-alt
    template(v-else-if="iconName === 'solidTag'")
      icon-solid-tag
    template(v-else-if="iconName === 'solidComment'")
      icon-solid-comment
    template(v-else-if="iconName === 'solidStar'")
      icon-solid-star
    template(v-else-if="iconName === 'solidEdit'")
      icon-solid-edit
    template(v-else-if="iconName === 'solidUserTie'")
      icon-solid-user-tie
    template(v-else-if="iconName === 'solidPlayCircle'")
      icon-solid-play-circle
    template(v-else-if="iconName === 'solidCloudDownloadAlt'")
      icon-solid-cloud-download-alt
    template(v-else-if="iconName === 'solidCheckCircle'")
      icon-solid-check-circle
    template(v-else-if="iconName === 'solidExclamationCircle'")
      icon-solid-exclamation-circle
    template(v-else-if="iconName === 'solidBill'")
      icon-solid-bill
    template(v-else-if="iconName === 'solidRefresh'")
      icon-solid-refresh
    template(v-else-if="iconName === 'solidWarning'")
      icon-solid-warning
    //- 品牌
    template(v-else-if="iconName === 'brandsFacebook'")
      icon-brands-facebook
    template(v-else-if="iconName === 'brandsLine'")
      icon-brands-line
    template(v-else-if="iconName === 'brandsFacebookMessenger'")
      icon-brands-facebook-messenger
    template(v-else-if="iconName === 'brandsChromeColored'")
      icon-brands-chrome-colored
    //- 自製 SVG
    template(v-else-if="iconName === 'customerLightRobotSolidEye'")
      icon-customer-light-robot-solid-eye
    template(v-else-if="iconName === 'customerLightUserTieSolidEye'")
      icon-customer-light-user-tie-solid-eye
    template(v-else-if="iconName === 'customerLightReportSolidEye'")
      icon-customer-light-report-solid-eye
    template(v-else-if="iconName === 'customerLightPostSolidEye'")
      icon-customer-light-post-solid-eye
    template(v-else-if="iconName === 'customerLightChats'")
      icon-customer-light-chats
    template(v-else-if="iconName === 'customerLightUserTagChat'")
      icon-customer-light-user-tag-chat
    template(v-else-if="iconName === 'customerLightPostMessage'")
      icon-customer-light-post-message
    template(v-else-if="iconName === 'customerLightFeedReply'")
      icon-customer-light-feed-reply
    template(v-else-if="iconName === 'customerChatbot'")
      icon-customer-chatbot
    //- 特別
    template(v-else-if="iconName === 'loading'")
      icon-regular-spinner-third.loading
</template>

<script>
// 細線 light
import iconLightTimes from '../assets/icons/icon-light-times.vue'
import iconLightAngleLeft from '../assets/icons/icon-light-angle-left.vue'
import iconLightAngleRight from '../assets/icons/icon-light-angle-right.vue'
import iconLightAngleUp from '../assets/icons/icon-light-angle-up.vue'
import iconLightAngleDown from '../assets/icons/icon-light-angle-down.vue'
import iconLightAngleDoubleLeft from '../assets/icons/icon-light-angle-double-left.vue'
import iconLightAngleDoubleRight from '../assets/icons/icon-light-angle-double-right.vue'
import iconLightSearch from '../assets/icons/icon-light-search.vue'
import iconLightClock from '../assets/icons/icon-light-clock.vue'
import iconLightEllipsisH from '../assets/icons/icon-light-ellipsis-h.vue'
import iconLightCog from '../assets/icons/icon-light-cog.vue'
import iconLightTrashAlt from '../assets/icons/icon-light-trash-alt.vue'
import iconLightQuestionCircle from '../assets/icons/icon-light-question-circle.vue'
import iconLightCommentAltEdit from '../assets/icons/icon-light-comment-alt-edit.vue'
import iconLightCommentAltLines from '../assets/icons/icon-light-comment-alt-lines.vue'
import iconLightCommentAlt from '../assets/icons/icon-light-comment-alt.vue'
import iconLightCommentDot from '../assets/icons/icon-light-comment-dot.vue'
import iconLightTag from '../assets/icons/icon-light-tag.vue'
import iconLightStar from '../assets/icons/icon-light-star.vue'
import iconLightEdit from '../assets/icons/icon-light-edit.vue'
import iconLightCheck from '../assets/icons/icon-light-check.vue'
import iconLightImage from '../assets/icons/icon-light-image.vue'
import iconLightShoppingBag from '../assets/icons/icon-light-shopping-bag.vue'
import iconLightVolumeUp from '../assets/icons/icon-light-volume-up.vue'
import iconLightFileAlt from '../assets/icons/icon-light-file-alt.vue'
import iconLightFilm from '../assets/icons/icon-light-film.vue'
import iconLightSmile from '../assets/icons/icon-light-smile.vue'
import iconLightExclamationCircle from '../assets/icons/icon-light-exclamation-circle.vue'
import iconLightUserTie from '../assets/icons/icon-light-user-tie.vue'
import iconLightUserAlt from '../assets/icons/icon-light-user-alt.vue'
import iconLightUserTimes from '../assets/icons/icon-light-user-times.vue'
import iconLightUserAltSlash from '../assets/icons/icon-light-user-alt-slash.vue'
import iconLightClipboardList from '../assets/icons/icon-light-clipboard-list.vue'
import iconLightRobot from '../assets/icons/icon-light-robot.vue'
import iconLightBars from '../assets/icons/icon-light-bars.vue'
import iconLightInboxIn from '../assets/icons/icon-light-inbox-in.vue'
import iconLightInboxOut from '../assets/icons/icon-light-inbox-out.vue'
import iconLightInbox from '../assets/icons/icon-light-inbox.vue'
import iconLightPlusCircle from '../assets/icons/icon-light-plus-circle.vue'
import iconLightEnvelope from '../assets/icons/icon-light-envelope.vue'
import iconLightEnvelopeOpen from '../assets/icons/icon-light-envelope-open.vue'
import iconLightPlus from '../assets/icons/icon-light-plus.vue'
import iconLightCopy from '../assets/icons/icon-light-copy.vue'
import iconLightInfo from '../assets/icons/icon-light-info.vue'
// 中體 regulat
import iconRegularSpinner from '../assets/icons/icon-regular-spinner.vue'
import iconRegularSpinnerThird from '../assets/icons/icon-regular-spinner-third.vue'
import iconRegularArrowRight from '../assets/icons/icon-regular-arrow-right.vue'
// 填滿 solid
import iconSolidCog from '../assets/icons/icon-solid-cog.vue'
import iconSolidCaretUp from '../assets/icons/icon-solid-caret-up.vue'
import iconSolidCaretDown from '../assets/icons/icon-solid-caret-down.vue'
import iconSolidUserAlt from '../assets/icons/icon-solid-user-alt.vue'
import iconSolidTag from '../assets/icons/icon-solid-tag.vue'
import iconSolidComment from '../assets/icons/icon-solid-comment.vue'
import iconSolidStar from '../assets/icons/icon-solid-star.vue'
import iconSolidEdit from '../assets/icons/icon-solid-edit.vue'
import iconSolidUserTie from '../assets/icons/icon-solid-user-tie.vue'
import iconSolidPlayCircle from '../assets/icons/icon-solid-play-circle.vue'
import iconSolidCloudDownloadAlt from '../assets/icons/icon-solid-cloud-download-alt.vue'
import iconSolidBill from '../assets/icons/icon-solid-bill.vue'
import iconSolidCheckCircle from '../assets/icons/icon-solid-check-circle.vue'
import iconSolidExclamationCircle from '../assets/icons/icon-solid-exclamation-circle.vue'
import iconSolidRefresh from '../assets/icons/icon-solid-refresh.vue'
import iconSolidWarning from '../assets/icons/icon-solid-warning.vue'
// 品牌用 brands
import iconBrandsFacebook from '../assets/icons/icon-brands-facebook.vue'
import iconBrandsLine from '../assets/icons/icon-brands-line.vue'
import iconBrandsFacebookMessenger from '../assets/icons/icon-brands-facebook-messenger.vue'
import iconBrandsChromeColored from '../assets/icons/icon-brands-chrome-colored.vue'
// 自製 customer
import iconCustomerLightRobotSolidEye from '../assets/icons/icon-customer-light-robot-solid-eye.vue'
import iconCustomerLightUserTieSolidEye from '../assets/icons/icon-customer-light-user-tie-solid-eye.vue'
import iconCustomerLightReportSolidEye from '../assets/icons/icon-customer-light-report-solid-eye.vue'
import iconCustomerLightPostSolidEye from '../assets/icons/icon-customer-light-post-solid-eye.vue'
import iconCustomerLightChats from '../assets/icons/icon-customer-light-chats.vue'
import iconCustomerLightUserTagChat from '../assets/icons/icon-customer-light-user-tag-chat.vue'
import iconCustomerLightPostMessage from '../assets/icons/icon-customer-light-post-message.vue'
import iconCustomerLightFeedReply from '../assets/icons/icon-customer-light-feed-reply.vue'
import iconCustomerChatbot from '../assets/icons/icon-customer-chatbot.vue'

import iconLiveShopPrinter from '../assets/icons/icon-printer.vue'
import iconLiveShopPlugins from '../assets/icons/icon-plugin.vue'
import iconLiveShopView from '../assets/icons/icon-view.vue'

export default {
  props: {
    iconName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  components: {
    iconLightTimes,
    iconLightAngleLeft,
    iconLightAngleRight,
    iconLightAngleUp,
    iconLightAngleDown,
    iconLightAngleDoubleLeft,
    iconLightAngleDoubleRight,
    iconLightSearch,
    iconLightClock,
    iconLightEllipsisH,
    iconLightCog,
    iconLightTrashAlt,
    iconLightQuestionCircle,
    iconLightCommentAltEdit,
    iconLightCommentAltLines,
    iconLightCommentAlt,
    iconLightCommentDot,
    iconLightTag,
    iconLightStar,
    iconLightEdit,
    iconLightCheck,
    iconLightImage,
    iconLightShoppingBag,
    iconLightVolumeUp,
    iconLightFileAlt,
    iconLightFilm,
    iconLightSmile,
    iconLightExclamationCircle,
    iconLightUserTie,
    iconLightUserAlt,
    iconLightUserTimes,
    iconLightUserAltSlash,
    iconLightClipboardList,
    iconLightRobot,
    iconLightBars,
    iconLightInboxIn,
    iconLightInboxOut,
    iconLightInbox,
    iconLightPlusCircle,
    iconLightEnvelope,
    iconLightEnvelopeOpen,
    iconLightPlus,
    iconLightCopy,
    iconLightInfo,
    //
    iconRegularSpinner,
    iconRegularSpinnerThird,
    iconRegularArrowRight,
    //
    iconSolidCog,
    iconSolidCaretUp,
    iconSolidCaretDown,
    iconSolidUserAlt,
    iconSolidTag,
    iconSolidComment,
    iconSolidStar,
    iconSolidEdit,
    iconSolidUserTie,
    iconSolidPlayCircle,
    iconSolidCloudDownloadAlt,
    iconSolidCheckCircle,
    iconSolidExclamationCircle,
    iconSolidBill,
    iconSolidRefresh,
    iconSolidWarning,
    //
    iconBrandsFacebook,
    iconBrandsLine,
    iconBrandsFacebookMessenger,
    iconBrandsChromeColored,
    //
    iconCustomerLightRobotSolidEye,
    iconCustomerLightUserTieSolidEye,
    iconCustomerLightReportSolidEye,
    iconCustomerLightPostSolidEye,
    iconCustomerLightChats,
    iconCustomerLightUserTagChat,
    iconCustomerLightPostMessage,
    iconCustomerLightFeedReply,
    iconCustomerChatbot,
    //
    iconLiveShopPrinter,
    iconLiveShopPlugins,
    iconLiveShopView
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>
