<template lang="pug">
#app
  page-aside(v-if="booIsSide", :boolActive="$root.toggleAside")
  page-header(v-if="booIsHeader", :boolActive="$root.toggleAside")
  router-view(:boolActive="$root.toggleAside")
  cover-bg
  QRcodeView(v-if="$root.qrcode_view_status")
  .order-workers(v-if="$root.showDownloadView")
    OrderWorker(v-for="Worker in $root.orderWorkers", :Worker="Worker")
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import PageAside from '@/common/page-aside.vue'
import pageHeader from '@/common/page-header.vue'
import CoverBg from '@/common/cover-background'
import QRcodeView from '@/components/QRcode.vue'
import OrderWorker from '@/components/OrderWorker.vue'

export default {
  name: 'App',
  components: {
    PageAside,
    pageHeader,
    CoverBg,
    QRcodeView,
    OrderWorker
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('ui', {
      boolAsideActive: 'boolAsideActive', // 側邊欄是否開啟
      arrNeedSidePages: 'arrNeedSidePages', // 需要顯示側邊欄的頁面
      arrNeedHeaderPages: 'arrNeedHeaderPages'
    }),
    booIsSide () {
      return this.arrNeedSidePages.indexOf(this.$route.name) !== -1
    },
    booIsHeader () {
      return this.arrNeedHeaderPages.indexOf(this.$route.name) !== -1
    }
  },
  created () {
    this.$root.console('--> LiveShop 執行 Main Created')
    // 更換網頁標題
    $('title').html("LiveShop | Let's sell on Live")
  },
  mounted () {
    this.$root.objWindow.addEventListener('load', this.$root.processMenu)
    this.$root.objWindow.addEventListener('resize', this.$root.processMenu)
  },
  beforeDestroy () {
    this.$root.objWindow.removeEventListener('load', this.$root.processMenu)
    this.$root.objWindow.removeEventListener('resize', this.$root.processMenu)
    this.$root.objWindow = null
  },
  methods: {

  },
}
</script>

<style lang="sass">
.order-workers
  position: fixed
  background: rgba(0,0,0,.4)
  width: 100%
  height: 100%
  z-index: 9
</style>
