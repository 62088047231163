<template lang="pug">
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      // 邀請代碼
      code: ''
    }
  },
  created () {
    if (this.$route.params.code) {
      this.code = this.$route.params.code
      setTimeout(() => { this.checkStatus() }, 800)
    }
  },
  methods: {
    // 確認目前狀態以準備請求加入管理員
    checkStatus () {
      this.$root.console('--> 驗證是否已經登入')
      if (!this.$root.user.id) {
        this.$root.console('--> 用戶未登入，導引至登入頁...')
        // 把 code 寫進暫存器
        this.$cookies.set('invite-code', this.code);
        // 請會員先去登入
        this.$router.push({ name: 'MainPage' })
        return false
      }

      this.$root.console('uid: ' + this.$root.user.id);
      this.bindManager()
    },
    // 請求綁定管理員
    bindManager () {
      this.$http.post('manager/v1/admin/invite/' + this.code).then(function (_response) {
        if (_response.body.code === '0000') {
          // 綁定成功，導引到商店目錄
          this.$router.push({ name: 'Shops' })
        }
      }, function (_response) {
        if (_response.body.code === 1051) {
          this.$router.push({ name: 'Shops' })
        }
      })
    }
  }
}
</script>
