<template lang="pug">
.signin-page.container-fluid
  //- 步驟說明頭部
  .step-info.step-info-xs.d-lg-none
    span(v-if="$root.user.platform === 1") {{ $t('message.signin_step_01_title_connect_page') }}
    span(v-if="$root.user.platform === 2") {{ $t('message.signin_step_01_title_connect_channel') }}
  .step-info.step-info-lg.d-none.d-md-block
    span(v-if="$root.user.platform === 1") {{ $t('message.signin_step_01_title_connect_page') }}
    span(v-if="$root.user.platform === 2") {{ $t('message.signin_step_01_title_connect_channel') }}
  .signin-content
    .row
      .col-2
      .col-8.text-center
        //- Logo 區塊
        .header-logo.d-lg-none
          //- 間隔空間
          .p-5
          //- Logo
          img.text-center(src="../assets/image/logo.png")
          //- 間隔空間
          .p-4
        //- Banner 區域
        .signin-banner.d-none.d-md-block
          img(src="../assets/image/main-banner.png")
        //- 手機版其他說明文字
        .d-lg-none.signin-text-xs
          .signin-text-01 
            span(v-if="$root.user.platform === 1") {{ $t('message.signin_info_context_page_01') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.signin_info_context_line_01') }}
          .signin-text-02 
            span(v-if="$root.user.platform === 1") {{ $t('message.signin_info_context_page_02') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.signin_info_context_line_02') }}
        //- 電腦版其他說明文字
        .d-none.d-md-block.signin-text-lg
          .signin-text-01
            span(v-if="$root.user.platform === 1") {{ $t('message.signin_info_context_page_01') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.signin_info_context_line_01') }}
          .signin-text-02
            span(v-if="$root.user.platform === 1") {{ $t('message.signin_info_context_page_02') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.signin_info_context_line_02') }}
        //- 間隔空間
        .d-lg-none
          .p-5
        //- 開始連接按鈕
        .sign-in-connect-button
          button.btn-line-login(
            type="button",
            @click="connectLine()",
            v-if="platform === 'line'"
          )
            .overlay
            .icon
              .icon-inner
                img(src="../assets/image/line-b.png")
            span {{ $t('message.signin_start_connect_button') }}

          button.btn-facebook-login(
            type="button",
            @click="connectFb()",
            v-if="platform === 'facebook'"
          )
            .overlay
            .icon
              .icon-inner
                img(
                  src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png"
                )
            span {{ $t('message.signin_start_connect_button') }}
      .col-2
</template>

<script>
export default {
  data () {
    return {
      platform: 'facebook'
    }
  },
  created () {
    this.$root.token = this.$root.getCookie()
    if (this.$root.$route.params.platform) {
      this.platform = this.$root.$route.params.platform
    }
    this.$root.console('Access Token:', this.$root.token)
  },
  methods: {
    // 點擊 FaceBook 連接時
    connectFb () {
      window.location.href = this.$root.web_url + 'users/access/authority'
    },
    // 點擊 Line 連接時
    connectLine () {
      window.location.href = this.$root.web_url + 'users/access/channel/' + this.$root.token
    }
  }
}
</script>

<style scoped lang="sass">
.signin-page
  .step-info
    margin-left: -15px
    margin-right: -15px
    padding: 5px 15px
    font-weight: bold
    background: #F2F2F2
    // ==== 處理進度漸層色 ====
    border-bottom: 5px solid transparent
    border-image: linear-gradient(to right, #1977F2 0%, #D1D1D1 0%)
    border-image-slice: 1
  .step-info-xs
    font-size: 1.3em
  .step-info-lg
    font-size: 3em
  .signin-content
    .signin-banner
      margin: 0px auto
      margin-top: 10%
      img
        margin: 0px auto
        max-width: 100%
        max-height: 350px
        margin-bottom: 25px
    .signin-text-xs
      font-size: 1em
    .signin-text-lg
      font-size: 1.5em
    .sign-in-connect-button
      text-align: center
      margin-bottom: 35px
      margin-top: 25px
</style>
