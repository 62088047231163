<template lang="pug">
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
      // 訂單編號
      number: ''
    }
  },
  created () {
    if (this.$route.params.number) {
      this.number = this.$route.params.number
    }

    setTimeout(() => { this.checkStatus() }, 1000)
  },
  methods: {
    // 確認目前狀態以準備請求加入管理員
    checkStatus () {
      this.$root.console('--> 驗證是否已經登入')
      if (!this.$root.user.id) {
        this.$root.console('--> 用戶未登入，導引至登入頁...')
        // 把 number 寫進暫存器
        this.$cookies.set('goto-order', this.number);
        // 請會員先去登入
        this.$router.push({ name: 'MainPage' })
        return false
      }

      this.$root.console('uid: ' + this.$root.user.id);
      this.needGoToOrder()
    },
    // 需要前往訂單頁
    needGoToOrder () {
      this.$http.get('manager/v1/order/' + this.number + '/info').then(function (_response) {
        if (_response.body.code === '0000') {
          let order = _response.body.data
          this.$root.shopID = order.shop
          //  前往訂單頁
          this.$router.push({ name: 'Order', params: { shop: order.shop, id: order.number } })
        }
      }, function () {
        // 前往商店清單
        this.$router.push({ name: 'Shops' })
      })
    }
  }
}
</script>