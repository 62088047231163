<template lang="pug">
  .privacy-policy-page
    .container-fluid
      .title
        .row
          .col-md-6.col-sm-12
            h1 隱私權條款
          .col-md-6.col-sm-12
            a(href="/main")
              img.logo(src="../assets/image/logo.png", alt="alt")
      .context
        p 法可創新股份有限公司(以下「本公司」) 為保障您於使用所有在 LiveShop 網站及相關產品與軟體（以下簡稱「本服務」）之隱私權，基於承諾保護及尊重您的個人隱私，本公司將遵循保護個人隱私原則及「個人資料保護法」等個人資料安全之法令要求；本公司隱私權保護政策(以下簡稱，本政策)將幫助您了解，我們如何蒐集、處理、利用、保護個人資料以及我們告知個人資料的對象，請詳閱以下內容：
        h2 一、個人資料蒐集及利用：
        p 本隱私政策載入《LiveShop 服務條款》之內，構成該等條款的一部份，您已為使用我們的服務而同意該等條款。除本隱私政策或內文另有規定外，本隱私條款所用詞語將與《LiveShop 服務條款》所定義的詞語具有相同的涵義。
        p 依照個人資料保護法第八條規定，對於客戶資料之蒐集、處理、利用等，須告知您相關事項如下，請詳閱：
        p 適用範圍：
        p 當您透過本服務註冊會員、登錄、購買商品、參加本服務所舉辦之網路活動、參加本服務所舉辦之行銷、產品宣傳活動，問券調查或贈獎活動，或進行滿意度調查、獲取意見回饋、回覆您的提問或資訊查詢、提供及改善服務、使用者經驗調查及分析等客戶服務時，除本服務之個人資料蒐集、處本公司理及利用之告知事項，請參閱本服務之服務條款外，本公司將依以下之規定蒐集、處理及利用您的個人資料。
        p 瀏覽本公司或本服務網站，本公司會自動接收並記錄您電腦及行動裝置上之資料，包括位置數據、Cookies、軟體和硬體屬性、您下載 APP 紀錄、付款資訊、信用卡資訊（請注意，我們不會保留您的信用卡資訊，因為您的信用卡付款將由適當的付款機構處理。）、推廣活動和客戶調查的聯繫資訊以及客戶支援的聯繫資訊。該等資料通常無法直接或間接辨識為個人資料。但如該等資料足以直接或間接辨識為個人資料時，本公司對於個人資料之蒐集、利用、處理，則依本條之規定為告知。
        p 本公司及本服務可能包含其他網站或網頁的連結或使用第三人提供之服務，對於此等不屬於本公司及本服務之連結或服務，您應了解其蒐集個人資料的行為毋論其內容或隱私權政策，概與本公司及本服務無涉。
        p 蒐集目的：
        p 本公司及本服務作為資料控制者或共同控制者，為了核實身份、行銷、客戶管理與服務、提供網路購物及其他數據服務服務、履行法定或合約義務、保護當事人及相關利害關係人之權益、售後服務、經營合於營業登記項目或組織章程所定之業務以及履行法定義務等目的，依照各該服務之性質，可能蒐集您的姓名、連絡方式(包括但不限於電話、E-MAIL及地址等)、服務單位、職稱、為完成收款或付款所需之資料、位置數據、及其他得以直接或間接識別使用者身分之個人資料。
        p 此外，為提升服務品質，本公司及本服務會依照所提供服務之性質，記錄使用者的位置數據、以及在本公司或本服務相關網站內的瀏覽活動(例如，使用者所使用的軟硬體、所點選的網頁)等資料，但是前述資料僅供作流量分析和網路行為調查，以便於改善本公司及本服務相關網站的服務品質，前述資料亦僅做為總量上的分析，不和特定個人連結。
        p 利用之期間、地區、對象及方式 利用之期間：
        p 除法律另有規定外，以您要求停止使用您的個人資料或本服務停止提供各該相關之服務之日為止。
        p 利用之地區：您的個人資料將使用於中華民國地區。
        p 利用之對象：本公司、關係企業、業務委外機構、宅配貨運業者及相關配合之廠商，或與本公司因業務需要訂有契約關係或業務往來之公私機構，或依法有權之機關。
        p 利用之方式：符合個人資料相關保護法令以自動化機器或其他非自動化之利用方式。
        p 依照個人資料保護法第三條規定，您得就其所提供之個人資料行使下列權利，您如欲行使各該，可與本服務客服連絡進行申請：
        p 查詢或請求閱覽。
        p 請求製給複製本。
        p 請求補充或更正。
        p 請求停止蒐集、處理或利用。
        p 請求刪除。
        p 由選擇是否提供個人資料予本公司或本服務，惟一旦您拒絕提供完成註冊程序成為會員所需必要之資料，或所提供之資料不完整，將可能導致無法享受完整服務或完全無法使用本服務，例如未提供身分證字號，將無法參與本服務舉辦之行銷等相關活動。
        p 本公司或本服務供之資料，確認您的身分真實性或有其他個人資料冒用、盜用、資料不實等情形。如您所提供之資料有任何偽冒不實，應自負一切民、刑事法律責任。
        h2 二、資料分享與揭露：
        p 當您瀏覽本服務網頁，本公司及本服務會自動接收並記錄您電腦及行動裝置上之資料，包括位置數據、Cookies、軟體和硬體屬性、您下載APP 紀錄、推廣活動和客戶調查的聯繫資訊以及客戶支援的聯繫資訊。
        p 本服務記錄前開資料後，為提供您更好的服務，並針對您的需求提供個人化的服務內容，本公司或本服務會向您傳送相關特定訊息，如服務公告、行銷廣告、管理訊息等相關資訊。
        p 為了達到下列目的，對個人資訊的存取、使用、保存或揭露屬合理必要行為時，我們將與其他單位或機構分享您的個人資訊，並要求他人遵守本公司或本服務指示，以及本政策和任何其他適當的保密和安全措施：
        p 符合適用法律、法規、法律程序或政府執行命令。
        p 執行適用本服務條款，包括調查可能的違規情事。
        p 偵測、防止或以其他方式處理詐欺、安全或技術問題。
        p 法律規定或在合法範圍內，保護本公司、本服務及使用者或公眾之權利、財產或安全不致遭受危害。
        h2 三、Cookies：
        p 「Cookies」，會在您瀏覽網站或頁面時由該網站傳送到您的電腦中，當您再次瀏覽，就能透過 Cookies 辨識您的瀏覽器。
        p Cookie 可能會儲存使用者偏好設定和其他資訊。您可以將瀏覽器重設為拒絕所有 Cookies，或是在傳送 Cookies 時顯示提醒。不過，若不啟用 Cookies，有些網站功能或服務可能無法正常運作。
        p 本公司及本服務會在您的電腦或行動裝置上設定並存取本服務之 Cookies 資料。
        p 本公司及本服務允許在某些本服務的網頁上刊登廣告之其他公司在您的電腦或行動裝置上設定並存取 Cookies。其他公司將根據其自訂之隱私權政策使用其 Cookies，並非依據本政策。廣告主或其他公司無法存取本公司及本服務之 Cookies 資料。
        p 本公司及本服務會使用網路存取我們的網站內外與產品及服務有關的 Cookies。
        h2 四、機密性和安全性：
        p 您可以透過申請會員帳戶並提供部分個人資訊 (通常是您的姓名、電子郵件地址和一組密碼)來使用我們的部分服務。當您使用本服務時，我們會運用這些帳戶資訊驗證您的身分，防止他人未經授權存取您的帳戶。
        p 本公司及本服務主機設有防火牆、防毒等各種符合行業標準的資訊安全保護措施，包括作業程序、技術等層面。 本公司及本服務已確實採取實體、電子和程序防護措施。
        p 請不要將您的密碼告知他人或使用與其他設備相同的密碼。同時，若您懷疑本服務出現任何未經授權使用您帳戶的行為，或任何其他不安全行為，請通知我們。
        p 由於任何電子傳輸方法或資料儲存方法都不是無懈可擊，我們無法擔保您的資訊在系統傳輸或儲存過程中百分之百安全，不被攻擊。
        h2 五、隱私權保護政策修訂：
        p 本公司及本服務將保留隨時修訂本政策之權利，隱私政策首次發佈的日期是 2018 年 2 月 2 日。
        p 若我們對隱私政策作出任何重大調整，我們將在生效前於服務中或透過網站公告其他方式告知您。
        p 在向您發出、推送變更通知或透過網站公告後，若您需要繼續使用我們的服務，則視為您接受變更，同意修改後的隱私政策。
        p 本隱私政策的現行版本參見我們的網站
        h2 六、著作權侵害之處理：
        p 如您認為本公司、本服務或網站中之任何內容或資訊已侵害您的著作權，請提供以下資訊：
        p 權利人或其代理人之姓名或名稱、地址及聯絡電話、傳真號碼、電子郵件信箱或其他自動聯繫方式被侵害之著作或製版之名稱。
        p 請求將涉有侵害著作權或製版權之內容移除或使他人無法進入之聲明。
        p 足使本公司或本服務知悉該涉有侵權內容之相關資訊及其存取路徑。
        p 表示權利人係基於善意，相信涉有侵權內容係未經合法授權或違反著作權法之陳述。
        p 註明如有不實致他人受損害者，權利人願負法律責任等相關資料及聲明，並經簽名或蓋章後送交本公司或本服務客服信箱。如您認為您並未侵害他人之著作權者，請提供以下資訊：
        p 使用者或其代理人之姓名或名稱、地址及聯絡電話、傳真號碼或電子郵件信箱。
        p 請求回復被移除或無法進入之內容之聲明。足使本公司或本服務知悉該回復內容之相關資訊。
        p 表示使用者基於善意，認為其有合法權利利用該內容，而該內容被移除或使他人無法進入，係出於權利人或其代理人不實或錯誤之陳述。
        p 同意本公司或本服務將回復通知轉送予權利人或其代理人。
        p 註明如有不實致他人受損害者，使用者願負法律責任等相關資料及聲明，並經簽名或蓋章後寄送至本公司或本服務。
        h2 七、涉嫌侵害您的著作權的處理原則：
        p 本公司及本服務為尊重智慧財產權，對於本服務中之任何內容或資訊涉嫌侵害您的智慧財產權者，本公司及本服務之處理原則如下：
        p 本公司或本服務接獲權利人的通知書後，將儘速移除權利人所主張涉有侵害著作權之內容，並依據使用者約定之聯絡方式或使用者留存之聯絡資訊，轉知使用者涉有侵權情事。
        p 本公司及本服務得將處理情況，包括權利人的姓名、電子郵件或電話等提供予該使用者，俾該使用者得直接與權利人溝通解決爭議。
        p 若著作權侵權通知不完備者，本公司或本服務將會通知權利人補正相關資訊。
        p 使用者的內容倘若經權利人主張涉有侵害其著作權者，該內容一旦經本公司或本服務刪除後，即無法再行回復。又使用者如有三次涉有侵權情事者，本公司及本服務將終止該使用者全部或部分之本服務。
        p 但倘若使用者對於權利人主張的侵權情事有不同意者，該使用者得檢具通知文件要求本公司或本服務將該通知文件轉知權利人以表示異議。
        p 若使用者的通知不完備者，本公司或本服務將會通知使用者補正相關資訊。
        p 本公司或本服務於接獲前項之通知文件後，會立即將該通知文件轉送給權利人。
        p 若權利人未於接獲該通知文件之次日起十個工作日內，提出已對該使用者提出訴訟之證明者，該使用者將可以自行重新上傳該內容。
        p 前述權利人或使用者的通知若不完備者，本公司或本服務得以其所提供之電子郵件或傳真通知補正相關資訊。 權利人或使用者必須於接到本公司或本服務補正通知之次日起五個工作日內完成補正，屆期未補正或未完全補正者，依著作權法之規定即為未提出通知。
        p 除經使用者同意或於提供服務之目的者外，本公司及本服務僅得依法令或政府機關之要求，提供使用者之識別資料予第三人。
        p 您如依本辦法提出檢舉，本公司及本服務僅會依您的請求移除該侵權內容，不會提供您有關該使用者之識別資料。
        p 您如欲取得該使用者資料，您應向地方法院檢察署或刑事警察局提出告訴，本公司及本服務將於前開單位來函要求提供使用者資料後，即刻配合提供之。
        h2 八、問題和建議：
        p 若您對隱私政策或我們的其他隱私政策或行為存在任何疑問、意見、建議或擔擾，請透過客服信箱與本公司或本服務團隊聯繫。
</template>

<script>
export default {
  components: {

  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="sass">
.privacy-policy-page
  margin: 0px auto
  max-width: 850px
  .title
    padding-bottom: 25px
    padding-top: 15px
    h1
      margin: 10px 5px 10px 5px
    img.logo
      float: right
      height: 50px
  .context
    padding-bottom: 25px
    h2
      margin: 15px 5px 15px 5px
    p
      padding-left: 2em
</style>