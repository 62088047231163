<template lang="pug">
.report-page
  .order-title.text-center
    h1 {{ $t('message.receipt_page_title').replace('%s', number) }}
  .order-info.text-left
    .text {{ $t('message.receipt_page_order_no') }} : {{ number }}
    .text {{ $t('message.receipt_page_buyer_name') }} : {{ order.member.name }}
    .text {{ $t('message.receipt_page_buyer_phone') }} : {{ order.recipient_phone }}
    .text {{ $t('message.receipt_page_buyer_postcode') }} : {{ order.recipient_zipcode }}
    .text {{ $t('message.receipt_page_buyer_address') }} : {{ order.recipient_address }}
  .p-3
  .order-items
    table.table
      thead
        th.text-center {{ $t('message.receipt_page_table_title_01') }}
        th.text-center {{ $t('message.receipt_page_table_title_02') }}
        th.text-center {{ $t('message.receipt_page_table_title_04') }}
      tbody
        tr.text-center(v-for="(detail, index) in order.details")
          td {{ index + 1 }}
          td {{ detail.name }}
          td {{ detail.quy }}
  .p-2
  hr
  .p-2
  .order-ship-info
    .logo.float-left
      img.logo-img(src="../assets/image/sm_logo.png")
    .content.float-left
      .text
        b {{ $t('message.receipt_page_table_from_name') }} : {{ $root.shop.sender.name }}
      .text
        | {{ $t('message.receipt_page_table_from_address') }} : {{ $root.shop.sender.zipcode }} {{ $root.shop.sender.address }}
      .text
        | {{ $t('message.receipt_page_table_from_phone') }} : {{ $root.shop.sender.phone }}
    .clearall
  hr
  .order-ship-to-info
    .text 
      b {{ $t('message.receipt_page_table_to_name') }} : {{ order.member.name }}
    .text 
      | {{ $t('message.receipt_page_table_to_address') }} : {{ order.recipient_zipcode }}
    .text 
      | {{ order.recipient_address }}
    .text 
      | {{ $t('message.receipt_page_table_to_phone') }} : {{ order.recipient_phone }}
</template>

<script>
export default {
  data () {
    return {
      number: '',
      order: {
        member: {
          name: ''
        },
        recipient_phone: '',
        ship_code: '',
        recipient_address: '',
        details: [],
        total: 0,
        ship_cost: 0,
        currency: 'TWD',
        sender: {
          name: "",
          phone: "",
          zipcode: "",
          address: ""
        },
      }
    }
  },
  created () {
    if (this.$route.params.number) {
      this.number = this.$route.params.number
    }

    this.getOrder()
  },
  methods: {
    // 取得目前訂單與資訊
    getOrder () {
      // 取得商店 ID
      let shopID = this.$root.shopID
      // 取得訂單資料
      this.$http.get('manager/v1/shop/' + shopID + '/order/' + this.number).then(function (_response) {
        if (_response.body.code === '0000') {
          // 灌入訂單資料
          this.order = _response.body.data
          // 重新計算訂單
          this.processOrder()
        }
      }, function (_response) {
        this.useOrderErr = _response.body.code
      })
    },
    // 重新計算訂單
    processOrder () {
      // 重新計算費用
      let total = 0
      this.order.details.forEach(function (_detail) {
        if (_detail.price && _detail.quy) {
          total += parseFloat(parseFloat(_detail.price) * parseFloat(_detail.quy))
        }
      })
      // 增加運費
      total += parseFloat(this.order.ship_cost)
      // 將數字格式化
      this.order.total = this.$root.common.formatNumber(total)
      this.order.subtotal = parseInt(total) - parseInt(this.order.ship_cost)
      // 列印頁面
      setTimeout(() => { this.printPage() }, 2000);
    },
    // 列印頁面
    printPage () {
      var text = document
      print(text)
    }
  }
}
</script>

<style lang="sass">
.report-page
  width: 100%
  padding-left: 15px
  padding-right: 15px
  .float-left
    float: left
  .text
    font-size: 18px
  .order-ship-info
    .logo
      img.logo-img
        max-width: 80px
    .content
      padding-left: 15px
</style>