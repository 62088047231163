<template lang="pug">
  .privacy-policy-page
    .container-fluid
      .title
        .row
          .col-md-6.col-sm-12
            h1 使用條款
          .col-md-6.col-sm-12
            a(href="/main")
              img.logo(src="../assets/image/logo.png", alt="alt")
      .context
        p 本服務條款（以下簡稱「本條款」）是法可創新股份有限公司（以下簡稱「本公司」）所提供所有在 LiveShop 網站及相關產品與軟體（以下簡稱「本服務」）的使用條件，由使用本服務的用戶互動的自然人或法人（以下簡稱「用戶」）與本公司間訂定的條款。
        h2 一、本服務說明：
        p 本服務為貴用戶提供存取各種有限 LiveShop 服務的權限，包括任何更新、加強程式、新功能及/或任何新增的 Web 屬性均受本使用條款所拘束。如果用戶沒有 LiveShop 付費會員帳戶，則這些使用條款將適用於在沒有付費會員帳戶的情況下，用戶可以使用之有限 LiveShop 服務。
        h2 二、對於條款的同意表示：
        p 本服務為貴用戶提供存取各種有限 LiveShop 服務的權限，包括任何更新、加強程式、新功能及/或任何新增的 Web 屬性均受本使用條款所拘束。如果用戶沒有 LiveShop 付費會員帳戶，則這些使用條款將適用於在沒有付費會員帳戶的情況下，用戶可以使用之有限 LiveShop 服務。
        p 用戶應遵照本條款規定使用本服務。除非用戶有效且不可撤銷地同意本條款，方能使用本服務。 如用戶為未成年人，須經過親權人等法定代理人同意後，方能使用本服務。此外，如用戶是為業者使用本服務時，亦請於該業者同意本條款後，再使用本服務。
        p 本服務一經用戶實際使用，將視為用戶已有效且不可撤銷地同意本條款。 本服務有個別使用條款時，除本條款外，用戶亦應遵照個別使用條款規定使用本服務。
        h2 三、條款的變更
        p 於本公司判斷有必要時，得在不事先通知用戶的情形下隨時變更本條款及個別使用條款。變更後的本條款及個別使用條款自公布在本公司經營網站內的適當處時起生效。
        p 如用戶於本條款及個別使用條款變更後仍繼續使用本服務，將視為用戶已有效且不可撤銷地同意變更後的本條款及其所適用的個別使用條款。由於本公司不會個別通知用戶相關變更內容，故請用戶於使用本服務時，隨時參閱最新版本的本條款及其所適用的個別使用條款。
        h2 四、會員帳號、密碼及安全性：
        p 如果有任何情況需要用戶開立帳號，用戶應依照適用之註冊表上所提示的內容，提供完整、真實且正確的最新資訊，以完成註冊程序，並經常進行修改以更新資料。
        p 用戶必須選擇密碼及使用者名稱，對於用戶密碼及任何其他非公開帳戶資訊機密性之維護，用戶應負完全責任並自行負責嚴密保管，以免遭到不當使用，利用該密碼所進行的一切行為均視為用戶本人的行為，用戶必須對所開立帳號下之一切活動負完全責任。
        p 用戶同意，若有任何人未經授權使用貴用戶之帳號，或是有其他違反安全性之行為，均應立即通知本公司。
        p 若因為他人使用用戶之使用者名稱、密碼或帳戶，而導致用戶遭受損失，則不論用戶是否知情，本公司均不負任何賠償責任。若因為他人使用用戶之使用者名稱、密碼或帳戶而導致本公司或其他人遭受損失，則用戶可能需負賠償責任。
        p 本服務的帳號專屬於用戶個人，在未取得帳號擁有人許可的情況下，任何時候用戶均不得使用他人之帳號。
        p 本公司認為用戶違反本條款或有違反之虞時，得在不事先通知用戶的情形下對該帳號進行停權或刪除。
        h2 五、隱私權
        p 本公司尊重用戶的隱私權，與用戶之資訊的蒐集和使用相關之揭露，請參閱
          a(href="/privacy-policy") 隱私權政策
          | 。
        p 本公司依照 LiveShop 隱私權政策，妥善處理用戶的隱私資料與個人資料。
        p 本公司將會以安全的方式管理自用戶處蒐集的資料，並對安全管理採取嚴格的措施。
        h2 六、廣告刊登
        p 本公司得在本服務刊登本公司、用戶或第三人的廣告。
        h2 七、資料：
        p 本服務，連同網站一起提供的所有資料及連結的內容，包括但不限於：軟體、說明主題、白皮書、介面、資料表、視訊及常見問題集等內容(以下合稱「資料」)，均屬 LiveShop 及/或其供應商受著作權法保護之著作。本公司及本服務之元件已受商品形象、商標、不公平競爭及其他法律之保護，其全部或一部分內容一概不得複製或抄襲。
        p 除非本公司明示許可，否則任何本服務及網站上之標誌、圖形、聲音或影像檔一概不得進行複製或轉載。
        p 本公司及本服務對資料之內容得擁有專利權、專利申請權、商標權、著作權或其他智慧財產權，除非本公司或本服務以書面方式明示規定，否則提供資料給用戶並不代表給予用戶上述專利權、商標權、著作權或其他智慧財產權之任何使用權。
        p 本服務並不會將服務或用戶所存取資料的任何智慧財產權授予用戶，除非相關資料的擁有者同意或法律允許，授權條款中未明示授與之資料的所有其他權利，均保留為本公司、本服務及/或其供應商所有。
        p 對於用戶提供予本公司或本服務之任何資料(包括回饋意見及建議)，或刊登、上傳、輸入或送出至任何網站服務，供一般大眾或任何公開或私人社群會員檢視之資料(以下合稱「提交資料」)，本公司或本服務不會主張該些資料之所有權。但若用戶刊登、上傳、輸入、提供或送出用戶之提交資料，即表示用戶免費授權予本公司及其關係企業，及必要之轉授權擁有人與其網際網路業務(包括但不限於本公司及本服務)作業相關之提交資料的使用權，前述授權包括但不限於下列之使用權權利：複製、散布、傳輸、公開展示、公開履行、重製、編輯、翻譯，及重新格式化用戶之提交資料； 公佈與用戶之提交資料相關之姓名；將該等權利轉授權給網站服務之任何供應商。
        p 用戶對資料之使用，需受資料授權條款及本條款所拘束，不得超出本服務所訂定的使用樣態而使用，或有下列任一行為：移除、遮蓋、修改、竄改或變造任何服務所顯示之著作權標示或隨附顯示的任何法律聲明； 將資料散布予第三方，包括任何重製、傳送、轉載、更動及在連線電腦上張貼資料，供網路上之任何其他電腦存取，或在任何媒體中公開傳播資料；對資料進行任何修改。
        h2 八、軟體
        p 本服務提供之任何軟體（下稱「軟體」）均屬 LiveShop 及/或其供應商受著作權法保護之著作。 軟體之使用需受可能隨附或包含之授權條款(以下稱「授權合約」)所拘束。
        p 使用者若未先同意授權合約，則視為未獲授權安裝任何軟體。
        p 軟體係根據授權合約，僅就使用者之使用提供下載。
        p 未依照授權合約而對軟體進行任何之重製或轉散布皆已明文禁止。
        h2 九、業務合作夥伴服務
        p 本服務內可能包含其他與本公司有業務合作的業者及用戶所提供的服務或資料。該等服務或資料所應負責任，概由提供服務或資料的業者負擔。此外，提供服務或資料的業者所規定的使用條款及其他條件，可能適用於該等服務或資料。
        h2 十、服務內容
        p 本公司就所提供的服務授予用戶使用權，如另有規定使用費、使用期間等使用條件時，即應遵照該等使用條件。
        p 如貴用戶已同意前項所示之使用條件，於任何情況下均不得要求本公司終止本服務及/或退還已支付之費用。
        p 即使本服務介面上有顯示「購買」、「販賣」等文字，本公司對業務合作夥伴及用戶提供的相關智慧財產權及其他權利亦不因此移轉給用戶，對用戶僅有授予上述使用權。
        p 本服務得包含訊息或通訊設施，並設計為可讓用戶能夠與其他人進行通訊，或是分享資料 (以下合稱「通訊服務」) 用戶同意只使用通訊服務來刊登、傳送及接收與特定通訊服務相關之訊息和資料。
        p 若用戶選擇使用通訊服務和其他人分享資料，即表示用戶授與通訊服務中所有使用者使用權，包括使用、修改及重新張貼此等資料或解決方案之權利，且用戶允許本公司及本服務以其所選方式及所選地點，將前述項目提供予此等使用者。
        h2 十一、禁止事項
        p 用戶承諾於使用本服務時不得從事下列所述行為：違反法令、法院判決、裁定或命令或具有法令拘束力的行政措施的行為。進行任何非法、誤導、勸誘或歧視的惡意行為及恐有危害公共秩序或善良風俗的行為。
        p 以上傳或其他方式提供內含受智慧財產權法(舉例而言，包括但不限於著作權法或商標法，或是隱私權或肖像權)保護之影像、相片、軟體或其他資料之檔案，侵害本公司或第三人的著作權、商標權、專利權等智慧財產權、名譽權、隱私權、其他法令或契約規定權利的行為。
        p 以任何侵害他人著作權、商標權、專利權、營業秘密或其他專屬權利之方式，使用透過網站服務所提供之任何資料或資訊(包括影像或相片)的行為。傳送內容有鼓吹仇恨、威脅、煽動暴力、過度暴力的表現或血腥暴力傾向；色情、裸露及性暗示過度露骨的表現；涉及人種、國籍、信仰、性別、社會地位、家世等有關歧視的表現；引誘或助長自殺、自傷行為或濫用藥物的表現；及其他包含反社會情節在內等使人感到不愉快的表現的行為。
        p 偽裝成本公司或第三人的行為或故意散布不實資訊的行為。
        p 搭配進行調查或競賽(如由本公司、本服務或本公司之合作夥伴或客戶透過本網站舉辦之調查或競賽，則不在此限)、從事非法多層次傳銷（如金字塔式傳銷）、以性行為或猥褻行為為目的之行為、以與未曾謀面的異性認識或交往為目的之行為、以騷擾或毀謗中傷其他用戶為目的之行為、其他基於與本服務訂定的使用目的不同之目的使用本服務的行為。
        p 對反社會勢力提供利益或其他協助的行為。
        p 勸誘參與宗教活動或宗教團體的行為。
        p 透過非網站服務所提供之任何方式，取得或試圖取得任何資料或資訊的行為。 干擾本服務的伺服器或網路系統的行為、利用 BOT、作弊工具、其他技術性手段不當操作服務的行為、故意利用本服務漏洞的行為、超出必要限度重複同一問題等對本公司提出不當洽詢或要求的行為、其他妨礙本公司經營本服務或其他用戶使用本服務，並製造干擾的行為。 上傳病毒、其他惡意程式碼或任何可能導致本公司或本服務伺服器或連線至本公司或本服務伺服器之任何網路遭破壞、停用、超過負荷或受到損害，或是可能會影響其他人使用及享用該等網站服務之方式使用本服務的行為。
        p 協助或鼓勵任何違反本聲明或政策的行為支援或助長符合前述任一行為的行為。
        p 違反任何相關法律或規範；以誤導他人為目的建立偽造的使用者身分；使用、下載或以其他方式複製網站服務使用者之任何目錄、其他使用者或使用資訊，或是其中任何部分，或將前述資訊提供給其他個人或機構(不論是否收費)。
        p 其他本公司判斷為不適當的行為。
        h2 十二、例外規定
        p 為了遵從任何相關法律、規定、法律程序或政府的要求，本公司及本服務有權隨時揭露任何資訊或編輯資訊，或是由本公司及本服務自行決定拒絕刊登或移除任何資訊或資料(全部或一部分)。 用戶於任何通訊服務中提供可辨識自己或子女身分之任何個人識別資訊時，請務必多加留意。 由於本公司及本服務並未控制或認可任何通訊服務之內容、訊息或資訊，因此本公司及本服務在此特別聲明，對於通訊服務以及用戶參與通訊服務所導致之任何結果均不負任何責任。 管理員及負責人並非本公司及本服務所授權之發言人，因此其看法並不一定代表本公司及本服務之看法。
        p 上傳至通訊服務之資料在使用、複製或傳播上得受到所公布限制之拘束。若貴用戶下載此等資料，用戶必須負責遵守此等限制。
        h2 十三、用戶責任
        p 用戶應自行承擔責任使用本服務，對於在本服務所從事的所有行為及其結果，皆屬於用戶各人行為，應自行負擔一切責任，本公司就此對用戶及其他第三人不付任何法律責任。
        p 本公司認為用戶有違反本條款情形下使用本服務時，將採取本公司判斷有必要且適當的措施。但此不代表本公司有義務防止或糾正該等違反情形。
        p 起因於用戶使用本服務（包括本公司自第三人處收到原因為該等使用的申訴），致本公司直接或間接蒙受任何損害（包括律師費用的負擔）時，用戶應依照本公司要求立即給予補償。
        h2 十四、本公司的免責
        p 本公司、本服務及其供應商不會就連同網站服務一起依任何用途而公佈之資料及相關圖形內所含之資訊的適用性，提供任何聲明。所有此類資料和相關圖形，以及網站服務，係依「現況」提供，且不含任何類型之瑕疵擔保本公司、本服務及其供應商謹以前面所述，不對此項資訊提供任何瑕疵擔保及條件，包括一切有關適售性、符合特定目的、所有權及未侵權之明示、默示或法定之瑕疵擔保及條件。
        p 網站上所公佈之資料及相關圖形可能含有不正確之技術內容或文字排版有誤。此處所包含的資訊會定期變更。本公司、本服務及/或其供應商得隨時改善及/或變更此處所述之網站服務及/或資料。
        p 本公司、本服務或其供應商對於所發生之任何特殊、間接性或衍生性損害，或因使用權、資料或利潤之損失所導致之任何損害均不負任何責任，不論上述損害是否係出於契約行為、過失或其他侵權行為，亦不論上述損害是否導因於使用或操作網站服務或任何資料、服務或任何網站服務之提供或無法提供。
        p 不論明示或默示，本公司均未保證本服務不具有事實瑕疵或法律瑕疵（包括安全性、可靠性、正確性、完整性、有效性、特定目的的適用性、安全等相關缺陷、錯誤或程式錯誤、權利侵害等）。
        p 因本服務而對用戶所造成的所有損害，包括債務不履行或侵權行為，本公司將不負任何責任。但本公司與用戶間的本服務相關契約（包括本條款）為消費者保護法規定的商品或服務時，則不適用此免責規定。 即使在前述但書所規定情形下，其賠償以發生該等損害當月自用戶收取的使用金額為上限。
        h2 十五、回饋意見：
        p 若用戶提供回饋意見給本公司或本服務，即代表用戶授與本公司或本服務不可撤銷、永久且可轉授權之權利，得基於各種目的，以各種方式免費使用、共用及商業化用戶之回饋意見，同時也免費授權第三方，就該第三方的產品、技術與服務擬使用或結合本公司或本服務產品或服務任何部分所需的回饋意見專利權，這些權利於本條款終止、解除、失效或屆滿時仍繼續有效。 本公司或本服務並無刊登或使用用戶所提供之任何提交資料的義務，且本公司或本服務可自行決定隨時移除任何提交資料。
        p 依據本使用條款之規定，若用戶刊登提交資料，即表示用戶擔保及聲明用戶擁有或以其他方式控制提交資料之所有權利，包括但不限於用戶提供、刊登、上傳、輸入或提出提交資料之所有必要權利。
        p 除上述擔保及聲明外，若用戶刊登內含影像、相片、圖片或其他含有全部或一部分圖形之資訊(以下稱「影像」)之提交資料，即表示用戶對以下事項提出保證及聲明：
        p 用戶為該等影像之著作權擁有人，或是該等影像之著作權擁有人已同意用戶遵照該等影像之使用方式和目的，以及本使用條款及網站服務中之其他許可方式，使用影像或影像中所包含之任何內容；
        p 用戶擁有本使用條款中所規定授權及轉授權之必要權利； 
        p 該等影像中所提及(任何可能之)每個人均已同意依據本使用條款之規定使用該等影像，包括但不限於散布、公開展示及重製該等影像。 若用戶刊登影像，即表示用戶同意依據任何網站服務之本使用條款所允許之方式，授與下列對象在服務中使用用戶之影像：
        p 私人社群之所有會員得使用提供給該私人社群會員使用之所有影像；
        p 一般大眾得使用網站服務中(而非私人社群中)所提供之全部影像；
        p 非專屬性、全球性、免權利金之使用權，以便複製、散布、傳輸、公開展示、公開履行、重製、編輯、翻譯及重新格式化不含附屬物之此等影像，以及將該等權利轉授權給網站服務之任何供應商。
        p 當用戶從網站服務中完全移除該等影像時，前述條款中所授與之影像使用權利即告終止，惟前提為該項使用權利之終止並不影響用戶完全移除相同影像之前就影像相關所授與之任何使用權利。 站服務得包括對網站外部之第三方廠商網站的連結。這些網站並不在本公司或本服務的控制範圍內。本公司或本服務並未花心力檢閱這些網站內容，包括此等內容之有效性、合法性、是否尊重著作權和是否合乎禮儀，且對這些網站的內容並不負任何責任。若 LiveShop 在網站中納入這類對第三方廠商網站之連結，則此等連結係僅為了方便而提供。
        p 本公司或本服務對任何第三方廠商網站、服務或產品相關事宜，不提供背書或任何形式之聲明或保證。
        p 用戶所取得之任何第三方廠商產品或服務，以及用戶及此等第三方供應商之間交換的資料，僅係雙方之間的事務。第三方廠商網站得受第三方條款、條件和隱私權聲明拘束。
        p 本公司或本服務得停用由用戶或他人在網站上張貼之任何第三方廠商網站連結。
        h2 十六、智慧財產權：
        p 本公司、本服務或其供應商保留本網站和網站服務之所有權利、所有權及利益，包括所有著作權、專利權、營業秘密、商標和其他智慧財產權。
        p 所有未經明示授與之權利，均保留為本公司或本服務所有。
        p 本使用條款並未授與或默示任何本公司、本服務或供應商商標、商品名稱或標誌之任何權利。 所有未明示授與之權利均予保留。
        h2 十七、聯絡方式
        p 本公司向用戶聯絡本服務相關事項，以於本公司經營網站內的適當處公布或其他本公司判斷為適當的方式進行。
        p 用戶向本公司聯絡本服務相關事項，請以本公司經營網站內的適當處所設置的問題反應表傳送或依本公司指定方式進行。
        h2 十八、準據法、管轄法院
        p 本條款以中文為準，其準據法為中華民國法律。
        p 用戶與本公司間所發生起因於本服務或與本服務有關連的紛爭，以台北地方法院或台北簡易法院為第一審專屬合意管轄法院。
        p 2020 年 3 月 6 日自本公司及本服務服務條款獨立修訂並保留一切權利。
</template>

<script>
export default {
  components: {

  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="sass">
.privacy-policy-page
  margin: 0px auto
  max-width: 850px
  .title
    padding-bottom: 25px
    padding-top: 15px
    h1
      margin: 10px 5px 10px 5px
    img.logo
      float: right
      height: 50px
  .context
    padding-bottom: 25px
    h2
      margin: 15px 5px 15px 5px
    p
      padding-left: 2em
</style>