<template lang="pug">
aside(:class="{ active: $root.toggleAside, unactive: !$root.toggleAside }")
  header
    router-link.logo-box(:to="{ name: 'Shops' }")
      img.logo(
        :class="{ active: $root.toggleAside }",
        src="../assets/image/logo.png"
      )
      img.logo.mobile(
        :class="{ active: !$root.toggleAside }",
        src="../assets/image/sm_logo.png"
      )
  nav.align-items-stretch.flex-grow.flex-column(
    :class="{ active: $root.toggleAside, unactive: !$root.toggleAside }"
  )
    .my-room-box.text-align-center
      .room-photo.rounded-circle
        img(
          v-if="$root.shop.page.platform_type === 2",
          src="../assets/image/default-icon.jpeg"
        )
        img(
          v-if="$root.shop.page.platform_type === 1",
          v-show="$root.shop.page.image !== ''",
          :src="$root.shop.page.image"
        )
        icon(
          v-if="$root.shop.page.platform_type === 1",
          v-show="$root.shop.page.image === ''",
          :iconName="'customerLightChats'"
        )
      p.room-name {{ $root.shop.page.name }}
      router-link.back-to-room-list(:to="{ name: 'Shops' }")
        icon(:iconName="'lightAngleLeft'")
        span(v-if="$root.shop.page.platform_type === 1") {{ $t('message.aside_change_page') }}
        span(v-if="$root.shop.page.platform_type === 2") {{ $t('message.aside_change_channel') }}
      .floated
        p.room-name {{ $root.shop.page.name }}
        router-link.back-to-room-list(:to="{ name: 'Shops' }")
          icon(:iconName="'lightAngleLeft'")
          span(v-if="$root.shop.page.platform_type === 1") {{ $t('message.aside_change_page') }}
          span(v-if="$root.shop.page.platform_type === 2") {{ $t('message.aside_change_channel') }}
    .nav-list-box.list-group.flex-grow
      router-link.list-group-item(
        :to="{ name: 'Setting' }",
        active-class="active"
      )
        icon(:iconName="'solidCog'")
        span.link-to {{ $t('message.aside_sale_setting') }}
        span.link-to.floated {{ $t('message.aside_sale_setting') }}
      router-link.list-group-item(
        :to="{ name: 'Ship' }",
        active-class="active"
      )
        icon(:iconName="'solidCog'")
        span.link-to {{ $t('message.aside_ship_setting') }}
        span.link-to.floated {{ $t('message.aside_ship_setting') }}
      router-link.list-group-item(
        :to="{ name: 'Orders' }",
        active-class="active"
      )
        icon.icon-bill(:iconName="'solidBill'")
        span.link-to {{ $t('message.aside_order_manager') }}
        span.link-to.floated {{ $t('message.aside_order_manager') }}
      router-link.list-group-item(
        :to="{ name: 'Products' }",
        active-class="active"
      )
        icon.icon-bill(:iconName="'lightShoppingBag'")
        span.link-to {{ $t('message.aside_product_manager') }}
        span.link-to.floated {{ $t('message.aside_product_manager') }}
      a.list-group-item(
        v-if="$root.shop.page.platform_type === 1",
        target="new",
        href="https://docs.google.com/presentation/d/1jymWcM4DhxXNQjRPQv8_eAQMH2iyNpLbDUa9mTTuV5Q/edit?usp=sharing"
      )
        icon(:iconName="'solidStar'")
        span.link-to {{ $t('message.aside_platform_document') }}
        span.link-to.floated {{ $t('message.aside_platform_document') }}
      a.list-group-item(
        v-if="$root.shop.page.platform_type === 2",
        target="new",
        href="https://docs.google.com/presentation/d/1a2yrD41y-vR8b5Nr2LO0mIBqhSDOnCs6E3lI5hXEgKw/edit?usp=sharing"
      )
        icon(:iconName="'solidStar'")
        span.link-to {{ $t('message.aside_platform_document') }}
        span.link-to.floated {{ $t('message.aside_platform_document') }}
      a.list-group-item(target="new", href="https://line.me/R/ti/p/@437uiqsl")
        icon(:iconName="'solidUserTie'")
        span.link-to {{ $t('message.aside_customer_service') }}
        span.link-to.floated {{ $t('message.aside_customer_service') }}
      .flex-grow
      a.list-group-item.toggle-item.d-none.d-sm-none.d-md-flex(
        @click="toggleAside"
      )
        span.link-to {{ $t('message.aside_close_menu') }}
        span.link-to.floated {{ $t('message.aside_close_menu') }}
        icon(
          :iconName="'lightAngleRight'",
          :class="{ left: $root.toggleAside }"
        )
</template>
<script>
import icon from '../common/icons.vue'
export default {
  data () {
    return {
      objRoom: {
        id: '',
        name: '',
        src: '',
        timezone: '',
        channel: {},
        is_invalid: false,
        is_active: false,
        is_payed: false,
        expired_at: '',
        expired_at_timestamp: 0,
        order: {},
        isOwner: true
      }
    }
  },
  components: {
    icon
  },
  created () {
    switch (this.$root.common.getData("toggleAside")) {
      case "true":
        this.$root.toggleAside = true
        break;

      case "false":
        this.$root.toggleAside = false
        break;

      default:
        this.$root.toggleAside = true
        break;
    }
  },
  watch: {
    "$root.toggleAside" (_status) {
      this.$root.common.setData("toggleAside", _status)
    }
  },
  methods: {
    toggleAside: function () {
      this.$root.toggleAside = !this.$root.toggleAside
    }
  }
}
</script>