export default [
  {
    name: '',
    value: ''
  },
  {
    name: 'TWD',
    value: 'TWD'
  },
  {
    name: 'USD',
    value: 'USD'
  },
  {
    name: 'HKD',
    value: 'HKD'
  },
  {
    name: 'GBP',
    value: 'GBP'
  },
  {
    name: 'AUD',
    value: 'AUD'
  },
  {
    name: 'CAD',
    value: 'CAD'
  },
  {
    name: 'SGD',
    value: 'SGD'
  },
  {
    name: 'CHF',
    value: 'CHF'
  },
  {
    name: 'JPY',
    value: 'JPY'
  },
  {
    name: 'ZAR',
    value: 'ZAR'
  },
  {
    name: 'SEK',
    value: 'SEK'
  },
  {
    name: 'NZD',
    value: 'NZD'
  },
  {
    name: 'THB',
    value: 'THB'
  },
  {
    name: 'PHP',
    value: 'PHP'
  },
  {
    name: 'IDR',
    value: 'IDR'
  },
  {
    name: 'EUR',
    value: 'EUR'
  },
  {
    name: 'KRW',
    value: 'KRW'
  },
  {
    name: 'VND',
    value: 'VND'
  },
  {
    name: 'MYR',
    value: 'MYR'
  },
  {
    name: 'CNY',
    value: 'CNY'
  }
]
