<template lang="pug">
.page.setting-page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-row.align-items-stretch
        .section-body
          .container-fluid
            .row
              .col-12.py-3
                //- ATM 帳號資訊
                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 收款方式： ATM 帳號收款
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_bankacc_edit_status",
                            @click="setEditStatus('setting_bankacc_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveBankAcc()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-12.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_bankacc_edit_status",
                            v-model="account.atm_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.atm_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-4
                        .inpu-group
                          | {{ $t('message.setting_bank_account_title') }}
                          input.input.ml-2(
                            v-if="setting_bankacc_edit_status",
                            v-model="account.number"
                          )
                          span(v-else) : {{ account.number }}
                      .col-xs-12.col-md-4
                        .inpu-group
                          | {{ $t('message.setting_bank_name_title') }}
                          input.input.ml-2(
                            v-if="setting_bankacc_edit_status",
                            v-model="account.bank"
                          )
                          span(v-else) : {{ account.bank }}
                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 收款方式： Line Pay 收款
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_linepay_edit_status",
                            @click="setEditStatus('setting_linepay_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveLinePay()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-12.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_linepay_edit_status",
                            v-model="account.linepay_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.linepay_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-4
                        .inpu-group
                          span Line Pay ID
                          input.input.ml-2(
                            v-if="setting_linepay_edit_status",
                            v-model="account.linepay_id"
                          )
                          span(v-else) : {{ account.linepay_id }}
                      .col-xs-12.col-md-4
                        .inpu-group
                          span Line Pay KEY
                          input.input.ml-2(
                            v-if="setting_linepay_edit_status",
                            v-model="account.linepay_key"
                          )
                          span(v-else) : {{ account.linepay_key }}
                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 收款方式： 信用卡收款(綠界)
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_card_edit_status",
                            @click="setEditStatus('setting_card_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveCardPay()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_card_edit_status",
                            v-model="account.ecpay_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.ecpay_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span MerchantID
                          input.input.ml-2(
                            v-if="setting_card_edit_status",
                            v-model="account.ecpay_merchantid"
                          )
                          span(v-else) : {{ account.ecpay_merchantid }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span HashKey
                          input.input.ml-2(
                            v-if="setting_card_edit_status",
                            v-model="account.ecpay_hashkey"
                          )
                          span(v-else) : {{ account.ecpay_hashkey }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span HashIV
                          input.input.ml-2(
                            v-if="setting_card_edit_status",
                            v-model="account.ecpay_hashiv"
                          )
                          span(v-else) : {{ account.ecpay_hashiv }}

                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 收款方式： 取貨時付款
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_cash_edit_status",
                            @click="setEditStatus('setting_cash_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveCashPay()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_cash_edit_status",
                            v-model="account.cash_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.cash_status === "1" ? '已啟用' : '已關閉'  }}

                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 出貨方式： 宅配到府
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_post_tohome_edit_status",
                            @click="setEditStatus('setting_post_tohome_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="savePostToHome()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_post_tohome_edit_status",
                            v-model="account.post_tohome_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.post_tohome_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 出貨運費
                          input.input.ml-2(
                            v-if="setting_post_tohome_edit_status",
                            v-model="account.post_tohome_cost"
                          )
                          span(v-else) : {{ account.post_tohome_cost }}

                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 出貨方式： 到店取貨
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_post_tostore_edit_status",
                            @click="setEditStatus('setting_post_tostore_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="savePostToStore()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_post_tostore_edit_status",
                            v-model="account.post_tostore_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.post_tostore_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 出貨運費
                          input.input.ml-2(
                            v-if="setting_post_tostore_edit_status",
                            v-model="account.post_tostore_cost"
                          )
                          span(v-else) : {{ account.post_tostore_cost }}

                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 出貨方式： 全家超商取貨
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_post_to_FAMIC2C_edit_status",
                            @click="setEditStatus('setting_post_to_FAMIC2C_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="savePostToFAMIC2C()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_post_to_FAMIC2C_edit_status",
                            v-model="account.post_to_FAMIC2C_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.post_to_FAMIC2C_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 出貨運費
                          input.input.ml-2(
                            v-if="setting_post_to_FAMIC2C_edit_status",
                            v-model="account.post_to_FAMIC2C_cost"
                          )
                          span(v-else) : {{ account.post_to_FAMIC2C_cost }}

                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 出貨方式： 7-11超商取貨
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_post_to_UNIMARTC2C_edit_status",
                            @click="setEditStatus('setting_post_to_UNIMARTC2C_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="savePostToUNIMARTC2C()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_post_to_UNIMARTC2C_edit_status",
                            v-model="account.post_to_UNIMARTC2C_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.post_to_UNIMARTC2C_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 出貨運費
                          input.input.ml-2(
                            v-if="setting_post_to_UNIMARTC2C_edit_status",
                            v-model="account.post_to_UNIMARTC2C_cost"
                          )
                          span(v-else) : {{ account.post_to_UNIMARTC2C_cost }}
                .p-2
                .card
                  .card-header
                    .row
                      .col-6
                        span 出貨方式： 萊爾富超商取貨
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_post_to_HILIFEC2C_edit_status",
                            @click="setEditStatus('setting_post_to_HILIFEC2C_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="savePostToHILIFEC2C()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-4.col-md-4
                        .inpu-group
                          span 啟用狀態
                          select.select.form-control.ml-2(
                            v-if="setting_post_to_HILIFEC2C_edit_status",
                            v-model="account.post_to_HILIFEC2C_status"
                          )
                            option(value="0") 關閉
                            option(value="1") 啟用
                          span(v-else) : {{  account.post_to_HILIFEC2C_status === "1" ? '已啟用' : '已關閉'  }}
                      .col-xs-12.col-md-3
                        .inpu-group
                          span 出貨運費
                          input.input.ml-2(
                            v-if="setting_post_to_HILIFEC2C_edit_status",
                            v-model="account.post_to_HILIFEC2C_cost"
                          )
                          span(v-else) : {{ account.post_to_HILIFEC2C_cost }}

                .p-2
                .card
                  .card-header 
                    .row
                      .col-6
                        span {{ $t('message.setting_send_info_title') }}
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_sender_edit_status",
                            @click="setEditStatus('setting_sender_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveSenderInfo()"
                          ) 儲存
                  .card-body.post-ship-info.auto-response
                    .row
                      .col-xs-12.col-md-6
                        .inpu-group
                          | {{ $t('message.setting_send_name_title') }}
                          input.input.ml-2(
                            v-if="setting_sender_edit_status",
                            v-model="sender.name"
                          )
                          span(v-else) : {{ sender.name }}
                        .inpu-group
                          | {{ $t('message.setting_send_zipcode_title') }}
                          input.input.ml-2(
                            v-if="setting_sender_edit_status",
                            v-model="sender.zipcode"
                          )
                          span(v-else) : {{ sender.zipcode }}
                      .col-xs-12.col-md-6
                        .inpu-group
                          | {{ $t('message.setting_send_phone_title') }}
                          input.input.ml-2(
                            v-if="setting_sender_edit_status",
                            tel="tel",
                            v-model="sender.phone",
                            @keydown="$root.changePhone"
                          )
                          span(v-else) : {{ sender.phone }}
                        .inpu-group
                          | {{ $t('message.setting_send_address_title') }}
                          input.input.ml-2(
                            v-if="setting_sender_edit_status",
                            v-model="sender.address"
                          )
                          span(v-else) : {{ sender.address }}
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch } from '../common/ui'

export default {
  components: {
    icon,
    uiSwitch
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      account: {
        bank: "",
        number: "",
        linepay: "",
        linepay_id: "",
        ecpay_merchantid: "",
        ecpay_hashkey: "",
        ecpay_hashiv: "",
        buyer_payment_switch: false,
        buyer_ship_switch: false,
        default_payment: 0,
        default_ship: 0,
        line_login_channel_id: "",
        line_login_channel_secret: "",
      },
      sender: {
        name: "",
        phone: "",
        zipcode: "",
        address: "",
      },
      setting_liffacc_edit_status: false,
      setting_bankacc_edit_status: false,
      setting_linemoney_edit_status: false,
      setting_linepay_edit_status: false,
      setting_card_edit_status: false,
      setting_cash_edit_status: false,
      setting_switch_edit_status: false,
      setting_sender_edit_status: false,
      setting_default_edit_status: false,
      setting_cash_edit_status: false,
      setting_post_tohome_edit_status: false,
      setting_post_tostore_edit_status: false,
      setting_post_to_FAMIC2C_edit_status: false,
      setting_post_to_UNIMARTC2C_edit_status: false,
      setting_post_to_HILIFEC2C_edit_status: false,
      managers: [],
      invite_url: '',
      gen_keyword: '',
      gen_link: '',
      can_bind_insgatram: false,
    }
  },
  computed: {
    boolShopIsClosed () {
      return this.$root.shop.status === 0
    }
  },
  async created () {
    console.log('==> check point 001')
    // 重新拿商店資料
    await this.$root.getShop()
    // 處理畫面流程
    await this.autoRun()
    // 獲取管理員邀請編碼
    await this.getInviteCode()
  },
  watch: {
    gen_keyword (_keyword) {
      this.gen_link = ''
      if (_keyword) {
        this.genKeywordLink(_keyword)
      }
    }
  },
  methods: {
    // 自動化處理
    autoRun () {
      let vm = this
      console.log('==> 處理畫面相關功能')
      // --  處理畫面相關功能
      $(document).ready(function () {
        // 更新畫面資料
        if (vm.$root.shop.sender) {
          if (vm.$root.shop.sender.name) {
            vm.sender = vm.$root.shop.sender
          } else {
            vm.sender = {
              name: "",
              phone: "",
              zipcode: "",
              address: "",
            }
          }
        }

        let account = vm.$root.shop.account
        if (account) {
          if (account.number || account.linepay || account.default_ship || account.default_payment || account.line_login_channel_id) {
            vm.account = account
            vm.account.buyer_payment_switch = (vm.account.buyer_payment_switch) ? true : false
            vm.account.buyer_ship_switch = (vm.account.buyer_ship_switch) ? true : false
          } else {
            vm.account = {
              bank: "",
              number: "",
              linepay: "",
              linepay_id: "",
              ecpay_merchantid: "",
              ecpay_hashkey: "",
              ecpay_hashiv: "",
              buyer_payment_switch: false,
              buyer_ship_switch: false,
              default_payment: 0,
              default_ship: 0
            }
          }
        }
        // 取得管理員清單
        vm.getManagers()
      })
      // --  處理參數判定相關功能
      this.can_bind_insgatram = (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
    },
    // 變更編輯狀態
    setEditStatus (_type = '') {
      switch (_type) {
        case 'setting_liffacc_edit_status':
          this.setting_liffacc_edit_status = !this.setting_liffacc_edit_status
          break;
        case 'setting_bankacc_edit_status':
          this.setting_bankacc_edit_status = !this.setting_bankacc_edit_status
          break;
        case 'setting_linemoney_edit_status':
          this.setting_linemoney_edit_status = !this.setting_linemoney_edit_status
          break;
        case 'setting_linepay_edit_status':
          this.setting_linepay_edit_status = !this.setting_linepay_edit_status
          break;
        case 'setting_card_edit_status':
          this.setting_card_edit_status = !this.setting_card_edit_status
          break;
        case 'setting_switch_edit_status':
          this.setting_switch_edit_status = !this.setting_switch_edit_status
          break;
        case 'setting_sender_edit_status':
          this.setting_sender_edit_status = !this.setting_sender_edit_status
          break;
        case 'setting_default_edit_status':
          this.setting_default_edit_status = !this.setting_default_edit_status
          break;
        case 'setting_cash_edit_status':
          this.setting_cash_edit_status = !this.setting_cash_edit_status
          break;
        case 'setting_post_tohome_edit_status':
          this.setting_post_tohome_edit_status = !this.setting_post_tohome_edit_status
          break;
        case 'setting_post_tostore_edit_status':
          this.setting_post_tostore_edit_status = !this.setting_post_tostore_edit_status
          break;
        case 'setting_post_to_FAMIC2C_edit_status':
          this.setting_post_to_FAMIC2C_edit_status = !this.setting_post_to_FAMIC2C_edit_status
          break;
        case 'setting_post_to_UNIMARTC2C_edit_status':
          this.setting_post_to_UNIMARTC2C_edit_status = !this.setting_post_to_UNIMARTC2C_edit_status
          break;
        case 'setting_post_to_HILIFEC2C_edit_status':
          this.setting_post_to_HILIFEC2C_edit_status = !this.setting_post_to_HILIFEC2C_edit_status
          break;
      }
    },
    // 更新 Liff 參數
    async saveLiffAcc () {
      let saveData = {
        line_login_channel_id: (this.account.line_login_channel_id) ? this.account.line_login_channel_id : '',
        line_login_channel_secret: (this.account.line_login_channel_secret) ? this.account.line_login_channel_secret : '',
      }
      await this.updateSetting('setting_liffacc', saveData)
      // 關閉編輯狀態
      this.setting_liffacc_edit_status = false
    },
    // 更新銀行帳號
    async saveBankAcc () {
      let saveData = {
        atm_status: (this.account.atm_status) ? this.account.atm_status : '0',
        bank: (this.account.bank) ? this.account.bank : '',
        number: (this.account.number) ? this.account.number : '',
      }
      await this.updateSetting('setting_bankacc', saveData)
      // 關閉編輯狀態
      this.setting_bankacc_edit_status = false
    },
    // 更新 Line Pay
    async saveLinePay () {
      let saveData = {
        linepay_status: (this.account.linepay_status) ? this.account.linepay_status : '0',
        linepay_id: (this.account.linepay_id) ? this.account.linepay_id : '',
        linepay_key: (this.account.linepay_key) ? this.account.linepay_key : '',
      }
      await this.updateSetting('setting_linepay', saveData)
      // 關閉編輯狀態
      this.setting_linepay_edit_status = false
    },
    // 更新信用卡刷卡
    async saveCardPay () {
      let saveData = {
        ecpay_status: (this.account.ecpay_status) ? this.account.ecpay_status : '0',
        ecpay_merchantid: (this.account.ecpay_merchantid) ? this.account.ecpay_merchantid : '',
        ecpay_hashkey: (this.account.ecpay_hashkey) ? this.account.ecpay_hashkey : '',
        ecpay_hashiv: (this.account.ecpay_hashiv) ? this.account.ecpay_hashiv : '',
      }
      await this.updateSetting('setting_card', saveData)
      // 關閉編輯狀態
      this.setting_card_edit_status = false
    },
    // 更新貨到付款
    async saveCashPay () {
      let saveData = {
        cash_status: (this.account.cash_status) ? this.account.cash_status : '0',
      }
      await this.updateSetting('setting_cash', saveData)
      // 關閉編輯狀態
      this.setting_cash_edit_status = false
    },
    // 更新宅配到府
    async savePostToHome () {
      let saveData = {
        post_tohome_status: (this.account.post_tohome_status) ? this.account.post_tohome_status : '0',
        post_tohome_cost: (this.account.post_tohome_cost) ? this.account.post_tohome_cost : '0'
      }
      await this.updateSetting('setting_post_tohome', saveData)
      // 關閉編輯狀態
      this.setting_post_tohome_edit_status = false
    },
    // 更新到店取貨
    async savePostToStore () {
      let saveData = {
        post_tostore_status: (this.account.post_tostore_status) ? this.account.post_tostore_status : '0',
        post_tostore_cost: (this.account.post_tostore_cost) ? this.account.post_tostore_cost : '0'
      }
      await this.updateSetting('setting_post_tostore', saveData)
      // 關閉編輯狀態
      this.setting_post_tostore_edit_status = false
    },
    // 更新全家超商取貨
    async savePostToFAMIC2C () {
      let saveData = {
        post_to_FAMIC2C_status: (this.account.post_to_FAMIC2C_status) ? this.account.post_to_FAMIC2C_status : '0',
        post_to_FAMIC2C_cost: (this.account.post_to_FAMIC2C_cost) ? this.account.post_to_FAMIC2C_cost : '0'
      }
      await this.updateSetting('setting_post_to_FAMIC2C', saveData)
      // 關閉編輯狀態
      this.setting_post_to_FAMIC2C_edit_status = false
    },
    // 更新7-11超商取貨
    async savePostToUNIMARTC2C () {
      let saveData = {
        post_to_UNIMARTC2C_status: (this.account.post_to_UNIMARTC2C_status) ? this.account.post_to_UNIMARTC2C_status : '0',
        post_to_UNIMARTC2C_cost: (this.account.post_to_UNIMARTC2C_cost) ? this.account.post_to_UNIMARTC2C_cost : '0'
      }
      await this.updateSetting('setting_post_to_UNIMARTC2C', saveData)
      // 關閉編輯狀態
      this.setting_post_to_UNIMARTC2C_edit_status = false
    },
    // 更新萊爾富超商取貨
    async savePostToHILIFEC2C () {
      let saveData = {
        post_to_HILIFEC2C_status: (this.account.post_to_HILIFEC2C_status) ? this.account.post_to_HILIFEC2C_status : '0',
        post_to_HILIFEC2C_cost: (this.account.post_to_HILIFEC2C_cost) ? this.account.post_to_HILIFEC2C_cost : '0'
      }
      await this.updateSetting('setting_post_to_HILIFEC2C', saveData)
      // 關閉編輯狀態
      this.setting_post_to_HILIFEC2C_edit_status = false
    },
    // 更新用戶自選
    async saveSwitchAction () {
      let saveData = {
        buyer_payment_switch: (this.account.buyer_payment_switch) ? this.account.buyer_payment_switch : false,
        buyer_ship_switch: (this.account.buyer_ship_switch) ? this.account.buyer_ship_switch : false,
      }
      await this.updateSetting('setting_switch', saveData)
      // 關閉編輯狀態
      this.setting_switch_edit_status = false
    },
    // 更新寄件人資訊
    async saveSenderInfo () {
      let saveData = {
        name: (this.sender.name) ? this.sender.name : '',
        phone: (this.sender.phone) ? this.sender.phone : '',
        zipcode: (this.sender.zipcode) ? this.sender.zipcode : '',
        address: (this.sender.address) ? this.sender.address : '',
      }
      await this.updateSetting('setting_sender', saveData)
      // 關閉編輯狀態
      this.setting_sender_edit_status = false
    },
    // 更新預設金物流方式
    async saveDefaultSettingAction () {
      let saveData = {
        default_payment: (this.account.default_payment) ? this.account.default_payment : 0,
        default_ship: (this.account.default_ship) ? this.account.default_ship : 0,
      }
      await this.updateSetting('setting_default', saveData)

      // 關閉編輯狀態
      this.setting_default_edit_status = false
    },
    // 更新相關設定
    async updateSetting (_type = '', _saveData = {}) {
      let shopID = this.$root.shopID
      await this.$http.put('manager/v1/shop/' + shopID + '/sales', {
        type: _type,
        form: _saveData
      }).then(function (_response) {
        if (_response.body.code === '0000') {
          this.$root.shop = _response.body.data
        }
      })
    },
    // 請求解綁商店
    removeShop () {
      if (!confirm(this.$t('message.setting_pages_unlink_confirm').replace('%s', this.$root.shop.page.name))) {
        return
      }

      let shopID = this.$root.shopID
      this.$http.delete('manager/v1/shop/' + shopID + '/remove').then(function (_response) {
        if (_response.body.code === '0000') {
          // 導往商店清單頁
          this.$root.showAlert("商店解綁成功", 'success')
          setTimeout(() => {
            this.$router.push({ name: 'Shops' })
          }, 1500)
        }
      }, function (_response) {
        this.$root.showAlert(_response.body.error, 'error')
      })
    },
    // 請求綁定 IG
    async bindInstagram () {
      let shopID = this.$root.shopID
      await this.$http.post('manager/v1/shop/' + shopID + '/bind/instagram').then(async function (_response) {
        await this.$root.getShop()
        // --  處理參數判定相關功能
        this.can_bind_insgatram = (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
        // -- 粗暴重新渲染
        this.$forceUpdate()
      }, function (_error) {
        alert("綁定時發生問題\r\n" + _error.body.data)
      })
    },
    // 請求解綁 IG
    async unBindInstagram () {
      let shopID = this.$root.shopID
      await this.$http.delete('manager/v1/shop/' + shopID + '/unbind/instagram').then(async function (_response) {
        await this.$root.getShop()
        // --  處理參數判定相關功能
        this.can_bind_insgatram = await (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
        // -- 粗暴重新渲染
        this.$forceUpdate()
      }, function (_error) {
        alert("解除綁定時發生問題\r\n" + _error.body.data)
      })
    },
    // 取得管理員清單
    getManagers () {
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/admins').then(function (_response) {
        if (_response.body.code === '0000') {
          this.managers = _response.body.data
        }
      })
    },
    // 請求一組新的管理員邀請編碼
    getInviteCode () {
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/admin/invite').then(function (_response) {
        if (_response.body.code === '0000') {
          // 請求成功
          this.invite_url = this.$root.web_url + 'manager/invite/' + _response.body.data
        }
      })
    },
    // 移除指定管理員
    delAdmin (_targetID = '') {
      let shopID = this.$root.shopID
      this.$http.delete('manager/v1/shop/' + shopID + '/admin/' + _targetID).then(function (_response) {
        if (_response.body.code === '0000') {
          this.getManagers()
        }
      }, function () {
        // 移除發生錯誤
      })
    },
    // 複製
    copyFunction () {
      $('#staticBackdrop input.link').select();
      document.execCommand("Copy");
    },
    // 設定商店狀態
    setShopStatus () {
      const shopID = this.$root.shopID
      const status = (this.boolShopIsClosed) ? 1 : 2
      this.$http.put('manager/v1/shop/' + shopID + '/status', { status: status }).then((_response) => {
        if (_response.body.code === '0000') {
          this.$root.getShop()
        }
      }
      )
    },
    // 產生 Line 官方帳號鏈結
    genKeywordLink (_keyword) {
      let keyword = encodeURI(_keyword)
      this.gen_link = 'https://line.me/R/oaMessage/' + this.$root.shop.source_id + '/?' + keyword
    },
    // 設定是否開放用戶自選支付方式
    async setShopPaymentSwitchStatus () {
      // 修改元件狀態
      this.account.buyer_payment_switch = await !this.account.buyer_payment_switch
      await this.saveSwitchAction()
    },
    // 設定是否開放用戶自選出貨方式
    async setShopShipSwitchStatus () {
      // 修改元件狀態
      this.account.buyer_ship_switch = await !this.account.buyer_ship_switch
      await this.saveSwitchAction()
    },
  }
}
</script>

<style scoped lang="sass">
.setting-page
  .page-logo
    img
      margin: 0px auto
  .inpu-group
    margin-bottom: 15px
  .show-lg
    .manager-body
      border-top: 1px solid #eee
      .manager-item
        margin-bottom: 15px
        padding: 10px 0
        border-bottom: 1px solid #eee
        .manager-image
          float: left
          img
            width: 100px
            border-radius: 100%
        .manager-name
          float: left
          font-size: 1.3em
          padding-top: 3%
          padding-left: 25px
        .manager-button
          float: right
          padding-top: 3%
  .show-xs
    .manager-item
      margin-bottom: 15px
      padding: 10px 0
      border-bottom: 1px solid #eee
      .manager-image
        img
          border-radius: 100%
  #staticBackdrop
    .modal-body
      input.link
        border: 1px solid #aaa
        padding: 30px 15px
        margin: 15px 0px
        width: 100%
  .shop-page-name
    font-weight: bold
    font-size: 16px
  .shop-plan-info
    color: #555555
  #linkGenerator
    border: 0px
    width: 80%
  .btn-sm
    padding: 0.3rem
    min-width: 50px
</style>