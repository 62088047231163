<template lang="pug">
  div.container(style="overflow-y:scroll; position: relative; height: 100%;")
    //- button 按鈕
    div.sample
      h1 button 按鈕
      button 沒加 class 的空樣式 button
      br
      h2 預設按鈕 .btn-default
      br
      button.button.btn-default .btn-default
      button.button.btn-default(disabled="disabled") :disabled
      button.button.btn-default.disabled .disabled
      br
      br
      h2 預設按鈕 + 右箭頭 .btn-default
      br
      button.button.btn-default-arrow-right
        span .btn-default
        icon(:iconName="'regularArrowRight'")
      br
      br
      h2 預設 - 框線按鈕 .btn-default-outline
      br
      button.button.btn-default-outline .btn-default-outline
      button.button.btn-default-outline(disabled="disabled") :disabled
      button.button.btn-default-outline.disabled .disabled
      br
      br
      h2 預設 - 幽靈按鈕 .btn-default-ghost
      br
      button.button.btn-default-ghost .btn-default-ghost
      button.button.btn-default-ghost(disabled="disabled") :disabled
      button.button.btn-default-ghost.disabled .disabled
      br
      br
      h2 預設 - 幽靈按鈕 + (+)號 .btn-default-ghost
      br
      button.button.btn-default-ghost
       icon(:iconName="'lightPlus'")
       span .btn-default-ghost
      button.button.btn-default-ghost(disabled="disabled")
       icon(:iconName="'lightPlus'")
       span :disabled
      button.button.btn-default-ghost.disabled
       icon(:iconName="'lightPlus'")
       span .disabled
      br
      br
      h2 錯誤按鈕 .btn-error
      br
      button.button.btn-error .btn-error
      button.button.btn-error(disabled="disabled") :disabled
      button.button.btn-error.disabled .disabled
      br
      br
      h2 錯誤 - 框線按鈕 .btn-error-outline
      br
      button.button.btn-error-outline .btn-error-outline
      button.button.btn-error-outline(disabled="disabled") :disabled
      button.button.btn-error-outline.disabled .disabled
      br
      br
      h2 錯誤 - 幽靈按鈕 .btn-error-ghost
      br
      button.button.btn-error-ghost .btn-error-ghost
      button.button.btn-error-ghost(disabled="disabled") :disabled
      button.button.btn-error-ghost.disabled .disabled
      br
      br
      h2 按鈕尺寸
      br
      button.button.btn-default.d-block .d-block
      button.button.btn-default.d-block .d-block
      br
      button.button.btn-default.btn-sm .btn-sm
      button.button.btn-default.btn-lg .btn-lg
      br
      br
      h2 icon 按鈕 - 實心
      br
      button.btn-icon
        icon(:iconName="'lightEdit'")
      button.btn-icon(disabled)
        icon(:iconName="'lightEdit'")
      button.btn-icon
        icon(:iconName="'iconLiveShopView'")
      button.btn-icon(disabled)
        icon(:iconName="'iconLiveShopView'")
      br
      br
    //-
    //-
    //-
    //- icon
    div.sample
      h1 icon
      br
      div.text-default-dark
        h2 Light
        icon(:iconName="'lightTimes'")
        span lightTimes
        br
        icon(:iconName="'lightAngleLeft'")
        span lightAngleLeft
        br
        icon(:iconName="'lightAngleRight'")
        span lightAngleRight
        br
        icon(:iconName="'lightAngleUp'")
        span lightAngleUp
        br
        icon(:iconName="'lightAngleDown'")
        span lightAngleDown
        br
        icon(:iconName="'lightAngleDoubleLeft'")
        span lightAngleDoubleLeft
        br
        icon(:iconName="'lightAngleDoubleRight'")
        span lightAngleDoubleRight
        br
        icon(:iconName="'lightSearch'")
        span lightSearch
        br
        icon(:iconName="'lightClock'")
        span lightClock
        br
        icon(:iconName="'lightEllipsisH'")
        span lightEllipsisH
        br
        icon(:iconName="'lightCog'")
        span lightCog
        br
        icon(:iconName="'lightTrashAlt'")
        span lightTrashAlt
        br
        icon(:iconName="'lightQuestionCircle'")
        span lightQuestionCircle
        br
        icon(:iconName="'lightCommentAltEdit'")
        span lightCommentAltEdit
        br
        icon(:iconName="'lightCommentAltLines'")
        span lightCommentAltLines
        br
        icon(:iconName="'lightCommentAlt'")
        span lightCommentAlt
        br
        icon(:iconName="'lightCommentDot'")
        span lightCommentAlt
        br
        icon(:iconName="'lightTag'")
        span lightTag
        br
        icon(:iconName="'lightStar'")
        span lightStar
        br
        icon(:iconName="'lightEdit'")
        span lightEdit
        br
        icon(:iconName="'lightCheck'")
        span lightCheck
        br
        icon(:iconName="'lightImage'")
        span lightImage
        br
        icon(:iconName="'lightShoppingBag'")
        span lightShoppingBag
        br
        icon(:iconName="'lightVolumeUp'")
        span lightVolumeUp
        br
        icon(:iconName="'lightFileAlt'")
        span lightFileAlt
        br
        icon(:iconName="'lightFilm'")
        span lightFilm
        br
        icon(:iconName="'lightSmile'")
        span lightSmile
        br
        icon(:iconName="'lightExclamationCircle'")
        span lightExclamationCircle
        br
        icon(:iconName="'lightUserTie'")
        span lightUserTie
        br
        icon(:iconName="'lightUserAlt'")
        span lightUserAlt
        br
        icon(:iconName="'lightUserTimes'")
        span lightUserTimes
        br
        icon(:iconName="'lightUserAltSlash'")
        span lightUserAltSlash
        br
        icon(:iconName="'lightClipboardList'")
        span lightClipboardList
        br
        icon(:iconName="'lightRobot'")
        span lightRobot
        br
        icon(:iconName="'lightBars'")
        span lightBars
        br
        icon(:iconName="'lightInboxIn'")
        span lightInboxIn
        br
        icon(:iconName="'lightInboxOut'")
        span lightInboxOut
        br
        icon(:iconName="'lightInbox'")
        span lightInbox
        br
        icon.icon(:iconName="'lightPlusCircle'")
        span lightPlusCircle
        br
        icon.icon(:iconName="'lightEnvelope'")
        span lightEnvelope
        br
        icon.icon(:iconName="'lightEnvelopeOpen'")
        span lightEnvelopeOpen
        br
        icon.icon(:iconName="'lightCopy'")
        span lightCopy
        br
        br
        h2 Regular
        br
        icon(:iconName="'regularSpinner'")
        span regularSpinner
        br
        icon(:iconName="'regularSpinnerThird'")
        span regularSpinnerThird
        br
        br
        br
        h2 Solid
        icon(:iconName="'solidCog'")
        span solidCog
        br
        icon(:iconName="'solidCaretUp'")
        span solidCaretUp
        br
        icon(:iconName="'solidCaretDown'")
        span solidCaretDown
        br
        icon(:iconName="'solidUserAlt'")
        span solidUserAlt
        br
        icon(:iconName="'solidTag'")
        span solidTag
        br
        icon(:iconName="'solidComment'")
        span solidComment
        br
        icon(:iconName="'solidStar'")
        span solidStar
        br
        icon(:iconName="'solidUserTie'")
        span solidUserTie
        br
        icon(:iconName="'solidPlayCircle'")
        span solidPlayCircle
        br
        icon(:iconName="'solidCloudDownloadAlt'")
        span solidCloudDownloadAlt
        br
        br
        h2 品牌 brands
        icon.brands-facebook-icon(:iconName="'brandsFacebook'")
        span brandsFacebook
        br
        icon.brands-line-icon(:iconName="'brandsLine'")
        span brandsLine
        br
        p line和fb的icon記得要帶上class，顏色才會寫上去
        br
        icon.brands-facebook-icon(:iconName="'brandsChromeColored'")
        span brandsChromeColored
        br
        icon(:iconName="'brandsFacebookMessenger'")
        span brandsFacebookMessenger
        br
        br
        h2 自己製作的icon customer
        icon(:iconName="'customerLightRobotSolidEye'")
        span customerLightRobotSolidEye
        br
        icon(:iconName="'customerLightUserTieSolidEye'")
        span customerLightUserTieSolidEye
        br
        icon(:iconName="'customerLightReportSolidEye'")
        span customerLightReportSolidEye
        br
        icon(:iconName="'customerLightPostSolidEye'")
        span customerLightPostSolidEye
        br
        icon(:iconName="'customerLightChats'")
        span customerLightChats
        br
        icon(:iconName="'customerLightUserTagChat'")
        span customerLightUserTagChat
        br
        icon(:iconName="'customerLightPostMessage'")
        span customerLightPostMessage
        br
        icon(:iconName="'customerLightFeedReply'")
        span customerLightFeedReply
        br
        icon(:iconName="'customerChatbot'")
        span customerChatbot
        br
        icon(:iconName="'loading'")
        span loading
        br
    //-
    //-
    //-
    //- a超連結
    div.sample
      h2 a超連結
      a(href="aaa") 我是 連結
      br
      br
      h2 a 底線 超連結
      a.link-underlined(href="javascript:void(0);") 我是 底線連結
    //-
    //-
    //-
    //- switch
    div.sample
      h1 switch
      p :boolActive 用來啟動
      p :boolDisabled 用來關閉
      p @click 是點擊動作的接口
      div
        uiSwitch(:boolActive="boolSwitchActive", @click="clickSwitch()")
      br
      hr
      br
      p.strong disabled
      div
        uiSwitch(:boolDisabled="true", :boolActive="true", @click="clickSwitch()")
        uiSwitch(:boolDisabled="true", :boolActive="false", @click="clickSwitch()")
      br
      hr
      br
      p 另外有 objSwitchStyle 的樣式接口
      p 只要改變 font-size ，就可以改變尺寸
      div
        uiSwitch(:boolActive="!boolSwitchActive", @click="clickSwitch()", :objStyle="objSwitchStyle")
    //-
    //-
    //-
</template>
<script>
  import icon from '../common/icons.vue'
  import { uiSwitch } from '../common/ui/'

  export default {
    components: { 
      icon,
      uiSwitch
    },
    data () {
      return {
        // switch
        boolSwitchActive: true,
        objSwitchStyle: {
          fontSize: '2em'
        }
      }
    },
    methods: {
      // -------- switch 小開冊
      clickSwitch: function () {
        this.boolSwitchActive = !this.boolSwitchActive
      }
    }
  }
</script>
<style lang="sass">
  @import "@/assets/sass/require/common.sass"
  div.sample
    border: 1px solid #111
    margin-top: 2em
    margin-bottom: 2em
    padding: 2em
    background: #fff
    position: relative
    > h1
      margin-bottom: 2em
    > p.strong
      margin-top: 1.6em
      font-size: 1.2em
      color: $color-sysc
    > div
      margin-top: 2em
  div.btn-sample
    display: inline-block
    div
</style>
