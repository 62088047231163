<template lang="pug">
  //- dropdown 自製下拉選單
  .dropdown(:class="{active: boolVisible}", :style="objDropdownStyle")
    .dropdown-toggle(:style="objToggleStyle", @click="toggle")
      slot(name="toggle")
    .dropdown-menu(ref="menu", :class="{fluid: boolFluid, right: boolRight, up: boolUp, 'text-right': boolTextRight}", :style="objMenuStyle")
      //- 內容插在這裡
      slot(name="menu")
    //- 背景
    .dropdown-background(:style="objBackgroundStyle", @click="hide")
</template>
<script>
export default {
  name: 'dropdown',
  props: {
    // 是否 顯示
    boolVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    // 是否 繼承外部父元素的寬度
    boolFluid: {
      type: Boolean,
      required: false,
      default: false
    },
    // 是否 靠右對齊
    boolRight: {
      type: Boolean,
      required: false,
      default: false
    },
    // 是否 靠上對齊
    boolUp: {
      type: Boolean,
      required: false,
      default: false
    },
    // 是否 文字靠右對齊
    boolTextRight: {
      type: Boolean,
      required: false,
      default: false
    },
    // dropdown 本身 的 class 與 style
    objDropdownStyle: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    // dropdown-toggle 的 class 與 style
    objToggleStyle: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    // dropdown-menu 的 class 與 style
    objMenuStyle: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    },
    // dropdown-background 的 class 與 style
    objBackgroundStyle: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  watch: {
  },
  components: {
  },
  mounted: function () {
    // 設定 scrollBar
    this.scroll()
  },
  destroyed: function () {
  },
  methods: {
    toggle: function () {
      this.$emit('toggle')
    },
    hide: function () {
      this.$emit('hide')
    },
    // 設定 scrollBar
    scroll: function () {
    }
  }
}
</script>
<style lang="sass" scoped>
  @import "@/assets/sass/require/common.sass"
  // 抵銷 Boostrap4 的設定
  .dropdown-toggle::after
    display: none
  .dropdown-menu
    display: block
</style>
