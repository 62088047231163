<template lang="pug">
  .base-header.font-size-xs
    .d-lg-none
      .header-brand
        .brand-name {{ $t('message.congratulations_page_title') }}
      .clearall
    .d-none.d-md-block.font-size-lg
      .header-brand
        .brand-logo
          img(src="../assets/image/logo.png")
        //- .brand-name
          | LiveShop
        .clearall
      //- .header-persion
        .persion-image
          img(src="https://storage.googleapis.com/funbike-uat/member/1001EMalq0X3o6zdn4xIornE7NJpV7jb8Owm-810439752", alt="alt")
        .persion-name 強大的一切
        .persion-more
      .clearall
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped lang="sass">
.base-header
  margin-left: -15px
  margin-right: -15px
  background: #aaa
  padding: 10px 15px
  .font-size-xs
    .brand-name
      font-size: 1em
    .font-size-lg
      .brand-name
        font-size: 2em
    .header-brand
      float: left
      .brand-logo
        float: left
        img
          // background: #000
          // width: 70px
          height: 40px
      .brand-name
        float: left
        padding-left: 5px
        font-weight: bold
    .header-search
      float: left
    .header-persion
      float: right
      .persion-image
        float: left
        img
          height: 40px
          border-radius: 100%
      .persion-name
        float: left
        font-size: 1.2em
        padding-top: 8px
        padding-left: 10px
      .persion-more

        float: left
</style>
