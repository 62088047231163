import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import MainPage from '@/components/Main'
import SignIn from '@/components/SignIn'
import Connect from '@/components/Connect'
import Currency from '@/components/Currency'
import ShopExpire from '@/components/ShopExpire'
import Congratulations from '@/components/Congratulations'
import Order from '@/components/Order'
import Orders from '@/components/Orders'
import Products from '@/components/Products'
import EditProduct from '@/components/EditProduct'
import InboxOrder from '@/components/InboxOrder'
import OrderBuilded from '@/components/OrderBuilded'
import Shops from '@/components/ShopList'
import Setting from '@/components/Setting'
import Ship from '@/components/Ship'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import TermsService from '@/components/TermsService'
import ManagerInvite from '@/components/ManagerInvite'
import OrderConfrim from '@/components/OrderConfirm'
import ReturnMain from '@/components/ReturnMain'
import ReportOrder from '@/components/ReportOrder'
import GoToOrder from '@/components/GoToOrder'
import LiffAddToCart from '@/components/Liff'
import LinkAddToCart from '@/components/Liff'
import StyleDemo from '@/components/StyleDemo'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    // ########################
    // ###  預設頁面
    // ########################
    {
      path: '/main',
      name: 'MainPage',
      component: MainPage
    },
    {
      path: '/main/:platform',
      name: 'MainPage',
      component: MainPage
    },
    // ########################
    // ###  登入頁面
    // ########################
    {
      path: '/signin',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/signin/:platform',
      name: 'SignIn',
      component: SignIn
    },
    // ########################
    // ###  連接粉絲團
    // ########################
    {
      path: '/connect',
      name: 'Connect',
      component: Connect
    },
    // ########################
    // ###  商店過期
    // ########################
    {
      path: '/:shop/expire',
      name: 'ShopExpire',
      component: ShopExpire
    },
    // ########################
    // ###  設定幣值
    // ########################
    {
      path: '/:shop/currency',
      name: 'Currency',
      component: Currency
    },
    // ########################
    // ###  設定完成
    // ########################
    {
      path: '/:shop/congratulations',
      name: 'Congratulations',
      component: Congratulations
    },
    // ########################
    // ###  粉絲團清單
    // ########################
    {
      path: '/shops',
      name: 'Shops',
      component: Shops
    },
    {
      path: '/shops/:platform',
      name: 'Shops',
      component: Shops
    },
    // ########################
    // ###  商品管理相關頁
    // ########################
    {
      path: '/:shop/products',
      name: 'Products',
      component: Products
    },
    {
      path: '/:shop/product/:productId',
      name: 'EditProduct',
      component: EditProduct
    },
    // ########################
    // ###  訂單首頁
    // ########################
    {
      path: '/:shop/orders',
      name: 'Orders',
      component: Orders
    },
    {
      path: '/:shop/orders/:operating',
      name: 'OrdersOperating',
      component: Orders
    },
    // ########################
    // ###  訂單內頁
    // ########################
    {
      path: '/:shop/order/:id',
      name: 'Order',
      component: Order
    },
    // ########################
    // ###  銷售設定
    // ########################
    {
      path: '/:shop/setting',
      name: 'Setting',
      component: Setting
    },
    // ########################
    // ###  金物流設定
    // ########################
    {
      path: '/:shop/ship',
      name: 'Ship',
      component: Ship
    },
    // ########################
    // ###  邀請管理員
    // ########################
    {
      path: '/manager/invite/:code',
      name: 'ManagerInvite',
      component: ManagerInvite
    },
    // ################################################
    // ### 以下為 Extension 專用各種畫面
    // ################################################
    // 000000000000000000000000
    // ########################
    // ###  訂單建立
    // ########################
    {
      path: '/order/member/:memberHashID/current',
      name: 'InboxOrder',
      component: InboxOrder
    },
    {
      path: '/order/comment/:commentID/current',
      name: 'InboxOrder',
      component: InboxOrder
    },
    {
      path: '/order/builded/:number',
      name: 'OrderBuilded',
      component: OrderBuilded
    },
    {
      path: '/order/goto/:number',
      name: 'OrderBuilded',
      component: OrderBuilded
    },
    // ########################
    // ###  訂單確認
    // ########################
    {
      path: '/order/:number',
      name: 'OrderConfrim',
      component: OrderConfrim
    },
    // ########################
    // ###  隱私權條款
    // ########################
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/terms-service',
      name: 'TermsService',
      component: TermsService
    },
    // ########################
    // ###  匯出訂單
    // ########################
    {
      path: '/report-order/:shop/:number',
      name: 'ReportOrder',
      component: ReportOrder
    },
    // ########################
    // ###  導轉頁面
    // ########################
    {
      path: '/goto/:number',
      name: 'GoToOrder',
      component: GoToOrder
    },
    // ########################
    // ###  Liff 相關應用
    // ########################
    {
      path: '/liff',
      name: 'Liff-AddToCart',
      component: LiffAddToCart
    },
    {
      path: '/link',
      name: 'Link-AddToCart',
      component: LinkAddToCart
    },
    // ########################
    // ###  compoent 與 色彩樣式 demo 頁面
    // ########################
    {
      path: '/style',
      name: 'style',
      component: StyleDemo
    },
    {
      path: '*',
      name: 'ReturnMain',
      component: ReturnMain
    }
  ]
})

router.afterEach((to) => { // to, from
  let pagesNeedToStretchFullHeight = store.state.ui.arrNeedSidePages
  let boolLayoutStretchFullHeight = pagesNeedToStretchFullHeight.includes(to.name)
  setLayoutStretchFullHeight(boolLayoutStretchFullHeight)
})

function setLayoutStretchFullHeight (bool) {
  if (!window) return
  let html = document.querySelector('html')
  if (bool) {
    html.classList.add('stretchFullheight')
  } else {
    html.classList.remove('stretchFullheight')
  }
}

export default router