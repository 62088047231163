<template lang="pug">
header.page-header(
  :class="{ active: $root.toggleAside, unactive: !$root.toggleAside }"
)
  //- 標題
  .col.nav-box.d-flex.align-items-center
    a#main-aside-toggle.d-inline-flex.d-md-none(
      @click.prevent="toggleAside",
      aria-label="Toggle main aside"
    )
      icon(:iconName="'lightBars'")
    h1.col-auto {{ strPageTitle }}
  //- 使用者資訊
  .col-auto.user-info-box
    .d-flex.align-items-center
      img.user-photo.rounded-circle(:src="objUser.src")
      a.user-name(
        :class="{ dropAcitve: boolDropUserInfo }",
        @click="toggleDropUserInfo"
      )
        span {{ objUser.name }}
        icon(:iconName="'solidCaretDown'")
    //- 使用者資訊的下拉選單
    dropdown-user-info(
      :boolVisible="boolDropUserInfo",
      @hide="hideDropUserInfo"
    )
</template>
<script>
import { mapState } from 'vuex'
import icon from '../common/icons.vue'
import { dropdownUserInfo } from '../common/dropdown'
export default {
  data () {
    return {
      // 關鍵字
      strSearch: '',
      // 是否允許連續搜尋
      boolCanSearch: false,
      // 是否顯示 user Info 的下拉選單
      boolDropUserInfo: false
    }
  },
  components: {
    icon,
    dropdownUserInfo
    // liveChatLogo
  },
  computed: {
    ...mapState('user', {
      objUser: 'objUser'
    }),
    strPageTitle () {
      if (this.$route.name === 'Setting') {
        return this.$t('message.header_page_title_setting')
      } else if (this.$route.name === 'Ship') {
        return this.$t('message.header_page_title_ship')
      } else if (this.$route.name === 'Order') {
        return this.$t('message.header_page_title_order')
      } else if (this.$route.name === 'Orders') {
        return this.$t('message.header_page_title_orders')
      } else if (this.$route.name === 'OrdersOperating') {
        return this.$t('message.header_page_title_orders_operating')
      } else if (this.$route.name === 'Congratulations') {
        return this.$t('message.congratulations_page_title')
      } else if (this.$route.name === 'Products') {
        return this.$t('message.aside_product_manager')
      } else if (this.$route.name === 'EditProduct') {
        return this.$t('message.aside_product_manager')
      } else {
        return ''
      }
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    // 刪除搜尋關鍵字
    clear: function () {
      this.strSearch = ''
      this.clearSearch()
    },
    toggleAside: function () {
      this.$root.toggleAside = !this.$root.toggleAside
    },
    // 顯示/隱藏 使用者資訊的下拉選單
    toggleDropUserInfo () {
      this.boolDropUserInfo = !this.boolDropUserInfo
    },
    hideDropUserInfo () {
      this.boolDropUserInfo = false
    },
    //
    // -------- EMIT
    //
    // 向父層取消搜尋狀態
    clearSearch: function () {
      this.$emit('clear')
    },
    //
    // 按下 放大鏡，進行搜尋訊息
    searchRoom: function () {
      if (this.strSearch !== '') {
        this.boolCanSearch = true
      } else {
        this.boolCanSearch = false
        this.clearSearch()
      }
      // 先證驗是否可以搜尋
      if (!this.boolCanSearch) {
        return
      }
      let value = this.strSearch
      this.$emit('search', value)
    },
    // page-header 導航點擊傳到父層setting-index，控制 setting 進行滾動事件
    scrollNav: function (nav) {
      this.$emit('scroll', nav)
    }
    // -------- API
  }
}
</script>
<style lang="sass" scoped>
@import "@/assets/sass/require/common.sass"
button.button.btn-unstyle.right.btn-clear
  transition-delay: .2s
h1
  font-size: 1.2rem
  color: $color-deepGray
</style>
