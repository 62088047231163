<template lang="pug">
.page(:class="{ active: boolActive, unactive: !boolActive }")
  .congratulations-page.container-fluid
    .p-3
    .row
      .col-2
      .col-8.text-center
        //- 恭喜頁標題
        //- .congratulations-title
          .font-size-lg.d-none.d-md-block {{ $t('message.congratulations_page_title') }}
        //- Banner 圖區塊
        .congratulations-banner
          img(src="../assets/image/congratulations.png")
        //- 間隔區塊
        .p-2
        //- 其他資訊標題
        .congratulations-subtitle
          .font-size-xs.d-lg-none
            span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_title') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_title_line') }}
          .font-size-lg.d-none.d-md-block 
            span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_title') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_title_line') }}
        //- 間隔區塊
        .p-1
        //- 其他資訊內容
        .congratulations-subinfo
          .font-size-xs.d-lg-none
            .p-2
            .congratulations-info
              span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_context_01') }}
              span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_context_line_01') }}
            span.font-bold {{ $root.shop.name }}
            span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_context_03') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_context_line_03') }}
            .p-5
          .font-size-lg.d-none.d-md-block
            span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_context_01') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_context_line_01') }}
            span.font-bold {{ $root.shop.name }}
            span(v-if="$root.user.platform === 1") {{ $t('message.congratulations_other_context_03') }}
            span(v-if="$root.user.platform === 2") {{ $t('message.congratulations_other_context_line_03') }}
        //- 間隔區塊
        .p-3
        //- 完成按鈕
        .congratulations-button
          button.btn-default.btn-lg(type="button", @click="goHomePage()") {{ $t('message.congratulations_ok_button') }}
      .col-2
</template>

<script>
import HeaderBase from './HeaderBase'
export default {
  components: {
    HeaderBase
  },
  data () {
    return {}
  },
  methods: {
    // 前往首頁
    goHomePage () {
      // 找到目前所使用的商店對象
      let shopID = this.$root.shopID
      // 前往訂單列表頁
      this.$router.push({ name: 'Orders', params: { shop: shopID } })
    }
  }
}
</script>

<style scoped lang="sass">
.congratulations-page
  .congratulations-banner
    img
      margin: 0px auto
      max-width: 100%
      max-height: 350px
      margin-bottom: 25px
    .congratulations-title
      margin: 3% 0px
      font-weight: bold
      .font-size-lg
        font-size: 3em
    .congratulations-subtitle
      font-weight: bold
      margin: 1% 0px
      .font-size-lg
        font-size: 1.5em
      .font-size-xs
        font-size: 1em
    .congratulations-subinfo
      text-align: center
      margin: 0px auto
      .font-size-lg
        font-size: 1.5em
        padding: 0% 10%
      .font-size-xs
        font-size: 1em
</style>
