<template lang="pug">
.page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-column.align-items-stretch.flex-nowrap
        .section-body
          .container-fluid
            .p-2
            .text-right
              .d-none.d-md-block
                button.btn.btn-default-outline(@click="goProductPage('add')") {{ $t('message.product_create_button') }}
              .d-lg-none
                button.d-block.btn.btn-default-outline(
                  @click="goProductPage('add')"
                ) {{ $t('message.product_create_button') }}

      section.section.flex-row.align-items-stretch
        .section-body
          .container-fluid
            .row
              .col-12
                .p-2
                .card
                  .card-body.auto-response
                    nav.flex-grow
                      .input-group.search-room-bar
                        //- 搜尋輸入框
                        input.input.search-input(
                          :placeholder="$t('message.products_please_input_search_key')",
                          v-model="strSearch",
                          @keypress.enter.prevent.stop="getShopProducts(1)"
                        )
                        //- 清除內容
                        button.button.btn-unstyle.right.btn-clear(
                          :class="{ active: strSearch !== '' }",
                          @click="clearSearch()"
                        )
                          icon(:iconName="'lightTimes'")
                        //- 執行搜尋 按鈕
                        button.btn-unstyle.btn-search(
                          :disabled="strSearch === ''",
                          @click="getShopProducts(1)"
                        )
                          icon(:iconName="'lightSearch'")
                //- 電腦版專用
                .p-2
                .card.d-none.d-md-block.order-list
                  .card-body.orders-lg
                    .p-2
                    .table-responsive
                      table.table
                        thead
                          //- th.text-left {{ $t('message.products_order_table_title_01') }}
                          th.text-left {{ $t('message.products_order_table_title_02') }}
                          th.text-left {{ $t('message.products_order_table_title_03') }}
                          th.text-left {{ $t('message.products_order_table_title_09') }}
                          th.text-left {{ $t('message.products_order_table_title_04') }}
                          th.text-left(
                            v-if="$root.user.platform === 2 && $root.shop.is_liff && $root.shop.account.line_login_channel_id"
                          ) {{ $t('message.products_order_table_title_05') }}
                          th.text-left {{ $t('message.products_order_table_title_06') }}
                          th.text-left {{ $t('message.products_order_table_title_08') }}
                        tbody
                          tr(v-for="(product, index) in products")
                            //- td
                              .select-order(@click="setSelectOrder(order.number)",v-if="operating ==='process' || operating === 'waitship'")
                                uiCheckbox(:boolActive="orderIsSelect(order.number)",:objStyle="{fontSize: '1em'}")
                              .text-center(v-else) -
                            td {{ index + 1 }}
                            td {{ product.name }}
                            td 
                              span(v-if="product.sku") {{ product.sku }}
                              span(v-else) -
                            td {{ product.price }}
                            td(
                              v-if="$root.user.platform === 2 && $root.shop.is_liff && $root.shop.account.line_login_channel_id"
                            ) {{ product.link }}
                            td
                              span.badge.bg-success(v-if="product.status") 已開放
                              span.badge.bg-secondary(v-else) 已關閉
                            td
                              .order-edit(
                                @click="goProductPage(product.hash_id)"
                              )
                                button.btn-icon
                                  icon(:iconName="'lightEdit'")
                          tr.text-center(v-if="products.length === 0")
                            td(colspan="7") {{ $t('message.common_now_no_data') }}
                    .pageings(v-if="last_page > 1")
                      nav(aria-label="Page navigation example")
                        ul.pagination
                          li.page-item(
                            v-if="current_page > 1",
                            @click="getShopProducts(current_page - 1)"
                          )
                            a.page-link(href="#") {{ $t('message.common_last_page') }}
                          li.page-item(
                            v-for="page in last_page",
                            @click="getShopProducts(page)"
                          )
                            a.page-link(href="#") {{ page }}
                          li.page-item(
                            v-if="current_page < last_page",
                            @click="getShopProducts(current_page + 1)"
                          )
                            a.page-link(href="#") {{ $t('message.common_next_page') }}
</template>

<script>
import HeaderBase from './HeaderBase'
import Menu from './Menu'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    HeaderBase,
    Menu,
    icon,
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      // 搜索文字
      strSearch: "",
      // 商品清單
      products: [],
      // 分頁相關功能
      last_page: 0,
      current_page: 1,
    }
  },
  created () {
    this.getShopProducts()
  },
  watch: {
  },
  methods: {
    // 獲取商品清單
    getShopProducts (_page = false) {

      let goPage = (_page) ? _page : this.current_page

      this.$http.get('manager/v1/shop/' + this.$root.shopID + '/products', {
        params: {
          page: goPage,
          search: this.strSearch
        }
      }).then(function (_response) {
        if (_response.body.code !== '0000') {
          return
        }

        // 獲得內容
        let response = _response.body.data

        // 商品清單
        this.products = response.products

        // 處理頁面參數
        this.last_page = response.pages
        this.current_page = response.page
      })
    },
    // 前往商品內頁
    goProductPage (_productId = '') {
      let shopID = this.$root.shopID
      this.$router.push({ name: 'EditProduct', params: { shop: shopID, productId: _productId } })
    },
    // 清除搜索文字
    clearSearch () {
      this.strSearch = ''
    }
  }
}
</script>

<style scoped lang="sass">
.section-body
  width: 100%
  overflow-y: scroll
  .search-room-bar
    width: 100%
    .search-input
      padding-left: 3rem
      padding-right: 3rem
  .order-operating
    width: 100%
    .order-operating
      .select-group
        width: 100%
      .operating-option
        max-width: 100%
        width: 100%
    ul.nav
      li.nav-item
        a.nav-link.active
          color: #00F
  .operating-lg
    .operating-01
      float: right
      .select-group
        width: 15rem
    .operating-02
      margin-left: 15px
      float: right
  .orders-list
    margin-bottom: 15px
  .nav-item
    cursor: pointer
  .orders-xs
    .order-number
      // color: #356CFA
    td
      border: 0px
      padding: 2px
    .order-payment
      border-radius: 20px
      background: #ccc
      padding: 3px 10px
      font-size: .8em
      float: right
  .orders-lg
    .table
      min-width: 800px
  .no-data
    img
      margin: 0px auto
      max-width: 100%
      max-height: 350px
      margin-bottom: 25px
  .select-all-order
    .select.operating-option
      margin-left: 15px
      min-width: 150px
  .order-list
    .pageings
      nav
        ul
          margin: 0px auto
  #staticBackdrop
    .modal-body
      .text
        text-align: left
      input.link
        border: 1px solid #aaa
        padding: 10px 15px
        margin: 15px 0px
        width: 100%
  .text-riget
    text-align: right
  span.badge.bg-success,span.badge.bg-secondary
    color: #fff
    padding-top: 3px
    padding-bottom: 3px
</style>