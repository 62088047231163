const state = {
  objUser: {
    id: "",
    name: "",
    src: "",
    lang_type: 1
  }
}

const mutations = {
  setAppUser (state, _user) {
    state.objUser = _user
  },
}

const actions = {
  toggleAside ({ commit }, _user) {
    // console.log(_user)
    commit('setAppUser', _user)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
