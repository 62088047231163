import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import VueQrcode from '@xkeshi/vue-qrcode'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
import VueLocalStorage from 'vue-localstorage'
import VueToast from 'vue-toast-notification';
import VueCarousel from 'vue-carousel';
import $ from 'jquery'

// SCSS - Bootstrap grid
// import '@/assets/bootstrap/scss/bootstrap-grid.scss'
import 'vue-toast-notification/dist/theme-sugar.css';
import '@/assets/bootstrap/scss/bootstrap.scss'
import '@/assets/js/bootstrap/bootstrap.js'

// SASS
import '@/assets/sass/main.sass'

// Main Css
import '@/assets/sass/default.sass'

// Library
import Common from './library/Common'

Vue.config.productionTip = false

// use plugin
Vue.use(VueResource)
Vue.use(VueI18n)
Vue.use(VueCookies)
Vue.use(VueToast)
Vue.use(VueCarousel);
Vue.use(VueLocalStorage)
Vue.component(VueQrcode.name, VueQrcode)

// 處理多語系資料
import zhCn from './languages/zhCn.json'
import zhTW from './languages/zhTw.json'
import en from './languages/en.json'
const language = {
  en: { message: en },
  zh: { message: zhTW },
  cn: { message: zhCn }
}

// 處理多語系功能
const i18n = new VueI18n({
  // 設置預設地區
  locale: 'zh',
  // 設置多語系資料
  messages: language
})

// ================
Vue.http.options.root = process.env.VUE_APP_API_URL
Vue.http.interceptors.push(function (_request, _next) {
  let authToken = 'Bearer ' + this.$root.token
  Vue.http.headers.common['Authorization'] = authToken
  Vue.http.headers.common['Content-Type'] = 'application/json'

  // 處理一般程序
  _next(_response => {
    var data = _response.body
    // this.$root.console('執行錯誤檢查:' + data.code)
    // this.$root.console('執行錯誤檢查:' + data)
    // 200 不會有的
    // 1078 token 失效，丟進 signin
    if (data) {
      switch (parseInt(data.code)) {
        case 1078:
          this.$root.console('--> 目前頁面: ')
          this.$root.console(this.$route.name)
          this.$root.setCookieData('api-token', '')
          this.$root.console('--> 清掉 Token 紀錄...')
          if (this.$route.name !== 'InboxOrder' && this.$route.name !== 'OrderBuilded') {
            this.$root.console('---> 強制導引到首頁...')
            this.$router.push({ name: 'MainPage' })
          }
          break
        case 1101:
          this.$root.console('--> 目前頁面: ')
          this.$root.console(this.$route.name)
          if (this.$route.name !== 'InboxOrder' && this.$route.name !== 'OrderBuilded') {
            this.$root.console('---> 強制導引到首頁...')
            this.$router.push({ name: 'MainPage' })
          }
          break
        case 2077:
          this.$root.setCookieData('goto-order', '')
          this.$router.push({ name: 'Shops' })
          break;
        default:
          break
      }
    }
  })

})

// 當每一次跳轉頁面，都把頁面 loading 打開
router.afterEach(() => {
  // 返回到最頂上
  $('html,body').animate({ scrollTop: 0 }, 'slow');
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    // 處理選單相關功能
    intWindowWidth: 0,
    objWindow: window,
    // Library
    common: false,
    // 多語系功能
    i18n: i18n,
    // 目前使用的 API Token
    token: '',
    // 目前使用的 LiveShopMemberHashID
    memberHashID: '',
    commentID: '',
    // 目前使用的 Page ID
    fbpageId: '',
    // 目前使用的平台
    usePlatform: '',
    // 目前使用的商店ID
    shopID: '',
    // 目前使用的商店資料
    shop: {
      page: {
        image: ''
      }
    },
    // 目前使用者的資料
    user: {
      id: '',
      full_name: '',
      image: '',
      lang: ''
    },
    // 目前的環境
    platformEnv: process.env.VUE_APP_NODE,
    // LiffID
    liffId: process.env.VUE_APP_WEB_LIFF,
    // 後端網站根目錄
    web_url: process.env.VUE_APP_WEB_URL + '/',
    base_url: process.env.VUE_APP_BASE_URL,
    // MarketPlate
    BotChannelID: process.env.VUE_APP_MARKET_CHANNEL_ID,
    BotChannelSecret: process.env.VUE_APP_MARKET_CHANNEL_SECRET,
    session_domain: process.env.VUE_APP_SESSION_DOMAIN,
    //- qrcode-view
    qrcode_view_status: false,
    code_uri: '',
    code_shopname: '',
    // 下載畫面開關
    showDownloadView: false,
    // 選單開關
    toggleAside: false,
    orderWorkers: []
  },
  watch: {
    '$route.name' (_name) {
      this.processMenu()
    }
  },
  created () {
    // 輸出目前環境
    // this.$root.console('MODEL:' + process.env.NODE_ENV)
    // this.$root.console('API_URL:' + process.env.VUE_APP_API_URL)
    // this.$root.console('WEB_URL:' + this.web_url)

    // 處理基底 Library
    this.common = new Common(this);

    // 更換網頁標題
    $('title').html("LiveShop | Let's sell on Live")

    // 嘗試取得 Cookie
    this.token = this.getCookie()

    // 取得目前使用的 SHOP ID
    if (this.$route.params.shop) {
      // 取得商店 ID
      this.shopID = this.$route.params.shop
      // 取得商店資料
      this.getShop()
    }

    // 取得目前使用的會員ID
    if (this.$route.params.memberHashID) {
      // 設置目前參數
      this.memberHashID = this.$route.params.memberHashID
    }

    // 取得目前使用的留言ID
    if (this.$route.params.commentID) {
      // 設置目前參數
      this.commentID = this.$route.params.commentID
    }

    // 取得Inbox內需使用的資料
    if (this.token) {
      this.getUserInfo()
    }
  },
  methods: {
    // 取得 Cookie 存入的 Token 資料
    getCookie (_id = 'api-token') {
      return this.$cookies.get(_id);
    },
    // 寫入 Cookie 資料
    setCookieData (_key, _value) {
      console.log('--> 寫入 Cookie [' + _key + ':' + _value + '] On ' + this.session_domain)
      this.$cookies.set(_key, _value, null, null, this.session_domain)
    },
    // 取得目前使用的商店資料
    async getShop () {
      if (!this.shopID) {
        return false
      }

      // 取得商店資料
      await this.$http.get('manager/v1/shop/' + this.shopID + '/detail').then(async function (_response) {
        if (_response.body.code === '0000') {
          this.shop = await _response.body.data

          // 如果在特殊頁面，就不處理
          if (this.$route.name === 'ShopExpire') {

          }

          // 若商店已到期，就前往商店清單頁
          else if (this.shop.is_expire) {
            // 前往清單頁
            await this.$router.push({ name: 'Shops' })
          }

          // 確認是否有設定完整,若無設定完整則前往設定頁
          else if (!this.shop.currency) {
            await this.$router.push({ name: 'Currency', params: { shop: this.shop.hash_id } })
          }
        }
      })
    },
    // 取得目前登入的會員資料
    getUserInfo () {
      this.$http.get('manager/v1/user/info').then(function (_response) {
        if (_response.body.code === '0000') {
          // 取得會員資料
          this.user = _response.body.data
          // 存入 Store , 並且讓參數名稱相符
          this.$store.dispatch('user/toggleAside', {
            name: this.user.full_name,
            src: this.user.image,
            id: this.user.id,
            lang_type: this.user.lang
          })
          // 如果有設置語系，便直接變更參數
          if (this.user.lang) {
            this.i18n.locale = this.user.lang
          }
          // 確認是否有被邀請碼
          let code = this.getCookie('invite-code')
          // 若有邀請碼，送去執行加入管理員操作
          if (code) {
            // 移除 Cookie
            this.$cookies.set('invite-code', '');
            // 轉移目前位置
            this.$router.push({ name: 'ManagerInvite', params: { code: code } })
          }
          // 確認是否要去指定訂單
          let onumber = this.getCookie('goto-order')
          if (onumber) {
            // 移除 Cookie
            this.$cookies.set('goto-order', '');
            // 轉移目前位置
            this.$router.push({ name: 'GoToOrder', params: { number: onumber } })
          }
        }
      }, function () {
        // this.$root.console(_response)
      })
    },
    changeVal (_event) {
      this.$root.console('------------------>');
      this.$root.console('detail: ' + _event.currentTarget.value);
      _event.currentTarget.value = this.onlyNumber(_event.currentTarget.value)
      this.$root.console('--> 複寫內容: ' + _event.currentTarget.value)
    },
    changePhone (_event) {
      this.$root.console('------------------>');
      this.$root.console('detail: ' + _event.currentTarget.value);
      if (!this.onlyPhone(_event)) {
        _event.currentTarget.value = ''
      } else {
        return true
      }
      this.$root.console('--> 複寫內容: ' + _event.currentTarget.value)
    },
    // 只准許使用數值
    onlyNumber (_str) {
      this.$root.console('--> [數值重整], 輸入: ' + _str)

      let checkStatus = true
      for (let i = 0; i < _str.length; i++) {
        if (_str[i] === 'e') {
          checkStatus = false
        }
      }

      let _string = _str.toString()
      if (checkStatus) {
        this.$root.console('--> 回傳: ' + _string)
        return _string
      } else {
        this.$root.console('--> 回傳: ' + _string.substring(0, -1))
        return _string.substring(0, -1)
      }
    },
    // 只准許使用電話
    onlyPhone (_event) {
      this.$root.console('--> [電話重整], 輸入: ' + _event.key)


      let alowStrs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '+', 'Tab', 'Shift', 'Backspace']
      let checkStatus = false
      if (alowStrs.indexOf(_event.key) > -1) {
        checkStatus = true
      }

      if (checkStatus) {
        return true
      } else {
        return false
      }
    },
    // 請求出現錯誤訊息
    showAlert (_message, _type = "error") {
      switch (_type) {
        case 'error':
          Vue.$toast.error(_message, {
            position: 'top'
          })
          break;

        case 'warning':
          Vue.$toast.warning(_message, {
            position: 'top'
          })
          break;

        case 'info':
          Vue.$toast.info(_message, {
            position: 'top'
          })
          break;

        case 'success':
          Vue.$toast.success(_message, {
            position: 'top'
          })
          break;
      }
    },
    // 處理選單縮回功能
    processMenu () {
      let vm = this
      let width = this.intWindowWidth
      width = vm.objWindow.innerWidth
      this.$root.console("--> 頁面寬度正在改變: [MENU:" + JSON.stringify(this.$root.toggleAside) + "][WIDTH:" + width + "]")
      if (this.$root.toggleAside && width < 768) {
        this.$root.toggleAside = false
      }
    },
    // 處理 Console
    console (_text) {
      if (this.platformEnv !== 'prod') {
        console.log(_text)
      }
    }
  }
}).$mount('#app')
