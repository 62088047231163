<template lang="pug">
//- REGISTER 選擇語系的下拉選單
.dropdown-user-info
  dropdown(
    :boolVisible="boolVisible",
    @hide="hide",
    :boolRight="true",
    :objMenuStyle="objDropStyle"
  )
    .d-flex.flex-column.px-4(slot="menu")
      .d-flex.flex-row.justify-content-between.align-items-center.py-3
        div
          span language：
          span(v-if="objUser.lang_type == 'zh'") 繁體中文
          span(v-if="objUser.lang_type == 'cn'") 簡體中文
          span(v-if="objUser.lang_type == 'en'") English
        a.d-flex(@click="changeLang()")
          icon(:iconName="'solidEdit'")
      //- hr.my-1
      .d-flex.flex-row.justify-content-between.align-items-center.py-3(
        v-if="!isChangeLang"
      )
        a.d-flex(@click="setLogout()") {{ $t('message.common_logout') }}
      .d-flex.flex-row.justify-content-between.align-items-center.py-3(
        v-else,
        v-for="lang in langList"
      )
        a.d-flex(@click="setStrLang(lang.type)") {{ lang.name }}
</template>
<script>
import { mapState } from 'vuex'
import icon from '../../icons.vue'
import dropdown from './default.vue'
export default {
  props: {
    // 是否 顯示
    boolVisible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      // 使用者資訊的下拉選單
      objDropStyle: {
        minWidth: '' // 22rem
      },
      // 是否需要變更語言
      isChangeLang: false,
      // 語言清單
      langList: [
        { name: '繁體中文', type: 'zh' },
        { name: '簡體中文', type: 'cn' },
        // { name: 'English', type: 'en' }
      ]
    }
  },
  components: {
    icon,
    dropdown
  },
  computed: {
    // ...mapGetters('user', ['objLang']),
    ...mapState('user', {
      objUser: 'objUser'
    }),
    // 語系的名稱
    strLangName: function () {
      let _type = this.objUser.lang_type
      let _str = ''
      if (_type === 'zh') {
        _str = '繁體中文'
      } else if (_type === 'cn') {
        _str = '簡體中文'
      } else if (_type === 'en') {
        _str = 'English'
      }
      return _str
    },
  },
  methods: {
    hide: function () {
      this.$emit('hide')
    },
    // 更換語言
    changeLang () {
      this.isChangeLang = !this.isChangeLang
    },
    // 執行切換語言
    setStrLang (_langType) {
      this.$root.console('--> 嘗試變更語言代號: ' + _langType)
      // 更新語言代號
      this.$store.dispatch('user/toggleAside', {
        name: this.objUser.name,
        src: this.objUser.src,
        id: this.objUser.id,
        lang_type: _langType
      })
      // 切換語言
      this.$root.i18n.locale = _langType
      // 更新語言設置
      if (this.$root.i18n.locale) {
        this.$http.put('manager/v1/user/lang/' + this.$root.i18n.locale).then(function (_response) {
          if (_response.body.code === '0000') {
            // this.$root.console('--> 語系變更成功')
            this.$root.getUserInfo()
          }
        })
      }
      // 關閉切換語言
      this.isChangeLang = false
    },
    // 請求登出帳戶
    setLogout () {
      this.$http.post('manager/v1/user/logout').then(function (_response) {
        if (_response.body.code === '0000') {
          this.$root.console('---> 導回登入頁')
          this.$router.push({ name: 'MainPage' })
          this.$root.console('---> 執行畫面刷新')
          location.reload()
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@/assets/sass/require/common.sass"
.dropdown-user-info
  color: $color-gray
  .icon
    color: $color-sysc
</style>
