<template lang="pug">
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
    this.$root.console('---> 將導轉至: /main');
    this.reload()
  },
  methods: {
    reload () {
      // 前往訂單列表頁
      this.$router.push({ name: 'MainPage' })
    }
  },
}
</script>
