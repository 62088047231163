<template lang="pug">
.container
  .login-page(v-if="needLogin")
    //- 大版 Banner 區塊
    .main-banner
      img.pt-5(src="../assets/image/main-banner.png")
    //- 隱私權條款同意方塊
    .main-sign-in-alert
      .form-row
        uiCheckbox(@click="setAgreeAlert", :boolActive="agreeAlert")
        .label 
          span {{ $t('message.main_agree_policy_by_phone') }}
          a(target="new", href="/privacy-policy") {{ $t('message.service_privacy_policy') }}
          span {{ $t('message.service_and') }}
          a(target="new", href="/terms-service") {{ $t('message.service_terms_service') }}
    .p-2
    .main-sign-in-button
      button.btn-line-login(
        type="button",
        @click="askGoLineLogin()",
        :disabled="!agreeAlert"
      )
        .overlay
        .icon
          .icon-inner
            img(src="../assets/image/line-b.png")
        span.font-size-xs.d-lg-none {{ $t('message.link_use_line_login') }}
        span.font-size-lg.d-none.d-md-block {{ $t('message.link_use_line_login') }}
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons.vue'
// import Common from '../library/Common'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    icon,
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  props: {
    userId: false,
    userName: false,
    linkToken: false,
    lineBaseId: false,
    nonce: false,
  },
  data () {
    return {
      needLogin: false,
      agreeAlert: false,
      goLinkAccountStatus: false,
    }
  },
  created () {
    if (this.userId) {
      this.getLineLinkAccountUri()
    }

    else {
      this.needLogin = true
    }
  },
  methods: {
    // 設置同意方塊
    setAgreeAlert () {
      this.agreeAlert = !this.agreeAlert
    },
    // 請求進行 Line 登入
    askGoLineLogin () {
      let currentLocation = window.btoa(window.location)
      let goUri = this.$root.web_url + 'link/auth/line/' + this.lineBaseId + '?state=' + currentLocation
      console.log('goUri: ' + goUri)
      window.location.href = goUri
    },
    // 前往 Line 帳號完成 Account Link 操作
    getLineLinkAccountUri () {
      let goUri = 'https://access.line.me/dialog/bot/accountLink?linkToken=' + this.linkToken + '&nonce=' + this.nonce
      console.log('goUri: ' + goUri)
      window.location.href = goUri
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  .main-header
    position: relative
    margin-top: 5%
    margin-bottom: 55px
    .header-logo
      img
        max-width: 100%
        margin: 0px auto
      .header-title
        font-weight: bold
  .main-banner
    margin: 0px auto
    img
      margin: 0px auto
      max-width: 100%
      max-height: 250px
      margin-bottom: 25px
  .main-sign-in-alert
    text-align: center
    margin-bottom: 15px
  .main-sign-in-button
    text-align: center
    margin-bottom: 35px
    .font-size-xs
      font-size: .8em
    .font-size-lg
      font-size: 1em
    .other-info
      padding: 3px
      font-weight: bold
  .main-footer
    width: 54%
    margin-left: 23%
    margin-bottom: 10%
    text-align: left
  .footer-next
    font-weight: bold
  .footer-info
    p
      margin: 0px
</style>