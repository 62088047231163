import { render, staticRenderFns } from "./icon-customer-chatbot.vue?vue&type=template&id=3354be78&lang=pug&"
var script = {}
import style0 from "./icon-customer-chatbot.vue?vue&type=style&index=0&id=3354be78&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports