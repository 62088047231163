<template lang="pug">
.currency-page.container-fluid
  .step-info.step-info-xs.d-lg-none {{ $t('message.currency_step_title') }}
  .step-info.step-info-lg.d-none.d-md-block {{ $t('message.currency_step_title') }}
  .currency-content
    .row
      .col-2
      .col-8.text-center
        .p-2
        .currency-title {{ $t('message.currency_select_type') }}
        .p-1
        .currency-info.font-size-xs.d-lg-none
          .text {{ $t('message.currency_info_text_01') }}
          .text {{ $t('message.currency_info_text_02') }}
        .currency-info.font-size-lg.d-none.d-md-block
          .text {{ $t('message.currency_info_text_01') }}
          .text {{ $t('message.currency_info_text_02') }}
        .p-1
        .currency-select
          .select-group
            select.select(v-model="useCurrency")
              option(
                v-for="(currency, index) in Currencys",
                :value="currency.value"
              ) {{ currency.name }}
        .p-1
        .currency-select
          .select-group
            select.select(v-model="useTimeZone")
              option(value="0") {{ $t('message.congratulations_setting_timezone') }}
              option(v-for="(zone, index) in TimeZones", :value="zone.value") {{ zone.name }}
        .currency-button
          button.btn-default.btn-lg(type="button", @click="setTimeZone()") {{ $t('message.currency_confirm_button') }}
      .col-2
</template>

<script>
import Currencys from '../library/Currency'
import TimeZones from '../assets/rawdata/timezone'
export default {
  data () {
    return {
      shopID: '',
      Currencys: Currencys,
      TimeZones: TimeZones,
      useCurrency: 'TWD',
      useTimeZone: 'Asia/Taipei'
    }
  },
  created () {
  },
  methods: {
    // 確定設定幣值
    setCurrency () {
      // 找到目前所使用的商店對象
      let shopID = this.$root.shopID
      // 送出設定幣值請求
      this.$http.put('manager/v1/shop/' + shopID + '/currency/' + this.useCurrency).then(function (_response) {
        if (_response.body.code === '0000') {
          // 設定完成，更新商店資料
          this.$root.getShop()
          // 前往下一頁
          this.$router.push({ name: 'Congratulations', params: { shop: shopID } })
        }
      }, function (_response) {
        this.$root.showAlert(_response.body.data, 'error')
      })
    },
    // 設定時區
    setTimeZone () {
      if (!this.useCurrency) {
        this.$root.showAlert(this.$t('message.alert_use_currency_null'), 'error')
        return false
      }

      if (!this.useTimeZone) {
        this.$root.showAlert(this.$t('message.alert_use_timezone_null'), 'error')
        return false
      }

      // 找到目前所使用的商店對象
      let shopID = this.$root.shopID
      // 送出設定幣值請求
      this.$http.put('manager/v1/shop/' + shopID + '/setBase', {
        CurrencyID: this.useCurrency,
        TimeZone: this.useTimeZone
      }).then(function (_response) {
        if (_response.body.code === '0000') {
          // 設定完成，更新商店資料
          this.$root.getShop()
          // 前往下一頁
          this.$router.push({ name: 'Congratulations', params: { shop: shopID } })
        }
      }, function (_response) {
        this.$root.showAlert(_response.body.data, 'error')
      })
    }
  }
}
</script>

<style scoped lang="sass">
.currency-page
  .step-info
    margin-left: -15px
    margin-right: -15px
    padding: 5px 15px
    font-weight: bold
    background: #F2F2F2
    // ==== 處理進度漸層色 ====
    border-bottom: 5px solid transparent
    border-image: linear-gradient(to right, #1977F2 0%, #D1D1D1 85%)
    border-image-slice: 1
  .step-info-xs
    font-size: 1.3em
  .step-info-lg
    font-size: 3em
  .currency-content
    .currency-title
      font-size: 1.5em
      font-weight: bold
      padding: 2% 0px
    .currency-info
      margin-bottom: 2%
    .font-size-xs
      font-size: .8em
    .font-size-lg
      font-size: 1.5em
    .currency-select
      margin-bottom: 25px
      .select-group
        margin: 0px auto
    .currency-button
      padding-top: 5%
</style>
