<template lang="pug">
.confirm-page
  .container-fluid
    .row.mb-4
      .col-12.mt-3
        h1 
          span(v-if="order.status === 1") {{ $t('message.inbox_order_confirm_title') }}
          span(v-else) {{ $t('message.order_info_title') }}
      .col-md-6.col-xs-12
        .row
          //- 訂單狀態
          .col-12.mt-3
            .card.order-info
              .card-header 
                h5 {{ $t('message.order_info_status_title') }}
              .card-body
                .inpu-group.row
                  .col-4.pt-2 {{ $t('message.order_info_current_progress') }}
                  .col-7.pt-2 
                    span(v-if="order.status === 0") {{ $t('message.orders_order_type_waiting') }}
                    span(v-if="order.status === 1") {{ $t('message.orders_order_type_process') }}
                    span(v-if="order.status === 2") {{ $t('message.orders_order_type_waitship') }}
                    span(v-if="order.status === 3") {{ $t('message.orders_order_type_shiped') }}
                    span(v-if="order.status === 4") {{ $t('message.orders_order_type_canceled') }}

          //- 訂單細節
          .col-12.mt-3
            .card.order-info
              .card-header 
                h5 
                  span(v-if="order.status === 0") 請確認您的購物車內容
                  span(v-else-if="needConfirm") 請確認您的訂單內容
                  span(v-else) {{ $t('message.inbox_order_info_title') }}
              .card-body
                .order-detail(v-for="(detail, index) in order.details")
                  .detail-id
                    .detail-item.row
                      .col-12.pt-2 {{ $t('message.common_table_rowid') }} {{ '#' + (parseInt(index) + 1) }}
                  .detail-name.pt-2
                    .detail-item.row
                      .col-3.pt-2 {{ $t('message.inbox_order_detail_name') }}
                      .col-8.pt-2.text-right {{ detail.name }}
                      datalist#pitemList02
                        option(
                          v-for="(product, index) in products",
                          :value="product.name"
                        ) {{ product.name }}
                  .detail-quy.pt-2
                    .detail-item.row
                      .col-3.pt-2 {{ $t('message.inbox_order_detail_count') }}
                      .col-8.pt-2.text-right {{ detail.quy }}
                  .detail-price.pt-2
                    .detail-item.row
                      .col-3.pt-2 {{ $t('message.inbox_order_detail_price') }}
                      .col-8.pt-2.text-right {{ order.currency }} ${{ detail.price }}
                  hr
                .order-amtinfo
                  .detail-shipcost
                    .detail-item.row
                      .col-3.pt-2 {{ $t('message.inbox_order_post_cost') }}
                      .col-8.pt-2.text-right {{ order.currency }} ${{ order.ship_cost }}
                  .detail-total
                    .detail-item.row
                      .col-3.pt-2 {{ $t('message.inbox_order_total_amt') }}
                      .col-8.pt-2.text-right {{ order.currency }} ${{ order.total }}

          //- 收件資料
          .col-12.mt-3
            .card.order-info
              .card-header 
                h5
                  span(v-if="needConfirm") 設定收件人資料
                  span(v-else) 收件人資料
              .card-body
                h6.mb-2 {{ $t('message.inbox_order_member_info_title') }}
                .inpu-group.row
                  .col-3.py-2 {{ $t('message.inbox_order_member_name') }}
                  .col-8.py-2 
                    .input-group(v-if="needConfirm")
                      input.input(v-model="order.recipient_name")
                    span(v-else) {{ order.recipient_name }}
                .inpu-group.row
                  .col-3.py-2 {{ $t('message.inbox_order_member_phone') }}
                  .col-8.py-2 
                    .input-group(v-if="needConfirm")
                      input.input(v-model="order.recipient_phone")
                    span(v-else) {{ order.recipient_phone }}
                .inpu-group.row(v-if="order.post_method == 1")
                  .col-3.py-2 {{ $t('message.inbox_order_member_zipcode') }}
                  .col-8.py-2 
                    .input-group(v-if="needConfirm")
                      input.input(v-model="order.recipient_zipcode")
                    span(v-else) {{ order.recipient_zipcode }}
                .inpu-group.row(v-if="order.post_method == 1")
                  .col-3.pt-2 {{ $t('message.inbox_order_member_address') }}
                  .col-8.pt-2 
                    .input-group(v-if="needConfirm")
                      input.input(v-model="order.recipient_address")
                    span(v-else) {{ order.recipient_address }}

          //- 收件資料
          .col-12.mt-3(v-if="order.status <= 1")
            .card.order-info
              .card-header 
                h5 
                  span 設定付款與出貨
              .card-body
                .row
                  .p-1
                  .col-12
                    label.form-label(for="PaymentSwitch") 付款方式
                    select#PaymentSwitch.form-control(
                      v-model="order.pay_method"
                    )
                      option(value="0") 請選擇付款的方式
                      option(
                        v-for="(payment, index) in payments",
                        :value="payment.id"
                      ) {{ payment.name }}
                .row
                  .p-1
                  .col-12
                    label.form-label(for="ShipSwitch") 出貨方式
                    select#ShipSwitch.form-control(v-model="order.post_method")
                      option(value="0") 請選擇出貨的方式
                      option(v-for="(ship, index) in ships", :value="ship.id") {{ ship.name }}

            //- 出貨方式
          .col-12.mt-3(v-if="order.post_method >= 3 && order.post_method <= 5")
            .card
              .card-header 
                h5(v-if="order.store_id") 已設定的取貨超商
                h5(v-else) 選擇取貨超商
              .card-body(v-if="order.store_id") 
                .p-1
                .inpu-group.row
                  .col-3.pt-2 超商種類
                  .col-8.pt-2 
                    span(v-if="order.post_method == 3") 全家超商
                    span(v-if="order.post_method == 4") 7-11超商
                    span(v-if="order.post_method == 5") 萊爾富超商
                .inpu-group.row
                  .col-3.pt-2 郵遞區號
                  .col-8.pt-2 
                    span {{ order.recipient_zipcode }}
                .inpu-group.row
                  .col-3.pt-2 超商地址
                  .col-8.pt-2 
                    span {{ order.recipient_address }}
              .card-body(v-else)
                .p-1
                .col-12(
                  v-if="order.post_method >= 3 && order.post_method <= 5"
                )
                  button.btn.btn-primary.btn-default.d-block(
                    @click="setOrderShipMap(order.number, 3)",
                    v-if="order.post_method == 3"
                  ) 前往設定 全家 門市
                  button.btn.btn-primary.btn-default.d-block(
                    @click="setOrderShipMap(order.number, 4)",
                    v-if="order.post_method == 4"
                  ) 前往設定 7-11 門市
                  button.btn.btn-primary.btn-default.d-block(
                    @click="setOrderShipMap(order.number, 5)",
                    v-if="order.post_method == 5"
                  ) 前往設定 萊爾富 門市
                .p-3

      .col-md-6.col-xs-12
        .row
          //- 付款與出貨
          .col-12.mt-3(v-if="order.status > 1")
            .card
              .card-header 
                h5 {{ $t('message.order_info_payment_and_ship_title') }}
              .card-body
                .inpu-group.row
                  .col-4.py-2 {{ $t('message.orders_order_table_title_10') }}
                  .col-7.py-2
                    span(v-if="order.post_method === 1") {{ $t('message.orders_order_post_tohome') }}
                    span(v-if="order.post_method === 2") {{ $t('message.orders_order_post_tostore') }}
                    span(v-if="order.post_method === 3") {{ $t('message.orders_order_post_to_FAMIC2C') }}
                    span(v-if="order.post_method === 4") {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                    span(v-if="order.post_method === 5") {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                .inpu-group.row
                  .col-4.py-2 {{ $t('message.orders_order_table_title_06') }}
                  .col-7.py-2
                    span(v-if="order.pay_method === 1") {{ $t('message.orders_order_payment_cash') }}
                    span(v-if="order.pay_method === 2") {{ $t('message.orders_order_payment_atm') }}
                    span(v-if="order.pay_method === 3") {{ $t('message.orders_order_payment_linemoney') }}
                    span(v-if="order.pay_method === 4") {{ $t('message.orders_order_payment_linepay') }}
                    span(v-if="order.pay_method === 5") {{ $t('message.orders_order_payment_ecpay') }}
                .inpu-group.row
                  .col-4.py-2 {{ $t('message.orders_order_table_title_11') }}
                  .col-7.py-2 {{ order.payed_at }}
                .inpu-group.row
                  .col-4.pt-2 {{ $t('message.order_info_ship_code') }}
                  .col-7.pt-2 
                    span(v-if="order.ship_code") {{ order.ship_code }}
                    span(v-else) -

          //- 付款資訊
          .col-12.mt-3(v-if="order.status >= 1 && order.pay_method === 2")
            .card.order-info
              .card-header 
                h5 {{ $t('message.order_info_payment_info') }}
              .card-body
                .inpu-group.row
                  .col-4.py-2 {{ $t('message.order_info_payment_bank') }}
                  .col-7.py-2 
                    span(v-if="order.atm_bank") {{ order.atm_bank }}
                    span(v-else) {{ order.payment.bank }}
                .inpu-group.row
                  .col-4.pt-2 {{ $t('message.order_info_payment_account') }}
                  .col-7.pt-2 
                    span(v-if="order.atm_number") {{ order.atm_number }}
                    span(v-else) {{ order.payment.number }}

          //- 買家備註
          .col-12.mt-3(v-if="order.recipient_note")
            .card
              .card-body
                h6 {{ $t('message.inbox_order_recipient_note') }}
                .member-note(
                  v-html="order.recipient_note.replace(/\\r?\\n/g, '<br>')"
                )

    .text-center.mb-4.py-2(v-if="needConfirm")
      button.btn-default(@click="confirmOrder()") {{ $t('message.order_info_confirm_order') }}
    .text-center.mb-4.py-2(v-if="order.status === 0")
      button.btn-default(type="button", @click="sendOrderToSeller") 找賣家結帳去
</template>

<script>
// import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio,
    icon
  },
  data () {
    return {
      // 訂單序號
      number: '',
      // 訂單內容
      order: {
        // 所屬會員
        member: {
          name: '',
          phone: '',
          zip: '',
          address: ''
        },
        // 訂單細節
        details: [
          {
            name: '',
            quy: 1,
            price: ''
          }
        ],
        // 付款方式
        pay_method: 1,
        // 買家備註
        recipient_note: '',
        // 賣家備註
        shop_note: '',
        // 通知紀錄
        notices: [],
      },
      // 運費
      post_cost: 0,
      // 自動計算總價
      total: 0,
      // 商店所屬商品清單
      products: [],
      // 判斷是否有修改過
      order_change: false,
      // 是否需要設定出貨
      order_ship: false,
      // 品牌可使用的付款方式
      paymentSwitch: true,
      payments: [],
      // 品牌可使用的出貨方式
      shipSwitch: true,
      ships: [],
      // Line@ ID
      line_id: false,
      // 判斷是否需要進行確認操作
      needConfirm: false,
      // 目前是否開始監聽
      needWatch: false
    }
  },
  watch: {
    "order.post_method" (_postMethod) {
      if (this.needWatch && _postMethod) {
        if (_postMethod >= 3 && _postMethod <= 5) {
          this.order.store_id = ""
        } else {
          this.order.recipient_zipcode = ""
          this.order.recipient_address = ""
        }
      }
    }
  },
  created () {
    if (this.$route.params.number) {
      this.number = this.$route.params.number
    }
    this.getOrder()
  },
  methods: {
    // 取得目前訂單與資訊
    getOrder () {
      // 取得訂單資料
      this.$http.get('extension/v1/order/' + this.number + '/detail').then(function (_response) {
        if (_response.body.code === '0000') {
          // 灌入訂單資料
          this.order = _response.body.data
          this.paymentSwitch = _response.body.payment_switch
          this.payments = _response.body.payments
          this.shipSwitch = _response.body.ship_switch
          this.ships = _response.body.ships
          this.line_id = _response.body.line_id

          // 確認訂單是否需要進行驗證
          this.needConfirm = (this.order.status === 1 && !this.order.confirm_at) ? true : false

          // 強制更換語言
          setTimeout(() => {
            let lang = 'en'
            if (this.order.lang) {
              lang = (this.order.lang === 'zhTw') ? 'zh' : this.order.lang
            }
            this.$root.console('---> 指定語系: ' + lang)
            this.$root.i18n.locale = lang
            // 啟動監測
            this.needWatch = true
          }, 1000);
          // 重新計算訂單
          this.processOrder()
        }
      }, function (_response) {
        this.useOrderErr = _response.body.code
      })
    },
    // 重新計算訂單
    processOrder () {
      let vm = this
      // 重新計算費用
      let total = 0
      this.order.details.forEach(function (_detail, _index) {
        if (_detail.price && _detail.quy) {
          total += parseFloat(parseFloat(_detail.price) * parseFloat(_detail.quy))
          // 格式化
          vm.$data.order.details[_index].price = vm.$root.common.formatNumber(_detail.price)
        }
      })
      // 增加運費
      if (this.order.ship_cost) {
        total += parseFloat(this.order.ship_cost)
      }
      // 將數字格式化
      this.order.ship_cost = this.$root.common.formatNumber(this.order.ship_cost)
      this.order.total = this.$root.common.formatNumber(total)
    },
    // 確認訂單
    confirmOrder () {
      this.$http.post('extension/v1/confirm/order/' + this.order.number, this.order).then(function (_response) {
        if (_response.body.code === '0000') {
          // 重新抓取訂單
          this.getOrder()
        }
      })
    },
    // 送單給賣家
    sendOrderToSeller () {
      let keyword = encodeURI("呼叫小幫手，我要結帳囉～")
      let targetUri = 'https://line.me/R/oaMessage/' + this.line_id + '/?' + keyword
      window.location.href = targetUri
    },
    // 前往設定指定取貨超商
    setOrderShipMap (_orderNumber, _postMethod) {
      let targetUri = this.$root.base_url + '/ecpay/' + _orderNumber + '/map/' + _postMethod
      console.log("=> 前往設定商店: ", targetUri)
      window.location.href = targetUri
    }
  }
}
</script>

<style scoped lang="sass">
.member-note
  padding: 15px 10px
  border: 1px solid #aaa
  margin: 10px 0px
  border-radius: 5px
.card-header .float-right .btn-sm
  padding: 0.3rem
  min-width: 50px
</style>