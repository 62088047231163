import { render, staticRenderFns } from "./icon-regular-arrow-right.vue?vue&type=template&id=12699c2c&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports