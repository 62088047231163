<template lang="pug">
  .menu-page
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped>
</style>
