<template lang="pug">
.checkbox(
  ref="checkbox",
  :class="{ active: boolActive, disabled: boolDisabled }",
  @click="click",
  :style="objStyle",
  :disabled="boolDisabled"
)
</template>
<script>
import $ from 'jquery'
export default {
  props: {
    // 是否 啟動
    boolActive: {
      type: Boolean,
      required: true,
      default: false
    },
    // 是否 關閉功能
    boolDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // style 接口
    objStyle: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    click: function (_event) {
      if ($(this.$refs.checkbox).attr('disabled') !== 'disabled') {
        this.$emit('click')
      }
    }
  }
}
</script>
<style lang="sass" scoped>
@import "../../../assets/sass/require/common.sass"
</style>
