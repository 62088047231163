<template lang="pug">
.page.setting-page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-row.align-items-stretch
        .section-body
          .container-fluid
            .row
              .col-12.py-3
                .card.page-info
                  .card-header
                    .row
                      .col-6
                        span(v-if="$root.shop.page.platform_type === 1") {{ $t('message.setting_pages_title') }}
                        span(v-if="$root.shop.page.platform_type === 2") {{ $t('message.setting_pages_title_for_line') }}
                      .col-6
                        .d-inline-flex.align-items-center.float-right
                          uiSwitch(
                            :boolActive="!boolShopIsClosed",
                            @click="setShopStatus()"
                          )
                          span.ml-2(v-if="$root.shop.page.platform_type === 1") {{ $t('message.setting_fanPage_autoReply') }}
                          span.ml-2(v-if="$root.shop.page.platform_type === 2") {{ $t('message.setting_lineChannel_autoReply') }}

                  //- 綁定 FaceBook Page / Line@ 帳號
                  .card-body
                    //- 電腦版顯示
                    .d-none.d-md-block
                      .row
                        .col-md-7
                          .row.no-gutters
                            .col-md-1.d-flex.align-items-center
                              img.page-logo(
                                v-if="$root.shop.page.platform_type === 1",
                                :src="$root.shop.page.image"
                              )
                              img.page-logo(
                                v-if="$root.shop.page.platform_type === 2",
                                src="../assets/image/default-icon.jpeg"
                              )
                            .col-md-9.d-flex.align-items-center
                              .ml-2
                                .text
                                  .shop-page-name {{ $root.shop.page.name }}
                                .text.shop-plan-info
                                  span(v-if="$root.shop.plan_id === 0") 試用方案
                                  span(v-if="$root.shop.plan_id === 1") 單月方案
                                  span(v-if="$root.shop.plan_id === 2") 單季方案
                                  span(v-if="$root.shop.plan_id === 3") 年度方案
                                  span(v-if="$root.shop.is_expire") , 方案已到期
                                  span(v-else) , 剩餘 {{ $root.shop.diffday }} 天
                        .col-md-5.d-flex.justify-content-end.align-items-center
                          .d-inline-flex.align-items-center
                            button.btn-sm.btn-error-outline(
                              @click="removeShop()"
                            ) 
                              span(v-if="$root.shop.page.platform_type === 1") {{ $t('message.setting_pages_unlink_facebook') }}
                              span(v-if="$root.shop.page.platform_type === 2") {{ $t('message.setting_pages_unlink_line') }}
                    //- 手機版顯示
                    .d-md-none
                      .text-center
                        img.page-logo(:src="$root.shop.page.image")
                        .mb-2
                      .text-center {{ $root.shop.page.name }}
                      .mb-2
                      .d-flex.align-items-center.justify-content-center
                        button.btn-sm.btn-error-outline(@click="removeShop()") {{ $t('message.setting_pages_unlink_facebook') }}

                  //- 修正版面顯示問題
                  hr(v-if="$root.shop.page.platform_type === 1")
                  //- 綁定 Instagram 帳號
                  .card-body(v-if="$root.shop.page.platform_type === 1")
                    //- 電腦版顯示
                    .d-none.d-md-block
                      .no-gutters.text-left(v-if="can_bind_insgatram")
                        button.btn-sm.btn-default-outline(
                          @click="bindInstagram"
                        ) 綁定 Instagram 帳號
                      .row(v-else)
                        .col-md-7
                          .row.no-gutters
                            .col-md-1.d-flex.align-items-center(
                              v-if="$root.shop.instagram"
                            )
                              img.page-logo(
                                :src="$root.shop.instagram.instagram_image"
                              )
                            .col-md-9.d-flex.align-items-center
                              .ml-2
                                .text
                                  .shop-page-name {{ $root.shop.instagram.instagram_name }}
                                .text.shop-plan-info
                                  span(
                                    v-if="$root.shop.instagram.instagram_displayname"
                                  ) {{ $root.shop.instagram.instagram_displayname }}
                                  span(v-else) 未設置名字的 IG 帳號
                        .col-md-5.d-flex.justify-content-end.align-items-center
                          .d-inline-flex.align-items-center
                            button.btn-sm.btn-error-outline(
                              @click="unBindInstagram"
                            ) {{ $t('message.setting_pages_unlink_instagram') }}
                    //- 手機版顯示
                    .d-md-none
                      .no-gutters.text-center(v-if="can_bind_insgatram")
                        button.btn-sm.btn-default-outline(
                          @click="bindInstagram"
                        ) 綁定 Instagram 帳號
                      .show-page-instagram(v-else)
                        .text-center(v-if="$root.shop.instagram")
                          img.page-logo(
                            :src="$root.shop.instagram.instagram_image"
                          )
                          .mb-2
                        .text-center {{ $root.shop.instagram.instagram_name }}
                        .mb-2
                        .d-flex.align-items-center.justify-content-center
                          button.btn-sm.btn-error-outline(
                            @click="unBindInstagram"
                          ) {{ $t('message.setting_pages_unlink_instagram') }}

                .p-2

                //- Line Liff ID
                .card(v-if="$root.shop.is_liff")
                  .p-2
                  .card-header
                    .row
                      .col-6
                        span {{ $t('message.setting_liff_id') }}
                      .col-6
                        .float-right
                          button.btn-sm.btn-info(
                            v-if="!setting_liffacc_edit_status",
                            @click="setEditStatus('setting_liffacc_edit_status')"
                          ) 編輯
                          button.btn-sm.btn-default(
                            v-else,
                            @click="saveLiffAcc()"
                          ) 儲存
                  .card-body.auto-response
                    .row
                      .col-xs-12.col-md-6
                        .inpu-group
                          | {{ $t('message.setting_liff_id_title') }}
                          input.input.ml-2(
                            v-if="setting_liffacc_edit_status",
                            v-model="account.line_login_channel_id"
                          )
                          span(v-else) : {{ account.line_login_channel_id }}
                      .col-xs-12.col-md-6
                        .inpu-group
                          | {{ $t('message.setting_liff_secret_title') }}
                          input.input.ml-2(
                            v-if="setting_liffacc_edit_status",
                            v-model="account.line_login_channel_secret"
                          )
                          span(v-else) : {{ account.line_login_channel_secret }}

                //- Line 官方帳號關鍵字連結產生器
                .p-2(v-if="$root.shop.page.platform_type === 2")
                .card(v-if="$root.shop.page.platform_type === 2")
                  .card-header {{ $t('message.setting_product_link_generator') }}
                  .card-body.auto-response
                    .row
                      .col-xs-12.col-md-4
                        .inpu-group
                          | {{ $t('message.setting_product_keyword_title') }}
                          input.input.ml-2(
                            v-model="gen_keyword",
                            :placeholder="$t('message.setting_product_keyword_input')"
                          )
                      .col-xs-12.col-md-8(v-if="gen_link")
                        .inpu-group
                          | {{ $t('message.setting_product_link_title') }}
                          input#linkGenerator.input.ml-2(:value="gen_link")
                    .row
                      .col-12
                        span {{ $t('message.setting_product_link_info') }}

                .p-2
                .card
                  .card-header {{ $t('message.setting_manager_list_title') }}
                  .card-body
                    //- 電腦版畫面
                    .show-lg.d-none.d-md-block
                      span {{ $t('message.setting_manager_alert_text') }}
                      .p-3
                      .manager-header.d-flex.justify-content-between.align-items-center.p-3
                        .manager-title {{ $t('message.setting_manager_details_title') }}
                        .manager-addbtn
                          button.btn-default(
                            type="button",
                            data-toggle="modal",
                            data-target="#staticBackdrop"
                          ) {{ $t('message.setting_manager_add_button') }}
                      .manager-body
                        .manager-item(v-for="(manager, index) in managers")
                          .manager-image
                            img(:src="manager.user.image", alt="alt")
                          .manager-name 
                            span {{ manager.user.full_name }}
                            span(v-if="manager.type === 1") ({{ $t('message.setting_manager_shop_own') }})
                          .manager-button(v-if="managers.length > 1")
                            button.btn-default-outline(
                              @click="delAdmin(manager.id)"
                            ) {{ $t('message.setting_manager_remove_button') }}
                          .clearall
                        .clearall
                    //- 手機版畫面
                    .show-xs.d-md-none
                      .manager-item(v-for="(manager, index) in managers")
                        .row
                          .col-3.manager-image
                            img(:src="manager.user.image", alt="alt")
                          .col-5.manager-name 
                            span {{ manager.user.full_name }}
                            span(v-if="manager.type === 1") ({{ $t('message.setting_manager_shop_own') }})
                          .col-3.manager-button(v-if="managers.length > 1")
                            .remove(@click="delAdmin(manager.id)")
                              icon(:iconName="'lightTimes'") 
                      .d-flex.justify-content-center
                        button.btn-default(
                          type="button",
                          data-toggle="modal",
                          data-target="#staticBackdrop"
                        ) {{ $t('message.setting_manager_add_button') }}
                    //- Modal
                    #staticBackdrop.modal.fade(
                      data-backdrop="static",
                      tabindex="-1",
                      role="dialog",
                      aria-labelledby="staticBackdropLabel",
                      aria-hidden="true"
                    )
                      .modal-dialog(role="document")
                        .modal-content
                          .modal-header
                            h5#staticBackdropLabel.modal-title {{ $t('message.setting_manager_invite_title') }}
                            button.close(
                              type="button",
                              data-dismiss="modal",
                              aria-label="Close"
                            )
                              span(aria-hidden="true") ×
                          .modal-body
                            .text {{ $t('message.setting_manager_invite_context_01') }}
                            .text {{ $t('message.setting_manager_invite_context_02') }}
                            input.link(type="text", :value="invite_url")
                          .modal-footer.text-center
                            button.btn.btn-primary(
                              type="button",
                              @click="copyFunction()",
                              data-dismiss="modal"
                            ) {{ $t('message.setting_manager_invite_copy_link') }}
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch } from '../common/ui/'

export default {
  components: {
    icon,
    uiSwitch
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      account: {
        bank: "",
        number: "",
        linepay: "",
        linepay_id: "",
        ecpay_merchantid: "",
        ecpay_hashkey: "",
        ecpay_hashiv: "",
        buyer_payment_switch: false,
        buyer_ship_switch: false,
        default_payment: 0,
        default_ship: 0,
        line_login_channel_id: "",
        line_login_channel_secret: "",
      },
      sender: {
        name: "",
        phone: "",
        zipcode: "",
        address: "",
      },
      setting_liffacc_edit_status: false,
      setting_bankacc_edit_status: false,
      setting_linemoney_edit_status: false,
      setting_linepay_edit_status: false,
      setting_card_edit_status: false,
      setting_switch_edit_status: false,
      setting_sender_edit_status: false,
      setting_default_edit_status: false,
      managers: [],
      invite_url: '',
      gen_keyword: '',
      gen_link: '',
      can_bind_insgatram: false,
    }
  },
  computed: {
    boolShopIsClosed () {
      return this.$root.shop.status === 0
    }
  },
  async created () {
    console.log('==> check point 001')
    // 重新拿商店資料
    await this.$root.getShop()
    // 處理畫面流程
    await this.autoRun()
    // 獲取管理員邀請編碼
    await this.getInviteCode()
  },
  watch: {
    gen_keyword (_keyword) {
      this.gen_link = ''
      if (_keyword) {
        this.genKeywordLink(_keyword)
      }
    }
  },
  methods: {
    // 自動化處理
    autoRun () {
      let vm = this
      console.log('==> 處理畫面相關功能')
      // --  處理畫面相關功能
      $(document).ready(function () {
        // 更新畫面資料
        if (vm.$root.shop.sender) {
          if (vm.$root.shop.sender.name) {
            vm.sender = vm.$root.shop.sender
          } else {
            vm.sender = {
              name: "",
              phone: "",
              zipcode: "",
              address: "",
            }
          }
        }

        let account = vm.$root.shop.account
        if (account) {
          if (account.number || account.linepay || account.default_ship || account.default_payment || account.line_login_channel_id) {
            vm.account = account
            vm.account.buyer_payment_switch = (vm.account.buyer_payment_switch) ? true : false
            vm.account.buyer_ship_switch = (vm.account.buyer_ship_switch) ? true : false
          } else {
            vm.account = {
              bank: "",
              number: "",
              linepay: "",
              linepay_id: "",
              ecpay_merchantid: "",
              ecpay_hashkey: "",
              ecpay_hashiv: "",
              buyer_payment_switch: false,
              buyer_ship_switch: false,
              default_payment: 0,
              default_ship: 0
            }
          }
        }
        // 取得管理員清單
        vm.getManagers()
      })
      // --  處理參數判定相關功能
      this.can_bind_insgatram = (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
    },
    // 變更編輯狀態
    setEditStatus (_type = '') {
      switch (_type) {
        case 'setting_liffacc_edit_status':
          this.setting_liffacc_edit_status = !this.setting_liffacc_edit_status
          break;
        case 'setting_bankacc_edit_status':
          this.setting_bankacc_edit_status = !this.setting_bankacc_edit_status
          break;
        case 'setting_linemoney_edit_status':
          this.setting_linemoney_edit_status = !this.setting_linemoney_edit_status
          break;
        case 'setting_linepay_edit_status':
          this.setting_linepay_edit_status = !this.setting_linepay_edit_status
          break;
        case 'setting_card_edit_status':
          this.setting_card_edit_status = !this.setting_card_edit_status
          break;
        case 'setting_switch_edit_status':
          this.setting_switch_edit_status = !this.setting_switch_edit_status
          break;
        case 'setting_sender_edit_status':
          this.setting_sender_edit_status = !this.setting_sender_edit_status
          break;
        case 'setting_default_edit_status':
          this.setting_default_edit_status = !this.setting_default_edit_status
          break;
      }
    },
    // 更新 Liff 參數
    async saveLiffAcc () {
      let saveData = {
        line_login_channel_id: (this.account.line_login_channel_id) ? this.account.line_login_channel_id : '',
        line_login_channel_secret: (this.account.line_login_channel_secret) ? this.account.line_login_channel_secret : '',
      }
      await this.updateSetting('setting_liffacc', saveData)
      // 關閉編輯狀態
      this.setting_liffacc_edit_status = false
    },
    // 更新銀行帳號
    async saveBankAcc () {
      let saveData = {
        bank: (this.account.bank) ? this.account.bank : '',
        number: (this.account.number) ? this.account.number : '',
      }
      await this.updateSetting('setting_bankacc', saveData)
      // 關閉編輯狀態
      this.setting_bankacc_edit_status = false
    },
    // 更新 Line 個人轉帳
    async saveLineMoney () {
      let saveData = {
        linepay: (this.account.linepay) ? this.account.linepay : '',
      }
      await this.updateSetting('setting_linemoney', saveData)
      // 關閉編輯狀態
      this.setting_linemoney_edit_status = false
    },
    // 更新 Line Pay
    async saveLinePay () {
      let saveData = {
        linepay_id: (this.account.linepay_id) ? this.account.linepay_id : '',
        linepay_key: (this.account.linepay_key) ? this.account.linepay_key : '',
      }
      await this.updateSetting('setting_linepay', saveData)
      // 關閉編輯狀態
      this.setting_linepay_edit_status = false
    },
    // 更新信用卡刷卡
    async saveCardPay () {
      let saveData = {
        ecpay_merchantid: (this.account.ecpay_merchantid) ? this.account.ecpay_merchantid : '',
        ecpay_hashkey: (this.account.ecpay_hashkey) ? this.account.ecpay_hashkey : '',
        ecpay_hashiv: (this.account.ecpay_hashiv) ? this.account.ecpay_hashiv : '',
      }
      await this.updateSetting('setting_card', saveData)
      // 關閉編輯狀態
      this.setting_card_edit_status = false
    },
    // 更新用戶自選
    async saveSwitchAction () {
      let saveData = {
        buyer_payment_switch: (this.account.buyer_payment_switch) ? this.account.buyer_payment_switch : false,
        buyer_ship_switch: (this.account.buyer_ship_switch) ? this.account.buyer_ship_switch : false,
      }
      await this.updateSetting('setting_switch', saveData)
      // 關閉編輯狀態
      this.setting_switch_edit_status = false
    },
    // 更新寄件人資訊
    async saveSenderInfo () {
      let saveData = {
        name: (this.sender.name) ? this.sender.name : '',
        phone: (this.sender.phone) ? this.sender.phone : '',
        zipcode: (this.sender.zipcode) ? this.sender.zipcode : '',
        address: (this.sender.address) ? this.sender.address : '',
      }
      await this.updateSetting('setting_sender', saveData)
      // 關閉編輯狀態
      this.setting_sender_edit_status = false
    },
    // 更新預設金物流方式
    async saveDefaultSettingAction () {
      let saveData = {
        default_payment: (this.account.default_payment) ? this.account.default_payment : 0,
        default_ship: (this.account.default_ship) ? this.account.default_ship : 0,
      }
      await this.updateSetting('setting_default', saveData)

      // 關閉編輯狀態
      this.setting_default_edit_status = false
    },
    // 更新相關設定
    async updateSetting (_type = '', _saveData = {}) {
      let shopID = this.$root.shopID
      await this.$http.put('manager/v1/shop/' + shopID + '/sales', {
        type: _type,
        form: _saveData
      }).then(function (_response) {
        if (_response.body.code === '0000') {
          this.$root.shop = _response.body.data
        }
      })
    },
    // 請求解綁商店
    removeShop () {
      if (!confirm(this.$t('message.setting_pages_unlink_confirm').replace('%s', this.$root.shop.page.name))) {
        return
      }

      let shopID = this.$root.shopID
      this.$http.delete('manager/v1/shop/' + shopID + '/remove').then(function (_response) {
        if (_response.body.code === '0000') {
          // 導往商店清單頁
          this.$root.showAlert("商店解綁成功", 'success')
          setTimeout(() => {
            this.$router.push({ name: 'Shops' })
          }, 1500)
        }
      }, function (_response) {
        this.$root.showAlert(_response.body.error, 'error')
      })
    },
    // 請求綁定 IG
    async bindInstagram () {
      let shopID = this.$root.shopID
      await this.$http.post('manager/v1/shop/' + shopID + '/bind/instagram').then(async function (_response) {
        await this.$root.getShop()
        // --  處理參數判定相關功能
        this.can_bind_insgatram = (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
        // -- 粗暴重新渲染
        this.$forceUpdate()
      }, function (_error) {
        alert("綁定時發生問題\r\n" + _error.body.data)
      })
    },
    // 請求解綁 IG
    async unBindInstagram () {
      let shopID = this.$root.shopID
      await this.$http.delete('manager/v1/shop/' + shopID + '/unbind/instagram').then(async function (_response) {
        await this.$root.getShop()
        // --  處理參數判定相關功能
        this.can_bind_insgatram = await (this.$root.shop.page.platform_type && !this.$root.shop.instagram_id)
        // -- 粗暴重新渲染
        this.$forceUpdate()
      }, function (_error) {
        alert("解除綁定時發生問題\r\n" + _error.body.data)
      })
    },
    // 取得管理員清單
    getManagers () {
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/admins').then(function (_response) {
        if (_response.body.code === '0000') {
          this.managers = _response.body.data
        }
      })
    },
    // 請求一組新的管理員邀請編碼
    getInviteCode () {
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/admin/invite').then(function (_response) {
        if (_response.body.code === '0000') {
          // 請求成功
          this.invite_url = this.$root.web_url + 'manager/invite/' + _response.body.data
        }
      })
    },
    // 移除指定管理員
    delAdmin (_targetID = '') {
      let shopID = this.$root.shopID
      this.$http.delete('manager/v1/shop/' + shopID + '/admin/' + _targetID).then(function (_response) {
        if (_response.body.code === '0000') {
          this.getManagers()
        }
      }, function () {
        // 移除發生錯誤
      })
    },
    // 複製
    copyFunction () {
      $('#staticBackdrop input.link').select();
      document.execCommand("Copy");
    },
    // 設定商店狀態
    setShopStatus () {
      const shopID = this.$root.shopID
      const status = (this.boolShopIsClosed) ? 1 : 2
      this.$http.put('manager/v1/shop/' + shopID + '/status', { status: status }).then((_response) => {
        if (_response.body.code === '0000') {
          this.$root.getShop()
        }
      }
      )
    },
    // 產生 Line 官方帳號鏈結
    genKeywordLink (_keyword) {
      let keyword = encodeURI(_keyword)
      this.gen_link = 'https://line.me/R/oaMessage/' + this.$root.shop.source_id + '/?' + keyword
    },
    // 設定是否開放用戶自選支付方式
    async setShopPaymentSwitchStatus () {
      // 修改元件狀態
      this.account.buyer_payment_switch = await !this.account.buyer_payment_switch
      await this.saveSwitchAction()
    },
    // 設定是否開放用戶自選出貨方式
    async setShopShipSwitchStatus () {
      // 修改元件狀態
      this.account.buyer_ship_switch = await !this.account.buyer_ship_switch
      await this.saveSwitchAction()
    },
  }
}
</script>

<style scoped lang="sass">
.setting-page
  .page-logo
    img
      margin: 0px auto
  .inpu-group
    margin-bottom: 15px
  .show-lg
    .manager-body
      border-top: 1px solid #eee
      .manager-item
        margin-bottom: 15px
        padding: 10px 0
        border-bottom: 1px solid #eee
        .manager-image
          float: left
          img
            width: 100px
            border-radius: 100%
        .manager-name
          float: left
          font-size: 1.3em
          padding-top: 3%
          padding-left: 25px
        .manager-button
          float: right
          padding-top: 3%
  .show-xs
    .manager-item
      margin-bottom: 15px
      padding: 10px 0
      border-bottom: 1px solid #eee
      .manager-image
        img
          border-radius: 100%
  #staticBackdrop
    .modal-body
      input.link
        border: 1px solid #aaa
        padding: 30px 15px
        margin: 15px 0px
        width: 100%
  .shop-page-name
    font-weight: bold
    font-size: 16px
  .shop-plan-info
    color: #555555
  #linkGenerator
    border: 0px
    width: 80%
  .btn-sm
    padding: 0.3rem
    min-width: 50px
</style>