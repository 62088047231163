<template lang="pug">
.pagelist-page.container-fluid
  .row
    .col-md-8.col-xs-12.mx-auto
      //- 選擇粉專標題區塊
      .connect-title
        h3.connect-title-xs.d-lg-none
          span(v-if="platform === 'facebook'") {{ $t('message.shops_please_select_manager_page') }}
          span(v-if="platform === 'line'") {{ $t('message.shops_please_select_manager_channel') }}
        h1.connect-title-lg.text-center.d-none.d-md-block
          span(v-if="platform === 'facebook'") {{ $t('message.shops_please_select_manager_page') }}
          span(v-if="platform === 'line'") {{ $t('message.shops_please_select_manager_channel') }}
      //- 粉絲專頁清單
      .connect-facebook-pages
        .container-fluid.facebook-page.pt-5(
          v-for="(shop, index) in shops",
          v-if="!shop.is_expire"
        )
          //- 手機版專用
          .d-lg-none
            .row
              .col-12
                .facebook-page-image(v-if="shop.page_id === 0")
                  img(src="../assets/image/default-icon.jpeg", alt="alt")
                .facebook-page-image(v-else)
                  img(
                    v-if="shop.page.platform_type === 1",
                    :src="shop.page.image"
                  )
                  img(
                    v-if="shop.page.platform_type === 2",
                    src="../assets/image/default-icon.jpeg",
                    alt="alt"
                  )
                .ml-3.float-left
                  .facebook-page-name 
                    b {{ shop.name }}
                  .shop-plan-info
                    span(v-if="shop.plan_id === 0") 試用方案
                    span(v-if="shop.plan_id === 1") 單月方案
                    span(v-if="shop.plan_id === 2") 單季方案
                    span(v-if="shop.plan_id === 3") 年度方案
                    span(v-if="shop.is_expire") , 方案已到期
                    span(v-else) , 剩餘 {{ shop.diffday }} 天
              .col-12.text-center.pt-3
                .facebook-page-check
                  button.d-block.btn-default.btn-sm(
                    v-if="!shop.is_expire",
                    @click="selectPage(shop.hash_id, shop.is_expire)"
                  )
                    span {{ $t('message.shops_manager_page_orders') }}
                  button.d-block.btn-error.btn-sm(
                    v-else,
                    @click="openLiveShopLine(1, shop.hash_id, shop.name)"
                  )
                    span {{ $t('message.shops_manager_page_expired') }}
          //- 電腦版專用
          .d-none.d-md-block
            .row
              .col-5.d-flex.align-items-center
                .facebook-page-image(v-if="shop.page_id === 0")
                  img(src="../assets/image/default-icon.jpeg", alt="alt")
                .facebook-page-image(v-else)
                  img(
                    v-if="shop.page.platform_type === 1",
                    :src="shop.page.image"
                  )
                  img(
                    v-if="shop.page.platform_type === 2",
                    src="../assets/image/default-icon.jpeg",
                    alt="alt"
                  )
                .ml-3 
                  .facebook-page-name 
                    b {{ shop.name }}
                  .shop-plan-info
                    span(v-if="shop.plan_id === 0") 試用方案
                    span(v-if="shop.plan_id === 1") 單月方案
                    span(v-if="shop.plan_id === 2") 單季方案
                    span(v-if="shop.plan_id === 3") 年度方案
                    span(v-if="shop.is_expire") , 方案已到期
                    span(v-else) , 剩餘 {{ shop.diffday }} 天
              .col-7.d-flex.align-items-center.justify-content-end
                .facebook-page-check
                  button.btn-default.btn-sm(
                    v-if="!shop.is_expire",
                    @click="selectPage(shop.hash_id, shop.is_expire)"
                  )
                    span {{ $t('message.shops_manager_page_orders') }}
                  button.btn-error.btn-sm(
                    v-else,
                    @click="openLiveShopLine(2, shop.hash_id, shop.name)"
                  )
                    span {{ $t('message.shops_manager_page_expired') }}
  .row
    .col-md-8.col-xs-12.mx-auto
      //- 請求重新授權文字區塊
      .connect-no-pages.text-center
        button.d-block.btn-default-ghost.btn-sm(@click="reAuthFacebook()")
          span(v-if="platform === 'facebook'") {{ $t('message.shops_not_found_page_reset') }}
          span(v-if="platform === 'line'") {{ $t('message.shops_not_found_page_reset_line') }}
</template>
<script>
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio,
    icon,
  },
  data () {
    return {
      shops: [],
      usePage: '',
      platform: 'facebook'
    }
  },
  created () {
    this.getFaceBookShops()
    if (this.$root.$route.params.platform) {
      this.platform = this.$root.$route.params.platform
    }
  },
  methods: {
    // 設定點選隱私權政策
    selectPage (_shopID = '', is_expire = false) {
      if (is_expire) {
        return false
      }
      // 設定所選商店
      this.$root.shopID = _shopID
      // 更新商店資料
      this.$root.getShop()
      // 前往指定頁面
      this.$router.push({ name: 'Orders', params: { shop: _shopID } })
    },
    // 請求重新授權
    reAuthFacebook () {
      if (this.platform === 'facebook') {
        window.location.href = this.$root.web_url + 'users/access/authority'
      }

      if (this.platform === 'line') {
        window.location.href = "/signin/line"
      }
    },
    // 取得粉絲專頁
    getFaceBookShops () {
      this.$http.get('manager/v1/shop/list').then(function (_response) {
        this.$root.console(_response)
        if (_response.body.code === '0000') {
          // 獲得後端回應的資料
          let shops = _response.body.data
          // 對已經解綁的商店進行特殊處理
          shops.forEach(function (_row, _index) {
            if (!_row.page_id) {
              shops[_index].page = {
                "fbpage_id": false,
                "name": false,
                "image": false,
                "platform_type": false
              }
            }
          })
          // 覆蓋商店列表資料
          this.shops = shops
        }

        // 針對 Line 的使用者調整平台文案
        if (this.$root.user.platform === 2) {
          this.platform = 'line'
        }
      })
    },
    // 開啟 LiveShop Line
    openLiveShopLine (_openType = false, _shopHashId = '', _shopName = '') {
      let uri = "https://line.me/R/oaMessage/@liveshop/?" + encodeURI("我的商店[" + _shopHashId + "-" + _shopName + "]已經到期")
      this.$root.console('--> 產製目標網址: ' + uri)

      switch (_openType) {
        // 開啟類型1, 打開指定網址
        case 1:
          window.open(uri)
          break;
        // 開啟類型2, 打開顯示 QRCode
        case 2:
          this.openLiveShopQrcode(_shopName, uri)
          break;
      }
    },
    // 開啟 QRCode 視窗
    openLiveShopQrcode (_shopName, _uri) {
      this.$root.qrcode_view_status = true
      this.$root.code_uri = _uri
      this.$root.code_shopname = _shopName
    }
  }
}
</script>

<style scoped lang="sass">
.connect-title
  padding-top: 5%
  .connect-title-xs
    font-weight: normal
  .connect-title-lg
    font-size: 2em
.connect-facebook-pages
  margin-top: 2%
  margin-bottom: 45px
  .facebook-page
    border-top: 2px solid #EBEDF0
    padding: 1rem
    position: relative
    cursor: pointer
    .facebook-page-image
      float: left
      width: 50px
      height: 50px
    .facebook-page-name-xs
      font-size: 1em
    .facebook-page-name-lg
      font-size: 1.5em
    .facebook-page-name
      font-size: 16px
    .shop-plan-info
      color: #555555
.connect-no-pages
  margin-bottom: 15px
  a
    text-decoration: underline
    color: #000000
.connect-page-button
  margin-bottom: 5%
.float-left
  float: left
</style>
