<template lang="pug">
.worker
  .item-info
    icon(:iconName="'loading'")
    span 正在處理出貨單 ({{ process_step }} / {{ order_cnt }})
</template>

<script>
import icon from '../common/icons.vue'
export default {
  name: "OrderWorker",
  components: {
    icon
  },
  props: ['Worker'],
  data () {
    return {
      workerHashId: "",
      order_cnt: 0,
      process_step: 0,
      status: 0
    }
  },
  created () {
    this.workerHashId = this.Worker

    var vm = this
    this.checkWorkStatus = setInterval(function () { vm.getOrderWorkInfo() }, 1500);
  },
  methods: {
    getOrderWorkInfo () {
      let shopID = this.$root.shopID
      console.log('--> workerHashId: ', this.workerHashId)
      this.$http.get('manager/v1/shop/' + shopID + '/order/worker/' + this.workerHashId).then(function (_response) {
        this.order_cnt = _response.body.data.order_cnt
        this.process_step = _response.body.data.process_step
        this.status = _response.body.data.status

        // 如果操作已完成，就向後端索取檔案
        if (this.status === 2) {
          // 開啟外部視窗
          window.open(this.$root.web_url + "/shop/" + shopID + "/orderworker/" + this.workerHashId)
        }

        // 如果訂單已經處理完畢，就不必再向後端索取更新
        if (this.status === 2 || this.status === 3) {
          // 移除 Worker 陣列
          this.$root.orderWorkers.splice(this.workerHashId, 1);
          // 不再向後端索取更新
          clearInterval(this.checkWorkStatus)
          // 關閉畫面
          this.$root.showDownloadView = false
        }
      })
    }
  },
  destroyed () {
    clearInterval(this.checkWorkStatus)
  }
}
</script>

<style lang="sass" scoped>
.worker
  max-width: 250px
  padding: 15px 20px
  background: #fff
  border-radius: 10px
  box-shadow: 2px 2px 3px #aaa
  margin: 0px auto
  margin-top: 30%
  .item-info
    span
      margin-left: 15px
      font-size: 15px
      font-weight: 500
</style>