<template lang="pug">
.main-page.container-fluid
  .row
    .col-2
    .col-8
      //- 網頁頭部
      .main-header
        //- Logo 到頂端距離
        .d-lg-none
          .pt-5
        //- Logo 區塊
        .header-logo
          img.text-center(src="../assets/image/logo.png")
        //- Logo 到底端距離
        .d-lg-none
          .pt-5
      //- 大版 Banner 區塊
      .main-banner
        img(src="../assets/image/main-banner.png")
      //- 隱私權條款同意方塊
      .main-sign-in-alert
        //- 電腦版
        .d-none.d-md-block
          .form-row(@click="setAgree()")
            uiCheckbox(:boolActive="agreeAlert")
            .label 
              span {{ $t('message.main_agree_policy_by_pc') }}
              a(target="new", href="/privacy-policy") {{ $t('message.service_privacy_policy') }}
              span {{ $t('message.service_and') }}
              a(target="new", href="/terms-service") {{ $t('message.service_terms_service') }}
        //- 手機版
        .d-lg-none
          .form-row
            uiCheckbox(:boolActive="agreeAlert")
            .label 
              span {{ $t('message.main_agree_policy_by_phone') }}
              a(target="new", href="/privacy-policy") {{ $t('message.service_privacy_policy') }}
              span {{ $t('message.service_and') }}
              a(target="new", href="/terms-service") {{ $t('message.service_terms_service') }}
      //- 登入用按鈕
      .p-2
      .main-sign-in-button
        button.btn-line-login(
          v-if="!platform || platform === 'line'",
          type="button",
          @click="loginLine()",
          :disabled="!agreeAlert"
        )
          .overlay
          .icon
            .icon-inner
              img(src="../assets/image/line-b.png")
          span.font-size-xs.d-lg-none {{ $t('message.main_use_line_login') }}
          span.font-size-lg.d-none.d-md-block {{ $t('message.main_use_line_login') }}
        .p-2
        button.btn-facebook-login(
          v-if="!platform || platform === 'facebook'",
          type="button",
          @click="loginFb()",
          :disabled="!agreeAlert"
        )
          .overlay
          .icon
            .icon-inner
              img(
                src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/OF6ddsGKpeB.png"
              )
          span.font-size-xs.d-lg-none {{ $t('message.main_use_facebook_login') }}
          span.font-size-lg.d-none.d-md-block {{ $t('message.main_use_facebook_login') }}
        //- 手機版其他資訊
        .d-lg-none
          .pt-1
          .other-info.text-center {{ $t('message.main_three_step') }}
          .pt-5.footer-other.text-left(v-if="!platform || platform === 'line'")
            .footer-next {{ $t('message.main_line_marketplace_title') }}
            .footer-info
              p {{ $t('message.main_line_marketplace_content_01') }}
              p {{ $t('message.main_line_marketplace_content_02') }}
              p {{ $t('message.main_line_marketplace_content_03') }}
              p 
                span {{ $t('message.main_line_marketplace_content_04_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager"
                ) {{ $t('message.main_line_marketplace_content_04_link') }}
                span {{ $t('message.main_line_marketplace_content_04_02') }}
              p 
                span {{ $t('message.main_line_marketplace_content_05_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager-autoreply"
                ) {{ $t('message.main_line_marketplace_content_05_link') }}
                span {{ $t('message.main_line_marketplace_content_05_02') }}
              p 
                span {{ $t('message.main_line_marketplace_content_06_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager-chatting"
                ) {{ $t('message.main_line_marketplace_content_06_link') }}
                span {{ $t('message.main_line_marketplace_content_06_02') }}
              p {{ $t('message.main_line_marketplace_content_07') }}
              p {{ $t('message.main_line_marketplace_content_08') }}
      //- 電腦版其他資訊
      .main-footer.d-none.d-md-block
        .footer-next {{ $t('message.main_whats_next_title') }}
        .footer-info
          p {{ $t('message.main_whats_next_content_01') }}
          p {{ $t('message.main_whats_next_content_02') }}
        .pt-5.footer-other.text-left(v-if="!platform || platform === 'line'")
          .footer-next {{ $t('message.main_line_marketplace_title') }}
          .footer-info
            p {{ $t('message.main_line_marketplace_content_01') }}
            p {{ $t('message.main_line_marketplace_content_02') }}
            p {{ $t('message.main_line_marketplace_content_03') }}
              p 
                span {{ $t('message.main_line_marketplace_content_04_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager"
                ) {{ $t('message.main_line_marketplace_content_04_link') }}
                span {{ $t('message.main_line_marketplace_content_04_02') }}
              p 
                span {{ $t('message.main_line_marketplace_content_05_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager-autoreply"
                ) {{ $t('message.main_line_marketplace_content_05_link') }}
                span {{ $t('message.main_line_marketplace_content_05_02') }}
              p 
                span {{ $t('message.main_line_marketplace_content_06_01') }}
                a(
                  target="new",
                  href="https://tw.linebiz.com/manual/line-official-account/oa-manager-chatting"
                ) {{ $t('message.main_line_marketplace_content_06_link') }}
                span {{ $t('message.main_line_marketplace_content_06_02') }}
            p {{ $t('message.main_line_marketplace_content_07') }}
            p {{ $t('message.main_line_marketplace_content_08') }}
    .col-2
</template>

<script>
import icon from '../common/icons.vue'
// import Common from '../library/Common'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    icon,
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  data () {
    return {
      platform: '',
      agreeAlert: true
    }
  },
  created () {
    if (this.$root.$route.params.platform) {
      this.platform = this.$root.$route.params.platform
    }
  },
  methods: {
    // 設定點選隱私權政策
    setAgree () {
      this.agreeAlert = !this.agreeAlert
    },
    // 點擊 FaceBook 登入時
    loginFb () {
      // this.$router.push({ name: 'SignIn' })
      window.location.href = this.$root.web_url + 'users/auth/facebook'
    },
    // 點擊 Line 登入時
    loginLine () {
      window.location.href = this.$root.web_url + 'users/auth/line'
    }
  }
}
</script>

<style scoped lang="sass">
.main-page
  .main-header
    position: relative
    margin-top: 5%
    margin-bottom: 55px
    .header-logo
      img
        max-width: 100%
        margin: 0px auto
      .header-title
        font-weight: bold
  .main-banner
    margin: 0px auto
    img
      margin: 0px auto
      max-width: 100%
      max-height: 250px
      margin-bottom: 25px
  .main-sign-in-alert
    text-align: center
    margin-bottom: 15px
  .main-sign-in-button
    text-align: center
    margin-bottom: 35px
    .font-size-xs
      font-size: .8em
    .font-size-lg
      font-size: 1em
    .other-info
      padding: 3px
      font-weight: bold
  .main-footer
    width: 54%
    margin-left: 23%
    margin-bottom: 10%
    text-align: left
  .footer-next
    font-weight: bold
  .footer-info
    p
      margin: 0px
</style>
