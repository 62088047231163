import Vue from 'vue'
import $ from 'jquery'

var project
export default class Common {

  constructor(_vm) {
    project = _vm
    project.console('-> 載入了 Common Lib')
  }

  //- ==================
  goUrl (_url = '') {
    // this.loadView = true
    project.$router.go(this.$router.push({ path: _url }))
  }

  // 格式化數字
  formatNumber (num = 0, _format = 2) {
    if (!num) {
      return 0.00
    } else {
      Number.prototype.numberFormat = function (c, d, t) {
        var n = this,
          c = isNaN(c = Math.abs(c)) ? 2 : c,
          d = d == undefined ? "." : d,
          t = t == undefined ? "," : t,
          s = n < 0 ? "-" : "",
          i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
          j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
      }

      var tempNumber = parseFloat(num)
      var number = tempNumber.numberFormat(_format, '.', ',')
      // project.console('---> formatNumber: ' + num + ' to ' + number)
      return number
    }
  }

  //- 設定 Storage 資料
  setData (_key = '', _val = '') {
    Vue.localStorage.set(_key, _val)
    return true
  }

  //- 取得 Storage 資料
  getData (_key = '') {
    return Vue.localStorage.get(_key)
  }
}
