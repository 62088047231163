<template lang="pug">
.liff-loading
  img.loading-img(src="@/assets/svgs/loading.svg")
</template>

<script>
import $ from 'jquery'
export default {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  created () {
  }
}
</script>

<style lang="sass" scoped>
.liff-loading
  img
    max-height: 80px
    margin: 0px auto
    margin-top: 25%
</style>