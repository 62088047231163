<template lang="pug">
.liff-info
  Loading(v-if="loading")
  Login(
    v-if="code === 'MemberBind' && lineBaseId",
    :lineBaseId="lineBaseId",
    :userId="userId",
    :userName="userName",
    :linkToken="linkToken",
    :nonce="nonce"
  )
  Product(
    v-if="product.hash_id",
    :lineBaseId="lineBaseId",
    :isBind="isBind",
    :userId="userId",
    :userIdType="userIdType",
    :userName="userName",
    :product="product",
    :audienceId="platform.profile.userId",
    :memberId="memberId",
    :order="order",
    :detail="detail",
    :platformId="platformId",
    :paymentSwitch="payment_switch",
    :shipSwitch="ship_switch",
    :payments="payments",
    :ships="ships",
    @continueBuy="closeLiffAPP",
    @goChannel="openInAppUri",
    @goSetPostShop="openInAppUri"
  )
  .ligg-infomation(v-if="debug")
    h2 Liff 實驗用
    h3 目前網址: {{ currentLocation }}
    h3 操作類型: {{ code }}
    h3 目前使用平台: {{ platform.os }}
    h3 目前使用語言: {{ platform.language }}
    h3 是否Liff Webview: {{ platform.isInClient }}
    h3 是否已經登入: {{ platform.isLoggedIn }}
    h3 是否已經綁定: {{ isBind }}
    h3 用戶ID: {{ platform.profile.userId }}
    hr
    .option-rang
      .option-info(v-if="platform.input.type === 'utou'") 
        h3 操作來源入口: 一對一聊天室
        h3 一對一聊天室ID: {{ platform.input.utouId }}

      .option-info(v-else-if="platform.input.type === 'group'") 
        h3 操作來源入口: Line 群組
        h3 Line 群組ID: {{ platform.input.groupId }}

      .option-info(v-else-if="platform.input.type === 'room'") 
        h3 操作來源入口: Line 聊天室
        h3 Line 聊天室ID: {{ platform.input.roomId }}

      .option-info(v-else-if="platform.input.type === 'external'") 
        h3 操作來源入口: 外部瀏覽器

      .option-info(v-else-if="platform.input.type === 'square_chat'") 
        h3 操作來源入口: Line 社群
        h3 Line 社群ID: {{ platform.input.squareId }}
        h3 Line 社群聊天ID: {{ platform.input.squareChatId }}
        h3 Line 社群用戶ID: {{ platform.input.squareMemberId }}

      .option-info(v-else-if="platform.input.type === 'none'") 
        h3 操作來源入口: 不知道哪裡來的(非一對一聊天室、Line 群組、Line 聊天室)
      .option-info(v-else)
        h4 input: {{ platform.input }}
      br
      br
      hr
      .other-info
        h3 userId:
          span {{ userId }}
        br
        h3 groupId:
          span {{ groupId }}
        br
        h3 shopId:
          span {{ shopId }}
        br
        h3 roomId:
          span {{ roomId }}
        br
        h3 userName:
          span {{ userName }}
    hr
    .option-rang(v-if="code === 'MemberBind'")
      h2 需要綁定的資訊
      h3 帶入的群組ID: {{ groupId }}
      h3 帶入的用戶ID: {{ userId }}
    .option-rang(v-if="code === 'OrderProduct'")
      h2 需要處理的商品資訊
      h3 規格一
      h3 規格二
</template>

<script>
import $ from 'jquery'
import Loading from './Loading'
import Login from './Login'
import Product from './Product'
export default {
  name: 'AWeb',
  components: {
    Loading,
    Login,
    Product
  },
  props: {
  },
  watch: {
    async isBind (_status) {
      if (_status) {
        // 辨認處理方法
        switch (this.code) {
          // 商品加入購物車
          case 'JoinProduct':
            await this.getCurrentOrder()
            await this.getProductInfo()
            break;

          // 其他未定義的操作
          default:
            alert("未知的操作")
            this.closeLiffAPP()
            break;
        }
      }
    }
  },
  data () {
    return {
      processModel: false,
      code: false,
      userId: false,
      channelId: false,
      groupId: false,
      shopId: false,
      memberId: false,
      lineBaseId: false,
      userName: false,
      memberId: false,
      linkToken: false,
      authToken: false,
      nonce: false,
      productId: false,
      product: false,
      isBind: false,
      loading: true,
      debug: false,
      order: false,
      detail: false,
      platformId: false,
      currentLocation: false,
      platform: {
        os: false,
        liffId: false,
        language: false,
        isInClient: false,
        isLoggedIn: false,
        profile: false,
        input: false
      },
      payment_switch: false,
      ship_switch: false,
      payments: [],
      ships: [],
      userIdType: false,
    }
  },
  created () {
    this.$root.console('--> 進入 WebView 相關頁面');

    // 更換網頁標題
    $('title').html("LiveShop | Line 群組結帳小幫手")

    // 開始處理 Liff 應用
    this.processInitLiffSDK()
  },
  methods: {
    //   開始載入 Liff SDK
    async processInitLiffSDK () {
      console.log('=> routeName: ', this.$route.name)

      if (this.$route.name === 'Link-AddToCart') {
        this.processModel = 'link'
      }

      if (this.$route.name === 'Liff-AddToCart') {
        this.processModel = 'liff'
      }

      // 獲取 URL 參數
      await this.processCurrentUriInfo()
      // 處理主要方法
      await this.processMainFunction()
    },
    // 初始化 Line Liff SDK
    async initLineLiffSDK () {
      var vm = this
      this.$root.console('--> 初始化 Line Liff SDK...');
      window.liff.init({ liffId: vm.$root.liffId }).then(() => {
        this.$root.console('')
        this.$root.console('===================')
        this.$root.console('==> Liff 應用程序載入成功...')
        this.$root.console('==> LIFF INFO: ', window.liff)
        this.$root.console('==> RUN OS: ' + window.liff.getOS())
        this.$root.console('==> RUN Language: ' + window.liff.getLanguage())
        this.$root.console('==> RUN IN LINE: ' + window.liff.isInClient())
        this.$root.console('==> User Login Status: ' + window.liff.isLoggedIn())

        vm.platform.os = window.liff.getOS()
        vm.platform.liffId = vm.$root.liffId
        vm.platform.language = window.liff.getLanguage()
        vm.platform.isInClient = window.liff.isInClient()
        vm.platform.isLoggedIn = window.liff.isLoggedIn()

        // 強制請求登入
        if (!vm.platform.isLoggedIn) {
          window.liff.login({ redirectUri: window.location.href })
        }

        // 開始獲取用戶資料
        vm.getUserProfile()

      }).catch((err) => {
        this.$root.console('==> LIFF INIT ERROR: ' + err)
      })
    },
    // 用商品ID換回商店資訊
    async getShopByProduct () {
      await this.$http.get('liff/v1/baseid/' + this.productId).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          this.closeLiffAPP()
          return
        }

        let response = _response.body.data

        // 處理獲取的 Line Base ID
        this.lineBaseId = response.base_id
        this.payment_switch = response.payment_switch
        this.ship_switch = response.ship_switch
        this.payments = response.payments
        this.ships = response.ships

      }, function (_response) {
        alert(_response.body.data)
        this.closeLiffAPP()
      })
    },
    // 用商店ID換回商店資料
    async getShopByShopID () {
      await this.$http.get('liff/v1/shop/' + this.shopId).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          this.closeLiffAPP()
          return
        }

        let response = _response.body.data
        console.log('--> Shop: ', response)

        // 處理獲取的 Line Base ID
        this.lineBaseId = response.base_id
        // this.payment_switch = response.payment_switch
        // this.ship_switch = response.ship_switch
        // this.payments = response.payments
        // this.ships = response.ships

      }, function (_response) {
        alert(_response.body.data)
        this.closeLiffAPP()
      })
    },
    // 獲取用戶資料
    async getUserProfile () {
      var vm = this
      await window.liff.getProfile()
        .then(async profile => {
          vm.platform.profile = profile
          this.$root.console('')
          this.$root.console('===================')
          this.$root.console('==> 獲取 Line User Profile 成功...')
          this.$root.console('==> User Profile: ' + vm.platform.profile)
          this.$root.console('--> Line User displayName: ' + vm.platform.profile.displayName)
          this.$root.console('--> Line User ID: ' + vm.platform.profile.userId)
          this.$root.console('--> Line User pictureUrl: ' + vm.platform.profile.pictureUrl)

          // 獲得操作入口資訊
          vm.platform.input = window.liff.getContext()

          // 處理當前網址資訊
          await vm.processCurrentUriInfo()

          // 處理主要方法
          await vm.processMainFunction()
        })
        .catch((err) => {
          this.$root.console('error', err)
        })
    },
    // 處理目前的用戶資料
    processCurrentUriInfo () {
      // 獲取當前網址
      this.currentLocation = window.location
      var url = new URL(this.currentLocation);

      let liffreferrer = url.searchParams.get("liff.referrer")
      let liffstate = url.searchParams.get("liff.state")

      if (liffstate) {
        let refererUri = this.$root.web_url + decodeURI(liffstate)
        console.log('==> refererUri: ', refererUri)
        url = new URL(refererUri)
      }

      // 獲取帶入的操作類型
      this.code = url.searchParams.get("code");

      // 獲取帶入的ID資訊
      this.userId = url.searchParams.get("userId")
      this.authToken = url.searchParams.get("authToken")
      this.groupId = url.searchParams.get("groupId")
      this.roomId = url.searchParams.get("roomId")
      this.shopId = url.searchParams.get("shopId")
      this.userName = url.searchParams.get("userName")
      this.memberId = url.searchParams.get("memberId")
      this.linkToken = url.searchParams.get("linkToken")
      this.nonce = url.searchParams.get("nonce")
      this.productId = url.searchParams.get("p")
    },
    // 處理執行方法
    async processMainFunction () {
      // 辨認處理方法
      switch (this.code) {
        // 會員綁定
        case 'MemberBind':
          await this.getShopByShopID()
          if (this.$route.name === 'Liff-AddToCart') {
            await this.processMemberBind()
          } else {
            this.loading = false
          }
          break;

        // 商品加入購物車
        case 'JoinProduct':
          // 如果帶有 userHash
          if (this.authToken) {
            console.log('--> 後端直接帶入 authToken...')
            // 寫入 Cookie
            await this.$root.setCookieData('user-hash', this.authToken)
            // 重刷頁面
            await this.openInAppUri(this.$root.web_url + "link?code=JoinProduct&p=" + this.productId)
            // 結束本次作業
            return
          }

          await this.getShopByProduct()
          await this.confirmMemberBind()
          break;

        // 請求前往 Account Link
        case 'AccountLink':
          await this.openInAppUri('https://access.line.me/dialog/bot/accountLink?linkToken=' + this.linkToken + '&nonce=' + this.nonce)
          break;

        // 請求發送訊息
        case 'SendMsg':
          await this.sendMessages()
          break;

        // 其他未定義的操作
        default:
          alert("未知的操作")
          this.closeLiffAPP()
          break;
      }
    },
    // 確認是否已經綁定
    async confirmMemberBind () {
      // 驗證登入是否正常
      let needAutoBind = true
      let targetUserId = false

      // 處理 Cookie 的參數
      let userHash = this.$root.getCookie('user-hash')
      console.log('--> 檢測 UserHash: ', userHash)
      if (userHash) {
        needAutoBind = false
        this.userIdType = 'audience-id'
        targetUserId = window.atob(userHash)
      }

      // 處理 Line Login 傳入的資料
      if (this.userId) {
        this.userIdType = 'line-id'
        targetUserId = this.userId
      }

      // 如果都沒有用戶資料
      if (!targetUserId) {
        this.loading = false
        await this.getProductInfo()
        return
      }

      // 向後端確認綁定資訊
      await this.$http.post('liff/v1/audience/confirm', {
        code: this.code,
        utype: this.userIdType,
        productId: this.productId,
        userId: targetUserId
      }).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          this.closeLiffAPP()
          return
        }

        // 可以開放加入購物車
        this.isBind = true
        this.memberId = _response.body.data
        this.platformId = _response.body.shop
        this.userId = targetUserId
      }, async function (_response) {
        if (needAutoBind) {
          // 發現有狀況，詢問是否前往綁定
          if (confirm(_response.body.data + "\r\n" + "您需要現在前往綁定嗎?")) {
            // 組合綁定的 URI
            let BindUri = "https://line.me/R/oaMessage/" + this.lineBaseId + "/?Hi%20%E5%B0%8F%E5%B9%AB%E6%89%8B%EF%BC%8C%E6%88%91%E8%A6%81%E7%B6%81%E5%AE%9A%E5%B8%B3%E8%99%9F"
            // 前往指定的官方帳號目標
            await this.openInAppUri(BindUri, false)
          }

          // 不做任何動作，關閉 Liff 視窗
          else {
            this.closeLiffAPP()
          }
        }
      })
    },
    // 請求綁定用戶資料
    async processMemberBind () {
      if (this.userName !== this.platform.profile.displayName) {
        alert("這個綁定鏈結已經失效囉")
        this.closeLiffAPP()
        return
      }

      // 向後端通報綁定資訊
      await this.$http.post('liff/v1/audience/bind', {
        code: this.code,
        shopId: this.shopId,
        userId: this.userId,
        roomId: this.roomId,
        groupId: this.groupId,
        audienceId: this.platform.profile.userId,
      }).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          this.closeLiffAPP()
          return
        }

        alert("綁定成功")
        this.closeLiffAPP()
      })
    },
    // 請求獲得訂單資料
    async getCurrentOrder () {
      await this.$http.get("liff/v1/order/member/" + this.memberId + '/current').then(async function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          return
        }

        // 成功獲取 Order 資料
        this.order = await _response.body.data
      })
    },
    // 請求取得商品資料
    async getProductInfo () {
      // 取得商品資料
      var vm = this
      await this.$http.get('liff/v1/product/' + this.productId).then(function (_response) {
        if (_response.body.code !== '0000') {
          alert(_response.body.data)
          this.closeLiffAPP()
          return
        }

        // 處理獲得的商品資料
        this.product = _response.body.data

        // 找出目前已經選購的資訊
        if (this.order.details.length > 0) {
          this.order.details.forEach(function (_item, _index) {
            if (_item.name === vm.product.name) {
              vm.detail = _item
            }
          })
        }

        // 關閉載入中畫面
        this.loading = false
      }, function (_response) {
        alert(_response.body.data)
        this.closeLiffAPP()
      })
    },
    // 開啟 InAPP WebView
    async openInAppUri (_uri, _autoClose = true) {
      window.location.href = _uri
    },
    // 請求發送訊息
    async sendMessages () {
      // 請求發送訊息
      window.liff.sendMessages([{
        type: 'text',
        text: 'Hi 小幫手，我要綁定帳號哦！'
      }])

      // 關閉 Liff 
      this.closeLiffAPP()
    },
    // 關閉 Liff
    closeLiffAPP () {
      // 導引前往 Line@ 帳號
      window.location.href = 'https://line.me/R/ti/p/' + this.lineBaseId
    }
  }
}
</script>

<style lang="sass" scoped>
.liff-info
  width: 100%
  .liff-loading
    img
      max-height: 80px
      margin: 0px auto
      margin-top: 25%
</style>