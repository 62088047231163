import Vue from 'vue'
import Vuex from 'vuex'

// modules
import ui from './modules/ui.js'
import user from './modules/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ui,
    user
  },
  // 嚴格模式，禁止直接修改 state
  // 必須經過 mutations 才能修改 state 的內容
  strict: true
})
