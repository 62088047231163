<template lang="pug">
.page.order-page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-column.align-items-stretch.flex-nowrap
        .section-body
          .container-fluid
            .p-2
            .row
              .col-md-6
                nav(aria-label="breadcrumb")
                  ol.breadcrumb
                    li.breadcrumb-item
                      a.link-underlined(@click="goOrderListPage(order.status)") 
                        span(v-if="order.status === 0") {{ $t('message.orders_order_type_waiting') }}
                        span(v-if="order.status === 1") {{ $t('message.orders_order_type_process') }}
                        span(v-if="order.status === 2") {{ $t('message.orders_order_type_waitship') }}
                        span(v-if="order.status === 3") {{ $t('message.orders_order_type_shiped') }}
                        span(v-if="order.status === 4") {{ $t('message.orders_order_type_canceled') }}
                    li.breadcrumb-item.active(aria-current="page") {{ '#' + order.number }}
              .col-md-6
                .text-right
                  .d-none.d-md-block
                    button.btn.btn-default-outline(@click="updateOrder") {{ $t('message.orders_order_save_order') }}
                  .d-lg-none
                    button.d-block.btn.btn-default-outline(
                      @click="updateOrder"
                    ) {{ $t('message.orders_order_save_order') }}
            .row
              .col-md-12.d-none.d-lg-flex(
                v-if="order.status === 2 || order.status === 3"
              )
                //- 匯出功能
                .col-12
                  .text-right
                    button.button.btn-default-ghost(
                      type="button",
                      @click="openOrderExport()"
                    )
                      icon(:iconName="'iconLiveShopPrinter'")
                      span.ml-2 {{ $t('message.orders_order_printer_page') }}
              .col-md-6.col-xs-12
                .row
                  //- 購買人資訊
                  .col-12
                    .p-2
                    .card.order-info
                      .card-body
                        .row
                          .col-7
                            h6 {{ $t('message.order_info_member_title') }}
                        .p-1
                        .inpu-group.row
                          .col-4.pt-2 {{ $t('message.order_info_audience_title') }}
                          .col-7.pt-2 
                            .d-none.d-md-block
                              a(
                                v-if="$root.shop.page.platform_type === 1",
                                target="_new",
                                :href="'https://www.facebook.com/' + $root.shop.page.fbpage_id + '/inbox/' + order.member.platform_user_id"
                              ) 
                                img.message-icon(
                                  v-if="order.member.platform_type === 1",
                                  src="../assets/image/messenger.png"
                                )
                                img.message-icon(
                                  v-if="order.member.platform_type === 3",
                                  src="../assets/image/instagram.png"
                                )
                                span.message-uname {{ order.member.full_name }}
                              span.message-uname(v-else) {{ order.member.full_name }}
                            .d-lg-none
                              span.message-uname {{ order.member.full_name }}
                  //- 收件資料
                  .col-12
                    .p-2
                    .card.order-info
                      .card-body
                        .row
                          .col-7
                            h6 {{ $t('message.inbox_order_member_info_title') }}
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 {{ $t('message.inbox_order_member_name') }}
                          input.input.col-8(
                            v-if="order.status < 3",
                            v-model="order.recipient_name"
                          )
                          .col-8.pt-2(v-else) {{ order.recipient_name }}
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 {{ $t('message.inbox_order_member_phone') }}
                          input.input.col-8(
                            type="tel",
                            v-if="order.status < 3",
                            v-model="order.recipient_phone",
                            @keydown="$root.changePhone"
                          )
                          .col-8.pt-2(v-else) {{ order.recipient_phone }}
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 {{ $t('message.inbox_order_member_zipcode') }}
                          input.input.col-8(
                            v-if="order.status < 3 && order.post_method < 3",
                            v-model="order.recipient_zipcode"
                          )
                          .col-8.pt-2(v-else) {{ order.recipient_zipcode }}
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 {{ $t('message.inbox_order_member_address') }}
                          input.input.col-8(
                            v-if="order.status < 3 && order.post_method < 3",
                            v-model="order.recipient_address"
                          )
                          .col-8.pt-2(v-else) {{ order.recipient_address }}
                  //- 訂單內容 <電腦版專用>
                  .col-12.d-none.d-md-block
                    .p-2
                    .card
                      .card-body
                        h6 {{ $t('message.inbox_order_info_title') }}
                        .p-2
                        .table-responsive
                          table.table
                            thead
                              th 
                              th.text-center {{ $t('message.inbox_order_detail_name') }}
                              th.text-center {{ $t('message.inbox_order_detail_count') }}
                              th.text-center {{ $t('message.inbox_order_detail_price') }}
                            tbody
                              tr(v-for="(detail, index) in order.details")
                                td
                                  .remove(
                                    v-if="order.status < 2",
                                    @click="removeItem(index)"
                                  )
                                    icon(:iconName="'lightTimes'")
                                td
                                  .input-group
                                    input.input(
                                      v-if="order.status < 2",
                                      list="pitemList01",
                                      :placeholder="$t('message.inbox_order_detail_name')",
                                      v-model="detail.name"
                                    )
                                    input.input(
                                      v-else,
                                      disabled,
                                      list="pitemList01",
                                      :placeholder="$t('message.inbox_order_detail_name')",
                                      v-model="detail.name"
                                    )
                                    datalist#pitemList01
                                      option(
                                        v-for="(product, index) in products",
                                        :value="product.name"
                                      ) {{ product.name }}
                                td
                                  .input-group
                                    select.select.form-control(
                                      v-if="order.status < 2",
                                      v-model="detail.quy"
                                    )
                                      option(v-for="n in 15", :value="n") {{ n }}
                                    input.input(
                                      v-else,
                                      disabled,
                                      :value="detail.quy"
                                    )
                                td
                                  .input-group
                                    input.input(
                                      v-if="order.status < 2",
                                      :placeholder="$t('message.inbox_order_detail_price')",
                                      type="number",
                                      v-model="detail.price",
                                      @input="$root.changeVal"
                                    )
                                    input.input(
                                      v-else,
                                      disabled,
                                      :value="detail.price"
                                    )
                              tr(v-if="order.status < 2")
                                td(colspan="4")
                                  button.button.btn-default-ghost.btn-sm(
                                    @click="addItem()"
                                  )
                                    icon(:iconName="'lightPlus'")
                                    span {{ $t('message.inbox_order_detail_add') }}
                              tr
                                td(colspan="3") {{ $t('message.inbox_order_post_cost') }}
                                td.text-right 
                                  input.input.col-8(
                                    v-if="order.status < 2",
                                    type="number",
                                    v-model="order.ship_cost"
                                  )
                                  input.input(
                                    v-else,
                                    disabled,
                                    :value="order.ship_cost"
                                  )
                              tr
                                td(colspan="3") {{ $t('message.inbox_order_total_amt') }}
                                td.text-right {{ $root.shop.currency }} ${{ order.total }}
                  //- 訂單內容 <手機版專用>
                  .col-12.d-lg-none
                    .p-2
                    .card
                      .card-body
                        .row
                          .col-5.pt-2
                            h6 {{ $t('message.inbox_order_info_title') }}
                        .p-2
                        .order-detail(v-for="(detail, index) in order.details")
                          .detail-id
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.common_table_rowid') }}
                              .col-5.pt-2 {{ index + 1 }}
                              .col-3.text-right
                                .remove(@click="removeItem(index)")
                                  icon(:iconName="'lightTimes'")
                          .detail-name.pt-2
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.inbox_order_detail_name') }}
                              input.input.col-8(
                                v-if="order.status < 2",
                                list="pitemList01",
                                :placeholder="$t('message.inbox_order_detail_name')",
                                v-model="detail.name"
                              )
                              input.input.col-8(
                                v-else,
                                disabled,
                                list="pitemList01",
                                :placeholder="$t('message.inbox_order_detail_name')",
                                v-model="detail.name"
                              )
                              datalist#pitemList02
                                option(
                                  v-for="(product, index) in products",
                                  :value="product.name"
                                ) {{ product.name }}
                          .detail-quy.pt-2
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.inbox_order_detail_count') }}
                              select.select.form-control.col-8(
                                v-if="order.status < 2",
                                v-model="detail.quy"
                              )
                                option(v-for="n in 15", :value="n") {{ n }}
                              input.input.col-8(
                                v-else,
                                disabled,
                                :value="detail.quy"
                              )
                          .detail-price.pt-2
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.inbox_order_detail_price') }}
                              input.input.col-8(
                                v-if="order.status < 2",
                                :placeholder="$t('message.inbox_order_detail_price')",
                                type="number",
                                v-model="detail.price",
                                @input="$root.changeVal"
                              )
                              input.input.col-8(
                                v-else,
                                disabled,
                                :value="detail.price"
                              )
                          hr
                        .order-additem.text-center(v-if="order.status < 2")
                          button.button.btn-default-ghost.btn-sm(
                            @click="addItem()"
                          )
                            icon(:iconName="'lightPlus'")
                            span {{ $t('message.inbox_order_detail_add') }}
                        .p-2
                        .order-amtinfo
                          .detail-shipcost
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.inbox_order_post_cost') }}
                              input.input.col-8(
                                v-if="order.status < 2",
                                type="number",
                                v-model="order.ship_cost"
                              )
                              input.input.col-8(
                                v-else,
                                disabled,
                                :value="order.ship_cost"
                              )
                          .detail-total
                            .detail-item.row
                              .col-3.pt-2 {{ $t('message.inbox_order_total_amt') }}
                              .col-9.pt-2 {{ $root.shop.currency }} ${{ order.total }}
              .col-md-6.col-xs-12
                .row
                  //- 付款與出貨
                  .col-12
                    .p-2
                    .card
                      .card-body
                        h6 {{ $t('message.order_info_payment_and_ship_title') }}
                        .p-1
                        .row
                          .col-3.pt-2 {{ $t('message.orders_order_table_title_10') }}
                          .col-8
                            .d-lg-none
                              .show-info(
                                v-if="order.status < 2 && order.post_method < 3"
                              )
                                .redio-option(@click="setPost(1)")
                                  uiRadio(
                                    :boolActive="order.post_method === 1"
                                  )
                                  .label {{ $t('message.orders_order_post_tohome') }}
                                .redio-option.pt-1(@click="setPost(2)")
                                  uiRadio(
                                    :boolActive="order.post_method === 2"
                                  )
                                  .label {{ $t('message.orders_order_post_tostore') }}
                                //- .redio-option.pt-1(@click="setPost(3)")
                                  uiRadio(
                                    :boolActive="order.post_method === 3"
                                  )
                                  .label {{ $t('message.orders_order_post_to_FAMIC2C') }}
                                //- .redio-option.pt-1(@click="setPost(4)")
                                  uiRadio(
                                    :boolActive="order.post_method === 4"
                                  )
                                  .label {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                                //- .redio-option.pt-1(@click="setPost(5)")
                                  uiRadio(
                                    :boolActive="order.post_method === 5"
                                  )
                                  .label {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                              .show-info(v-else)
                                span(v-if="order.post_method === 1") {{ $t('message.orders_order_post_tohome') }}
                                span(v-if="order.post_method === 2") {{ $t('message.orders_order_post_tostore') }}
                                span(v-if="order.post_method === 3") {{ $t('message.orders_order_post_to_FAMIC2C') }}
                                span(v-if="order.post_method === 4") {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                                span(v-if="order.post_method === 5") {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                            .d-none.d-md-block
                              .show-info(
                                v-if="order.status < 2 && order.post_method < 3"
                              )
                                .redio-option.form-row(@click="setPost(1)")
                                  uiRadio(
                                    :boolActive="order.post_method === 1"
                                  )
                                  .label {{ $t('message.orders_order_post_tohome') }}
                                .redio-option.form-row(@click="setPost(2)")
                                  uiRadio(
                                    :boolActive="order.post_method === 2"
                                  )
                                  .label {{ $t('message.orders_order_post_tostore') }}
                                //- .redio-option.form-row(@click="setPost(3)")
                                  uiRadio(
                                    :boolActive="order.post_method === 3"
                                  )
                                  .label {{ $t('message.orders_order_post_to_FAMIC2C') }}
                                //- .redio-option.form-row(@click="setPost(4)")
                                  uiRadio(
                                    :boolActive="order.post_method === 4"
                                  )
                                  .label {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                                //- .redio-option.form-row(@click="setPost(5)")
                                  uiRadio(
                                    :boolActive="order.post_method === 5"
                                  )
                                  .label {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                              .show-info(v-else)
                                span(v-if="order.post_method === 1") {{ $t('message.orders_order_post_tohome') }}
                                span(v-if="order.post_method === 2") {{ $t('message.orders_order_post_tostore') }}
                                span(v-if="order.post_method === 3") {{ $t('message.orders_order_post_to_FAMIC2C') }}
                                span(v-if="order.post_method === 4") {{ $t('message.orders_order_post_to_UNIMARTC2C') }}
                                span(v-if="order.post_method === 5") {{ $t('message.orders_order_post_to_HILIFEC2C') }}
                        .p-2
                        .row
                          .col-3.pt-2 {{ $t('message.orders_order_table_title_06') }}
                          .col-8
                            .d-lg-none
                              .show-info(v-if="order.status < 2")
                                .redio-option(@click="setPayment(1)")
                                  uiRadio(:boolActive="order.pay_method === 1")
                                  .label {{ $t('message.orders_order_payment_cash') }}
                                .redio-option.pt-1(@click="setPayment(2)")
                                  uiRadio(:boolActive="order.pay_method === 2")
                                  .label {{ $t('message.orders_order_payment_atm') }}
                                .redio-option.pt-1(@click="setPayment(3)")
                                  uiRadio(:boolActive="order.pay_method === 3")
                                  .label {{ $t('message.orders_order_payment_linemoney') }}
                                .redio-option.pt-1(@click="setPayment(4)")
                                  uiRadio(:boolActive="order.pay_method === 4")
                                  .label {{ $t('message.orders_order_payment_linepay') }}
                                .redio-option.pt-1(@click="setPayment(5)")
                                  uiRadio(:boolActive="order.pay_method === 5")
                                  .label {{ $t('message.orders_order_payment_ecpay') }}
                              .show-info(v-else)
                                span(v-if="order.pay_method === 1") {{ $t('message.orders_order_payment_cash') }}
                                span(v-if="order.pay_method === 2") {{ $t('message.orders_order_payment_atm') }}
                                span(v-if="order.pay_method === 3") {{ $t('message.orders_order_payment_linemoney') }}
                                span(v-if="order.pay_method === 4") {{ $t('message.orders_order_payment_linepay') }}
                                span(v-if="order.pay_method === 5") {{ $t('message.orders_order_payment_ecpay') }}
                            .d-none.d-md-block
                              .show-info(v-if="order.status < 2")
                                .redio-option.form-row(@click="setPayment(1)")
                                  uiRadio(:boolActive="order.pay_method === 1")
                                  .label {{ $t('message.orders_order_payment_cash') }}
                                .redio-option.form-row(@click="setPayment(2)")
                                  uiRadio(:boolActive="order.pay_method === 2")
                                  .label {{ $t('message.orders_order_payment_atm') }}
                                .redio-option.form-row(
                                  v-if="order.payment.linepay",
                                  @click="setPayment(3)"
                                )
                                  uiRadio(:boolActive="order.pay_method === 3")
                                  .label {{ $t('message.orders_order_payment_linemoney') }}
                                .redio-option.form-row(
                                  v-if="order.payment.linepay_id",
                                  @click="setPayment(4)"
                                )
                                  uiRadio(:boolActive="order.pay_method === 4")
                                  .label {{ $t('message.orders_order_payment_linepay') }}
                                .redio-option.form-row(
                                  v-if="order.payment.ecpay_hashkey",
                                  @click="setPayment(5)"
                                )
                                  uiRadio(:boolActive="order.pay_method === 5")
                                  .label {{ $t('message.orders_order_payment_ecpay') }}
                              .show-info(v-else)
                                span(v-if="order.pay_method === 1") {{ $t('message.orders_order_payment_cash') }}
                                span(v-if="order.pay_method === 2") {{ $t('message.orders_order_payment_atm') }}
                                span(v-if="order.pay_method === 3") {{ $t('message.orders_order_payment_linemoney') }}
                                span(v-if="order.pay_method === 4") {{ $t('message.orders_order_payment_linepay') }}
                                span(v-if="order.pay_method === 5") {{ $t('message.orders_order_payment_ecpay') }}
                        .p-2
                        .row
                          .col-3.pt-2 {{ $t('message.orders_order_table_title_12') }}
                          .col-8
                            .d-lg-none
                              .show-info(
                                v-if="order.status > 0 && order.status < 4"
                              )
                                .redio-option(@click="setPayStatus(0)")
                                  uiRadio(:boolActive="order.pay_status === 0")
                                  .label {{ $t('message.orders_order_not_pay') }}
                                .redio-option.pt-1(@click="setPayStatus(1)")
                                  uiRadio(:boolActive="order.pay_status === 1")
                                  .label {{ $t('message.orders_order_pay') }}
                              .show-info(v-else)
                                span(v-if="order.pay_status === 0") {{ $t('message.orders_order_not_pay') }}
                                span(v-if="order.pay_status === 1") {{ $t('message.orders_order_pay') }}
                            .d-none.d-md-block
                              .show-info(
                                v-if="order.status > 0 && order.status < 4"
                              )
                                .redio-option.form-row(
                                  @click="setPayStatus(0)"
                                )
                                  uiRadio(:boolActive="order.pay_status === 0")
                                  .label {{ $t('message.orders_order_not_pay') }}
                                .redio-option.form-row(
                                  @click="setPayStatus(1)"
                                )
                                  uiRadio(:boolActive="order.pay_status === 1")
                                  .label {{ $t('message.orders_order_pay') }}
                              .show-info(v-else)
                                span(v-if="order.pay_status === 0") {{ $t('message.orders_order_not_pay') }}
                                span(v-if="order.pay_status === 1") {{ $t('message.orders_order_pay') }}
                        .p-2
                        .row
                          .col-3.pt-2 {{ $t('message.orders_order_table_title_11') }}
                          .col-8
                            .show-info
                              span(v-if="order.payed_at") {{ order.payed_at }}
                              span(v-else) 尚未付款
                        //- .p-1
                        //- .inpu-group.row
                          .col-3.pt-2 {{ $t('message.order_info_ship_method') }}
                          input.input.col-8(
                            v-if="order.status < 3",
                            :placeholder="$t('message.order_info_ship_text')",
                            v-model="order.ship_method"
                          )
                          input.input.col-8(
                            v-else,
                            disabled,
                            :placeholder="$t('message.order_info_ship_text')",
                            v-model="order.ship_method"
                          )
                        .p-1
                        .inpu-group.row(v-if="order.status > 1")
                          .col-3.pt-2 {{ $t('message.order_info_ship_code') }}
                          input#shipcode.input.col-8(
                            v-if="order.status < 3",
                            :placeholder="$t('message.order_info_ship_code_input')",
                            v-model="order.ship_code"
                          )
                          input#shipcode.input.col-8(
                            v-else,
                            disabled,
                            :placeholder="$t('message.order_info_ship_code_input')",
                            v-model="order.ship_code"
                          )
                        .ship-data(v-if="order.shiped_at")
                          .detail-item.row
                            .col-3.pt-2 {{ $t('message.order_info_ship_at') }}
                            .col-8.pt-2 {{ order.shiped_at }}

                  //- 訂單付款 ATM 帳號
                  .col-12(v-if="order.pay_method === 2")
                    .p-2
                    .card
                      .card-body
                        .row
                          .col-7
                            h6 訂單收款 ATM 帳號
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 收款銀行
                          //- input.input.col-8
                          .col-8.pt-2
                            span(v-if="order.atm_bank") {{ order.atm_bank }}
                            span(v-else) -
                        .p-1
                        .inpu-group.row
                          .col-3.pt-2 收款帳號
                          //- input.input.col-8
                          .col-8.pt-2
                            span(v-if="order.atm_number") {{ order.atm_number }}
                            span(v-else) -
                  //- 買家備註
                  .col-12
                    .p-2
                    .card
                      .card-body
                        h6 {{ $t('message.inbox_order_recipient_note') }}
                        .p-1
                        textarea(
                          v-if="order.status < 3",
                          v-model="order.recipient_note",
                          maxlength="250"
                        )
                        textarea(
                          v-else,
                          disabled,
                          v-model="order.recipient_note",
                          maxlength="250"
                        )
                  //- 賣家備註
                  .col-12
                    .p-2
                    .card
                      .card-body
                        .row
                          .col-md-5.col-xs-12
                            h6 {{ $t('message.inbox_order_shop_note') }}
                          .col-md-7.col-xs-12.text-right
                            h6.alert-text {{ $t('message.inbox_order_shop_note_alert') }}
                        .p-1
                        textarea(
                          v-if="order.status < 3",
                          v-model="order.shop_note",
                          maxlength="250"
                        )
                        textarea(
                          v-else,
                          disabled,
                          v-model="order.shop_note",
                          maxlength="250"
                        )
                  //- 通知紀錄
                  .col-12
                    .p-2
                    .card
                      .card-body
                        h6 {{ $t('message.order_send_log_title') }}
                        .p-1
                        .table-responsive
                          table.table
                            tbody
                              tr(v-for="(notice, index) in order.logs")
                                td {{ notice.created_at }}
                                td {{ $t('message.orders_order_not_status_0' + notice.type) }}
                              tr.text-center(v-if="!order.logs.length")
                                td(colspan="2") {{ $t('message.common_now_no_data') }}
              .col
                .row.p-3.d-lg-none
                  .col-12.text-center(v-if="order.status < 3")
                    //- 取消訂單按鈕 <手機版專用>
                    button.btn-error-outline.btn-md(@click="deleteOrder()") {{ $t('message.order_cancel_button') }}
        .section-footer(v-if="order.status < 4")
          .container-fluid.p-3
            .row
              .col-lg-6.d-none.d-lg-flex.text-lg-left(v-if="order.status < 3")
                //- 取消訂單按鈕 <電腦版專用>
                button.btn-error-outline.btn-md(@click="deleteOrder()") {{ $t('message.order_cancel_button') }}
              .col-lg-6.text-center.text-lg-right
                //- 訂單編輯按鈕們 <電腦/手機版-共用>
                button.btn-md.btn-default-arrow-right(
                  v-if="order.status === 2",
                  @click="setOrder('realship')"
                )
                  span {{ $t('message.order_set_status_02') }}
                  icon(:iconName="'regularArrowRight'")
                button.btn-md.btn-default(
                  v-if="order.pay_method == 1 && order.status === 1",
                  @click="setOrder('confirm')"
                ) {{ $t('message.order_set_status_00') }}
                button.btn-md.btn-default(
                  v-if="(order.pay_method == 2 || order.pay_method == 3 || order.pay_method == 4 || order.pay_method == 5) && order.status === 1 && order.pay_status === 0",
                  @click="setOrder('askpay')"
                ) {{ $t('message.order_set_status_03') }}
                button.button.btn-default-arrow-right(
                  v-if="order.status === 1",
                  @click="setOrder('setship')"
                )
                  span {{ $t('message.order_set_status_01') }}
                  icon(:iconName="'regularArrowRight'")
                button.button.btn-default-arrow-right(
                  v-if="order.status === 0",
                  @click="setOrder('setbuild')"
                )
                  span {{ $t('message.order_set_status_build') }}
                  icon(:iconName="'regularArrowRight'")
</template>

<script>
import $ from 'jquery'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  name: "Order",
  components: {
    uiSwitch,
    uiCheckbox,
    uiRadio,
    icon
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      // 訂單序號
      number: '',
      // 訂單內容
      order: {
        // 所屬會員
        member: {
          name: '',
          phone: '',
          zip: '',
          address: ''
        },
        // 訂單細節
        details: [
          {
            name: '',
            quy: 1,
            price: ''
          }
        ],
        // 付款方式
        pay_method: 1,
        // 買家備註
        recipient_note: '',
        // 賣家備註
        shop_note: '',
        // 通知紀錄
        notices: [],
      },
      // 運費
      post_cost: 0,
      // 自動計算總價
      total: 0,
      // 商店所屬商品清單
      products: [],
      // 判斷是否有修改過
      order_change: false,
      // 是否需要設定出貨
      order_ship: false
    }
  },
  created () {
    if (this.$route.params.id) {
      this.number = this.$route.params.id
    }

    // 重新拿商店資料
    this.$root.getShop()
    this.autoRun()
    this.getOrder()
    this.getProductList()
  },
  methods: {
    // 自動更新
    autoRun () {
      let vm = this
      // 等待畫面載入完成
      $(document).ready(function () {
        // 處理自動存儲功能
        $(document).on('change', 'input,select,textarea', function () {
          // this.$root.console('--> 項目被修改: ')
          // this.$root.console($(this).attr('id'))

          if ($(this).attr('id') === 'shipcode') {
            // 被設定物流編號
            vm.$data.order_ship = true
          } else {
            // 將訂單狀態變為修改中
            vm.$data.order_change = true
            // 重新計算訂單價格
            vm.processOrder()
          }
        })
      })
    },
    // 取得目前訂單與資訊
    getOrder () {
      // 取得商店 ID
      let shopID = this.$root.shopID
      // 取得訂單資料
      this.$http.get('manager/v1/shop/' + shopID + '/order/' + this.number).then(function (_response) {
        if (_response.body.code === '0000') {
          // 灌入訂單資料
          this.order = _response.body.data
          // 重新計算訂單
          this.processOrder()
        }
      }, function (_response) {
        this.useOrderErr = _response.body.code
      })
    },
    // 重新計算訂單
    processOrder () {
      // 重新計算費用
      let total = 0
      this.order.details.forEach(function (_detail) {
        if (_detail.price && _detail.quy) {
          total += parseFloat(parseFloat(_detail.price) * parseFloat(_detail.quy))
        }
      })
      // 增加運費
      if (this.order.ship_cost) {
        total += parseFloat(this.order.ship_cost)
      }
      // 將數字格式化
      this.order.total = this.$root.common.formatNumber(total)
    },
    // 增加商品品項
    addItem () {
      this.order.details.push({
        name: '',
        quy: 1,
        price: ''
      })
    },
    // 移除商品項目
    removeItem (_index) {
      this.order.details.splice(_index, 1)
      this.processOrder()
    },
    // 變更付款方式
    setPayment (_methon) {
      this.order.pay_method = _methon
      // 將訂單狀態變為修改中
      this.order_change = true
    },
    // 變更付款狀態
    setPayStatus (_status) {
      this.order.pay_status = _status
      // 將訂單狀態變為修改中
      this.order_change = true
    },
    // 變更出貨方式
    setPost (_methon) {
      this.order.post_method = _methon
      // 將訂單狀態變為修改中
      this.order_change = true
    },
    // 取消訂單
    orderCannel () {
      if (confirm(this.$t('message.order_delete_confirm'))) {
        this.$http.delete('').then(function (_response) {
          if (_response.body.code === '0000') {
            // 刪除完成
            this.goOrderListPage()
          }
        })
      }
    },
    // 更新訂單
    async updateOrder () {
      let shopID = this.$root.shopID
      await this.$http.put('manager/v1/shop/' + shopID + '/order/' + this.order.number, this.order).then(function (_response) {
        if (_response.body.code === '0000') {
          this.order_change = false
          this.$root.showAlert(this.$t('message.alert_done'), 'success')
        }
      })
    },
    // 取消訂單
    deleteOrder () {
      if (confirm(this.$t('message.order_delete_confirm'))) {
        let shopID = this.$root.shopID
        this.$http.delete('manager/v1/shop/' + shopID + '/order/' + this.order.number).then(function (_response) {
          if (_response.body.code === '0000') {
            this.getOrder()
          }
        })
      }
    },
    // 操作訂單功能
    async setOrder (_type = '') {
      let isNullProductName = false
      await this.order.details.forEach(function (_detail) {
        if (!_detail.name) {
          isNullProductName = true
        }
      })

      if (isNullProductName) {
        await this.$root.showAlert(this.$t('message.alert_no_product_name'), 'error')
        return false
      }

      let post = { type: _type }

      // 設定參數
      post.ship_code = this.order.ship_code

      // 儲存訂單
      await this.updateOrder()

      // 設定訂單
      let shopID = this.$root.shopID
      await this.$http.post('manager/v1/shop/' + shopID + '/order/' + this.number, post).then(async function (_response) {
        if (_response.body.code === '0000') {
          this.$root.showAlert(this.$t('message.alert_done'), 'success')
          // 訂單設定成功
          await this.getOrder()
        }
      }, function (_error) {
        if (_error.body.data) {
          return alert(_error.body.data)
        }

        else {
          alert("發生例外錯誤，請洽客服詢問")
        }
      })
    },
    // 前往訂單列表
    goOrderListPage (_status = 0) {

      let operating = ''
      switch (_status) {
        case 1:
          operating = 'process'
          break;
        case 2:
          operating = 'waitship'
          break;
        case 3:
          operating = 'shiped'
          break;
        case 4:
          operating = 'canceled'
          break;
      }

      let shopID = this.$root.shopID
      this.$router.push({ name: 'Orders', params: { shop: shopID, operating: operating } })
    },
    // 取得商品清單
    getProductList () {
      // 取得商店 ID
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/product/list').then(function (_response) {
        if (_response.body.code === '0000') {
          this.products = _response.body.data
        }
      })
    },
    // 進行訂單匯出
    openOrderExport () {
      let shopID = this.$root.shopID
      window.open('/report-order/' + shopID + '/' + this.number)
    }
  }
}
</script>

<style scoped lang="sass">
.order-page
  .remove
    cursor: pointer
    color: #f00
    .redio-option
      cursor: pointer
</style>