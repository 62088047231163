<template lang="pug">
.page(:class="{ active: boolActive, unactive: !boolActive }")
  main
    article
      section.section.flex-row.align-items-stretch
        .section-body
          .container-fluid
            .row
              .col-12
                .p-2
                .card
                  .card-body.auto-response
                    nav.flex-grow
                      .input-group.search-room-bar
                        //- 搜尋輸入框
                        input.input.search-input(
                          :placeholder="$t('message.orders_please_input_search_key')",
                          v-model="strSearch",
                          @keypress.enter.prevent.stop="getOrders()"
                        )
                        //- 清除內容
                        button.button.btn-unstyle.right.btn-clear(
                          :class="{ active: strSearch !== '' }",
                          @click="clearSearch()"
                        )
                          icon(:iconName="'lightTimes'")
                        //- 執行搜尋 按鈕
                        button.btn-unstyle.btn-search(
                          :disabled="strSearch === ''",
                          @click="getOrders()"
                        )
                          icon(:iconName="'lightSearch'")
                .p-2
                .card
                  .card-body.order-operating
                    .row
                      //- 手機版專用
                      .col-xs-12.d-lg-none.auto-response
                        .text.mb-1 {{ $t('message.orders_order_type_title') }}
                        .select-group
                          select.select.operating-option(
                            v-model="operating",
                            @change="setOperating(operating)"
                          )
                            option(value="waiting") {{ $t('message.orders_order_type_waiting') }}
                            option(value="process") {{ $t('message.orders_order_type_process') }}
                            option(value="waitship") {{ $t('message.orders_order_type_waitship') }}
                            option(value="shiped") {{ $t('message.orders_order_type_shiped') }}
                            option(value="canceled") {{ $t('message.orders_order_type_canceled') }}
                      //- 電腦版專用
                      .col-md-5.d-none.d-md-block
                        .pt-1
                        ul.nav
                          li.nav-item
                            a.nav-link(
                              @click="setOperating('waiting')",
                              v-bind:class="[operating === 'waiting' ? 'active' : '']"
                            ) {{ $t('message.orders_order_type_waiting') }}
                          li.nav-item
                            a.nav-link(
                              @click="setOperating('process')",
                              v-bind:class="[operating === 'process' ? 'active' : '']"
                            ) {{ $t('message.orders_order_type_process') }}
                          li.nav-item
                            a.nav-link(
                              @click="setOperating('waitship')",
                              v-bind:class="[operating === 'waitship' ? 'active' : '']"
                            ) {{ $t('message.orders_order_type_waitship') }}
                          li.nav-item
                            a.nav-link(
                              @click="setOperating('shiped')",
                              v-bind:class="[operating === 'shiped' ? 'active' : '']"
                            ) {{ $t('message.orders_order_type_shiped') }}
                          li.nav-item
                            a.nav-link(
                              @click="setOperating('canceled')",
                              v-bind:class="[operating === 'canceled' ? 'active' : '']"
                            ) {{ $t('message.orders_order_type_canceled') }}
                      .col-md-7.d-none.d-md-block.operating-lg
                        .operating-02
                          button.button.btn-default-ghost(
                            type="button",
                            @click="exportOrders()"
                          )
                            icon(:iconName="'solidCloudDownloadAlt'")
                            span.ml-2 {{ $t('message.orders_order_export_report') }}
                        .operating-02(v-if="operating === 'waitship'")
                          button.button.btn-default-ghost(
                            type="button",
                            @click="exportDelivery(true)"
                          )
                            icon(:iconName="'solidCloudDownloadAlt'")
                            span.ml-2 {{ $t('message.orders_order_export_delivery') }}
                          button.button.btn-default-ghost(
                            type="button",
                            @click="exportDelivery(false)"
                          )
                            icon(:iconName="'solidCloudDownloadAlt'")
                            span.ml-2 {{ $t('message.orders_order_export_delivery_no_money') }}
                        //- .operating-01
                          .select-group
                            select.select
                              option {{ $t('message.orders_order_batch_operation') }}
                //- .p-2
                //- .card.d-lg-none(v-if="operating ==='process' || operating === 'waitship'")
                  .card-body.order-operating
                    .row
                      .col-xs-12.auto-response
                        //- .text.mb-1 {{ $t('message.orders_order_type_title') }}
                        .select-group
                          select.select.operating-option.form-control(v-if="useOrders.length > 0")
                            option(value="0") {{ $t('message.orders_order_batch_operation') }}
                            option(value="3") {{ $t('message.order_set_status_02') }}
                            option(value="4") {{ $t('message.order_cancel_button') }}
                          select.select.operating-option.form-control(v-else,disabled)
                            option(value="0") {{ $t('message.orders_order_batch_operation') }}

                .p-2
                //- 手機版專用
                .d-lg-none
                  .card.orders-list(v-for="(order, index) in orders")
                    .card-body.orders-xs
                      .select-order
                        .row
                          //- .col-5(v-if="operating ==='process' || operating === 'waitship'",@click="setSelectOrder(order.number)")
                            uiCheckbox(:boolActive="orderIsSelect(order.number)",:objStyle="{fontSize: '1em'}")
                          .col-5
                          .col-7.text-right(@click="goOrderPage(order.number)")
                            button.btn-icon
                              icon(
                                v-if="operating === 'canceled'",
                                :iconName="'iconLiveShopView'"
                              )
                              icon(v-else, :iconName="'lightEdit'")
                      .order-number.pt-3
                        b {{ $t('message.orders_order_number_title') }}
                        span.number {{ '#' + order.number }}
                      .order-date 
                        b {{ $t('message.orders_order_created_at') }}
                        | {{ order.release_at }}
                      .order-name 
                        b {{ $t('message.orders_order_member_name') }}
                        | {{ order.member.full_name }}
                      .order-detail
                        span {{ $t('message.orders_order_detail_title') }}：
                        table.table
                          thead
                          tbody
                            tr(v-for="(item, index) in order.details")
                              td {{ item.name }}
                              td {{ $root.shop.currency }} $ {{ item.price }} X {{ item.quy }}
                      .order-total 
                        b {{ $t('message.orders_order_total_title') }}：
                        | {{ $root.shop.currency }} $ {{ order.total }}
                      .pt-2
                      .order-payment
                        span(v-if="order.pay_method === 1") {{ $t('message.orders_order_payment_cash') }}
                        span(v-if="order.pay_method === 2") {{ $t('message.orders_order_payment_atm') }}
                        span(v-if="order.pay_method === 3") {{ $t('message.orders_order_payment_linemoney') }}
                        span(v-if="order.pay_method === 4") {{ $t('message.orders_order_payment_linepay') }}
                        span(v-if="order.pay_method === 5") {{ $t('message.orders_order_payment_ecpay') }}
                //- 電腦版專用
                .card.d-none.d-md-block.order-list
                  .card-body.orders-lg
                    //- .all-select(v-if="operating ==='process' || operating === 'waitship'")
                      .select-all-order
                        uiCheckbox(:boolActive="selectAllOrder",:objStyle="{fontSize: '1em'}",@click="setSelectOrder( ( selectAllOrder ) ? 'close' : 'all' )") 
                        select.select.operating-option.form-control(v-if="useOrders.length > 0")
                          option(value="0") {{ $t('message.orders_order_batch_operation') }}
                          option(value="3") {{ $t('message.order_set_status_02') }}
                          option(value="4") {{ $t('message.order_cancel_button') }}
                        select.select.operating-option.form-control(v-else,disabled)
                          option(value="0") {{ $t('message.orders_order_batch_operation') }}

                    .p-2
                    .table-responsive
                      table.table
                        thead
                          //- th.text-left {{ $t('message.orders_order_table_title_01') }}
                          th.text-left {{ $t('message.orders_order_table_title_02') }}
                          th.text-left {{ $t('message.orders_order_table_title_03') }}
                          th.text-left {{ $t('message.orders_order_table_title_04') }}
                          th.text-left {{ $t('message.orders_order_table_title_05') }}
                          th.text-left {{ $t('message.orders_order_table_title_06') }}
                          //- th.text-left {{ $t('message.orders_order_table_title_07') }}
                          th.text-left {{ $t('message.orders_order_table_title_12') }}
                          th.text-left 
                            span(v-if="operating === 'canceled'") {{ $t('message.orders_order_table_title_09') }}
                            span(v-else) {{ $t('message.orders_order_table_title_08') }}
                        tbody
                          tr(v-for="(order, index) in orders")
                            //- td
                              .select-order(@click="setSelectOrder(order.number)",v-if="operating ==='process' || operating === 'waitship'")
                                uiCheckbox(:boolActive="orderIsSelect(order.number)",:objStyle="{fontSize: '1em'}")
                              .text-center(v-else) -
                            td
                              .order-id {{ '#' + order.number }}
                              .order-date {{ order.release_at }}
                            td
                              a(
                                v-if="$root.shop.page.platform_type === 1",
                                target="_new",
                                :href="'https://www.facebook.com/' + $root.shop.page.fbpage_id + '/inbox/' + order.member.platform_user_id"
                              ) 
                                img.message-icon(
                                  v-if="order.member.platform_type === 1",
                                  src="../assets/image/messenger.png"
                                )
                                img.message-icon(
                                  v-if="order.member.platform_type === 3",
                                  src="../assets/image/instagram.png"
                                )
                                span.message-uname {{ order.member.full_name }}
                              span(v-else) {{ order.member.full_name }}
                            td
                              .order-detail(
                                v-for="(item, index) in order.details"
                              ) {{ item.name }} (${{ item.price }} x {{ item.quy }})
                            td ${{ order.total }}
                            td
                              span(v-if="order.pay_method === 1") {{ $t('message.orders_order_payment_cash') }}
                              span(v-if="order.pay_method === 2") {{ $t('message.orders_order_payment_atm') }}
                              span(v-if="order.pay_method === 3") {{ $t('message.orders_order_payment_linemoney') }}
                              span(v-if="order.pay_method === 4") {{ $t('message.orders_order_payment_linepay') }}
                              span(v-if="order.pay_method === 5") {{ $t('message.orders_order_payment_ecpay') }}
                            //- td
                              .text(v-if="order.logs[0]")
                                span {{ order.logs[order.logs.length - 1].created_at }}
                              .text(v-else) -
                            td
                              .text
                                span(
                                  v-if="order.pay_status === 0 || order.pay_status === 2"
                                ) {{ $t('message.orders_order_not_pay') }}
                                span(v-if="order.pay_status === 1") {{ $t('message.orders_order_pay') }}
                            td
                              .order-edit(@click="goOrderPage(order.number)")
                                button.btn-icon
                                  icon(
                                    v-if="operating === 'canceled'",
                                    :iconName="'iconLiveShopView'"
                                  )
                                  icon(v-else, :iconName="'lightEdit'")
                          tr.text-center(v-if="orders.length === 0")
                            td(colspan="7") {{ $t('message.common_now_no_data') }}
                    .pageings(v-if="last_page > 1")
                      nav(aria-label="Page navigation example")
                        ul.pagination
                          li.page-item(v-if="current_page > 1")
                            a.page-link(@click="getOrders(current_page - 1)") {{ $t('message.common_last_page') }}
                          li.page-item(v-for="page in last_page")
                            a.page-link(@click="getOrders(page)") {{ page }}
                          li.page-item(v-if="current_page < last_page")
                            a.page-link(@click="getOrders(current_page + 1)") {{ $t('message.common_next_page') }}
                //- .no-data(v-else)
                  .p-3
                  img(src="../assets/image/no-orders.png")
                  .p-2
                  .text-center
                    .info
                      span(v-if="$root.shop.page.platform_type === 1") {{ $t('message.orders_order_plugin_download') }}
                      span(v-if="$root.shop.page.platform_type === 2") {{ $t('message.orders_order_plugin_download_line') }}
                    .p-2
                    //- 手機版專用
                    //- .d-lg-none
                      //- button.btn-default(
                      //-   v-if="$root.shop.page.platform_type === 1",
                      //-   type="button",
                      //-   data-toggle="modal",
                      //-   data-target="#staticBackdrop"
                      //- ) {{ $t('message.orders_order_plugin_now_button') }}
                      button.btn-default(
                        v-if="$root.shop.page.platform_type === 2",
                        type="button",
                        @click="downloadApp('Android')"
                      ) {{ $t('message.orders_order_android_app_now_button') }}
                      button.btn-default(
                        v-if="$root.shop.page.platform_type === 2",
                        type="button",
                        @click="downloadApp('iOS')"
                      ) {{ $t('message.orders_order_ios_app_now_button') }}
                    //- 電腦版專用
                    //- .d-none.d-md-block
                      //- button.btn-default(
                      //-   v-if="$root.shop.page.platform_type === 1",
                      //-   type="button",
                      //-   @click="openExtension()"
                      //- ) {{ $t('message.orders_order_plugin_now_button') }}
                      button.btn-default(
                        v-if="$root.shop.page.platform_type === 2",
                        type="button",
                        @click="downloadApp('Android')"
                      ) {{ $t('message.orders_order_android_app_now_button') }}
                      button.btn-default(
                        v-if="$root.shop.page.platform_type === 2",
                        type="button",
                        @click="downloadApp('iOS')"
                      ) {{ $t('message.orders_order_ios_app_now_button') }}
                    .p-3
                    //- Modal
                    //- #staticBackdrop.modal.fade(
                    //-   data-backdrop="static",
                    //-   tabindex="-1",
                    //-   role="dialog",
                    //-   aria-labelledby="staticBackdropLabel",
                    //-   aria-hidden="true"
                    //- )
                    //-   .modal-dialog(role="document")
                    //-     .modal-content
                    //-       .modal-header
                    //-         h5#staticBackdropLabel.modal-title {{ $t('message.orders_get_pc_liveshop_plugin_title') }}
                    //-         button.close(
                    //-           type="button",
                    //-           data-dismiss="modal",
                    //-           aria-label="Close"
                    //-         )
                    //-           span(aria-hidden="true") ×
                    //-       .modal-body
                    //-         .text {{ $t('message.orders_get_pc_liveshop_plugin_text') }}
                    //-         input.link(type="text", v-model="sendEmail")
                    //-       .modal-footer.text-center
                    //-         button.btn.btn-primary(
                    //-           type="button",
                    //-           @click="sendExtension()",
                    //-           data-dismiss="modal"
                    //-         ) {{ $t('message.orders_get_pc_liveshop_plugin_send') }}
</template>

<script>
import HeaderBase from './HeaderBase'
import Menu from './Menu'
import icon from '../common/icons'
import { uiSwitch, uiCheckbox, uiRadio } from '../common/ui/'
export default {
  components: {
    HeaderBase,
    Menu,
    icon,
    uiSwitch,
    uiCheckbox,
    uiRadio
  },
  props: {
    boolActive: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      boolCheckboxActive: false,
      // 目前操作類型
      operating: 'process',
      // 目前搜尋字串
      strSearch: '',
      // 訂單清單
      orders: [],
      // 勾選的訂單們
      useOrders: [],
      // 目前分頁
      current_page: 1,
      // 最大分頁
      last_page: 1,
      // 每頁個數
      per_page: 20,
      // 總共幾筆
      total: 0,
      // 發送信箱
      sendEmail: '',
      // 多選全選
      selectAllOrder: false
    }
  },
  created () {
    // 獲取目前檢視的訂單類型
    if (this.$route.params.operating) {
      this.operating = this.$route.params.operating
    }
    // 重新拿商店資料
    this.$root.getShop()
    // 獲取訂單資訊
    this.getOrders()
    // 設置用戶 Email
    setTimeout(() => { this.sendEmail = this.$root.user.email }, 1000)
  },
  watch: {
    strSearch () {
      this.getOrders()
    }
  },
  methods: {
    // 設定目前操作類型
    setOperating (_operating = '') {
      let shopID = this.$root.shopID
      this.$root.console('--> 目標商店: ' + shopID)
      this.$root.console('--> 目標操作: ' + _operating)
      // 重新跳頁
      this.operating = _operating
      // 拉取訂單資料
      this.getOrders()
    },
    // 匯出本頁訂單
    exportOrders () {
      let shopID = this.$root.shopID
      let targetUrl = this.$root.web_url + 'shop/' + shopID + '/' + this.$root.user.lang + '/orders/report?operating=' + this.operating + '&search=' + this.strSearch
      window.open(targetUrl)
    },
    // 請求批次列印出貨單
    exportDelivery (_needPrice = true) {
      if (this.$root.orderWorkers[0]) {
        return;
      }

      // 獲取參數資訊
      let shopID = this.$root.shopID
      let langType = this.$root.user.lang

      // 請求後端批次匯出待出貨的訂單出貨單
      this.$http.get('manager/v1/shop/' + shopID + '/order/deliverys/' + langType, {
        params: {
          export_type: this.operating,
          need_price: _needPrice
        }
      }).then(function (_response) {
        this.$root.showDownloadView = true
        this.$root.orderWorkers.push(_response.body.data)
      })
    },
    // 取得訂單列表
    getOrders (_page = false) {
      let goPage = (_page) ? _page : this.current_page
      let shopID = this.$root.shopID
      this.$http.get('manager/v1/shop/' + shopID + '/orders', {
        params: {
          operating: this.operating,
          search: this.strSearch,
          page: goPage
        }
      }).then(function (_response) {
        if (_response.body.code === '0000') {
          // 獲取訂單資料
          this.orders = _response.body.data.data
          // 處理相關參數
          this.current_page = _response.body.data.current_page
          this.last_page = _response.body.data.last_page
          this.per_page = _response.body.data.per_page
          this.total = _response.body.data.total
          // 取得價格
          var vm = this
          this.orders.forEach(function (_order, _index) {
            let total = 0
            _order.ship_cost = (_order.ship_cost) ? _order.ship_cost : 0
            _order.details.forEach(function (_detail) {
              if (_detail.price && _detail.quy) {
                total += parseFloat(parseFloat(_detail.price) * parseFloat(_detail.quy))
              }
            })
            // 增加運費
            total += parseFloat(_order.ship_cost)
            // 設置總訂單金額
            vm.$data.orders[_index].ship_cost = vm.$root.common.formatNumber(_order.ship_cost)
            vm.$data.orders[_index].total = vm.$root.common.formatNumber(total)
          })
        }
      })
    },
    // 前往訂單內頁
    goOrderPage (_orderNumber = '') {
      let shopID = this.$root.shopID
      this.$router.push({ name: 'Order', params: { shop: shopID, id: _orderNumber } })
    },
    // 選擇訂單
    setSelectOrder (_number = '') {
      this.$root.console('--> 選擇訂單: ' + _number)

      this.selectAllOrder = false
      if (_number === 'close') {
        this.useOrders = []
        return true
      }

      if (_number === 'all') {
        var vm = this
        this.useOrders = []
        this.orders.forEach(function (_order) {
          vm.$data.useOrders.push(_order.number)
        })
        this.selectAllOrder = true
        return true
      }

      let searchAt = this.useOrders.indexOf(_number)
      if (searchAt === -1) {
        this.useOrders.push(_number)
      } else {
        this.useOrders.splice(searchAt, 1)
      }

    },
    // 是否被勾選
    orderIsSelect (_number) {
      if (this.useOrders.indexOf(_number) === -1) {
        return false
      } else {
        return true
      }
    },
    // 清除搜尋文字
    clearSearch () {
      this.strSearch = ''
    },
    // 打開 Extension 頁面
    openExtension () {
      window.open('https://chrome.google.com/webstore/detail/liveshop/khhlndknfafmknipinamodmgdjkehclf?hl=zh-TW')
    },
    downloadApp (_platform) {
      switch (_platform) {
        case 'iOS':
          window.open('https://apps.apple.com/us/app/livechat-mobile/id1506885665')
          break;
        case 'Android':
          window.open('https://play.google.com/store/apps/details?id=com.chatisfy.livechat.app')
          break;
      }
    },
    // 請求發送信件
    sendExtension () {
      if (!this.sendEmail) {
        return false
      }

      // 請求寄送 Email
      this.$http.post('manager/v1/edm/send', { email: this.sendEmail })
    },
    // 批次變更訂單狀態
    chagneOrders () {

    }
  }
}
</script>

<style scoped lang="sass">
.section-body
  width: 100%
  overflow-y: scroll
  .search-room-bar
    width: 100%
    .search-input
      padding-left: 3rem
      padding-right: 3rem
  .order-operating
    width: 100%
    .order-operating
      .select-group
        width: 100%
      .operating-option
        max-width: 100%
        width: 100%
    ul.nav
      li.nav-item
        a.nav-link.active
          color: #00F
  .operating-lg
    .operating-01
      float: right
      .select-group
        width: 15rem
    .operating-02
      margin-left: 15px
      float: right
  .orders-list
    margin-bottom: 15px
  .nav-item
    cursor: pointer
  .orders-xs
    .order-number
      // color: #356CFA
    td
      border: 0px
      padding: 2px
    .order-payment
      border-radius: 20px
      background: #ccc
      padding: 3px 10px
      font-size: .8em
      float: right
  .orders-lg
    .table
      min-width: 800px
  .no-data
    img
      margin: 0px auto
      max-width: 100%
      max-height: 350px
      margin-bottom: 25px
  .select-all-order
    .select.operating-option
      margin-left: 15px
      min-width: 150px
  .order-list
    .pageings
      nav
        ul
          margin: 0px auto
  #staticBackdrop
    .modal-body
      .text
        text-align: left
      input.link
        border: 1px solid #aaa
        padding: 10px 15px
        margin: 15px 0px
        width: 100%
</style>
